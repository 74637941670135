import React, { useState, useEffect } from 'react';
import { useSprings, animated, to as interpolate } from '@react-spring/web'
import { useDrag } from 'react-use-gesture'
import './tinder.css'
import flash from '../../assests/images/f5.png'
import reazy from '../../assests/images/f6.png'
import gp4 from '../../assests/svg/Group 4188.svg'
import locat from '../../assests/svg/Icon material-location-on.svg'
import desc from '../../assests/svg/Icon material-description.svg'
import { useLocation } from 'react-router-dom'
import { Truncate } from '../../utils/TrucateString'
import { GetPaginationRentProp } from '../../lib/Api/RentalAppApi';
import { useTranslation } from 'react-i18next';
import { parseTimes } from '../../hooks/TimeCountDown';

const to = (i) => ({
    x: 0,
    y: i * -4,
    scale: 1,
    rot: -10 + Math.random() * 20,
    delay: i * 100,
})
const from = (_i) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 })

const trans = (r, s) => `perspective(1500px)  rotateY(${r / 10}deg)  scale(${s})`

function Deck({ toggle, type, setNextLink, setSearchLoading, filterInfo, nexturl, backuplisting, setProperty, listing, setListing, setTimeLeft, setDetail, setOriginalLists, originalLists, setIsLogIn }) {
    const location = useLocation().pathname
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [gone] = useState(() => new Set())

    const [props, api] = useSprings(listing?.length, i => ({
        ...to(i),
        from: from(i),
    }))
    const determineDirCard = (x, index) => {
        const numberOfElementsToKeep = 1;
        const filteredData = listing.filter((_, i) => i >= listing.length - numberOfElementsToKeep);
        let obj = Object.assign({}, ...filteredData);
        setListing(prev => prev.filter(item => item.id !== obj.id));
        if (x && x > 0) {
            setProperty(listing[index])
            toggle(true)
        }
    }


    const PaginateFilter = async () => {
        try {
            setSearchLoading(true)
            let { res } = await GetPaginationRentProp(filterInfo, nexturl)
            if (res) {
                let ObjProperty = Object.assign({}, ...res)
                setSearchLoading(false)
                if (ObjProperty.listings) {
                    const reversedRoommates = ObjProperty?.listings.reverse();
                    setListing(reversedRoommates)
                    if (ObjProperty?.next_page_url) {
                        const urlParams = new URL(ObjProperty?.next_page_url).searchParams; // Parse the URL and get the search params
                        let page = urlParams.get("page"); // Extract the value of 'page'
                        setNextLink(page)
                    }
                    return reversedRoommates
                } else {
                    setSearchLoading(false)

                }
            }
        }
        catch (err) {
        }
    }




    const bind = useDrag(({ args: [index], down, movement: [mx], direction: [xDir], velocity }) => {
        // const trigger = velocity > 0.2 // If you flick hard enough it should trigger the card to fly out
        // const dir = xDir < 0 ? -1 : 1 // Direction should either point left or right
        // let find = listing[index]
        const trigger = velocity > 0.2 // Only trigger if dragged far enough
        // if(!trigger) {
        //     return 
        // }
        const dir = xDir < 0 ? -1 : 1
        const find = listing[index]
        let check = originalLists.find((f) => f.id === find.id)
        if (!check) {
            setOriginalLists((prev) => [...prev, find])
        }
        if (location === "/flash") {
            const initialTime = parseTimes(check?.flashlane?.time || "00:00:00");
            setTimeLeft(initialTime);
        }
        if (!down && trigger) gone.add(index)
        let result = ""
        // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
        api.start(i => {
            if (index !== i) return // We're only interested in changing spring-data for the current spring
            const isGone = gone.has(index)
            const x = isGone ? (200 + window.innerWidth) * dir : down ? mx : 0 // When a card is gone it flys out left or right, otherwise goes back to zero
            const rot = mx / 100 + (isGone ? dir * 10 * velocity : 0) // How much the card tilts, flicking it harder makes it rotate faster
            const scale = down ? 1.1 : 1 // Active listing lift up a bit
            if (!down && trigger)
                determineDirCard(x, index)
            return {
                x,
                rot,
                scale,
                delay: undefined,
                config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 },
            }
        })

        if (!down && gone.size === backuplisting?.length && type === "property") {
            if (nexturl !== "") {
                setOriginalLists([])
                // Fetch the next page
                PaginateFilter().then(() => {
                    setTimeout(() => {
                        gone.clear();
                        api.start((i) => to(i));
                    }, 600);
                });
            } else {
                // No more pages to fetch, just reset the UI
                setTimeout(() => {
                    gone.clear();
                    api.start((i) => to(i));
                }, 600);
            }
        }


    })

    useEffect(() => {
        const handleResize = () => {
            setInnerWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const { t } = useTranslation()

    return (
        <div className={`${innerWidth <= 1366 ? 'max-w-[500px]' : innerWidth === 1517 ? 'max-w-[525px]' : innerWidth === 1707 ? 'max-w-[580px]' : innerWidth === 1821 ? 'max-w-[600px]' : 'max-w-[625px]'} mx-auto overflow-hidden `}>
            {props?.map(({ x, y, rot, scale }, i) => {
                return (
                    <animated.div className={`${location === "/property" ? '!w-[60%]' : 'w-auto'} dd`} key={i} style={{
                        transform: interpolate([x, y], (x, y) => `translate3d(${x}px,${y}px,0)`),
                        marginTop: `${Math.min(50 + (listing.length / 45) * 150, 200)}px`
                    }}>

                        <animated.div className="  bg-cover bg-center " {...bind(i)} style={{ transform: interpolate([rot, scale], trans), backgroundImage: `url(${listing[i]?.image})` }} >
                            <div className=' w-full  '>

                                <div className='cursor-pointer' onClick={() => setDetail(listing[i])}>
                                    <div className='flex justify-between w-full items-center pt-5 px-5 absolute top-0'>
                                        {/* {location === "/flash" &&  */}
                                        <div className='flex flex-col'>
                                            <div className='bg-gray-100 rounded-full px-2'>
                                                {listing && listing[i]?.days_on_market} {t('day_ago')}
                                            </div>
                                            <div className='pt-4 flex items-center gap-3'>
                                                {listing && listing[i]?.flashlane && <img src={flash} alt="" className='w-8 h-8' />}

                                                {listing && listing[i]?.reazy_lane && <img src={reazy} alt="" className='w-8 h-8' />}
                                            </div>
                                        </div>


                                        {/* } */}
                                        <div>
                                            <img src={gp4} alt="detail" className='object-cover' />
                                        </div>
                                    </div>
                                    <div className='absolute bottom-36 left-5'>
                                        {location === "/flash" && listing && listing[i]?.flashlane !== null &&
                                            <div className='bg-gray-100 rounded-full px-2'>
                                                {listing && listing[i]?.days_on_market} {t('day_ago')}
                                            </div>
                                        }
                                    </div>

                                    <div className="pt-2 absolute bottom-[20%] w-full flex justify-center items-center">
                                        <div className="flex items-center gap-x-2 justify-center">
                                            <div className='flex items-center bg-[#F79F15] text-white rounded-md p-1'>
                                                <h2 className='text-[14px] px-5 py-1 font-medium'>{listing && listing[i]?.applications} {t('saved')}</h2>
                                            </div>
                                            <div className='flex items-center bg-[#F79F15] text-white rounded-md p-1'>
                                                <h2 className='text-[14px] px-6 py-1 font-medium'>{listing && listing[i]?.appointment} {t('appointment')}</h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='absolute w-full bottom-0'>
                                        <div className='flex justify-between items-center px-3 pb-2 bg-black bg-opacity-[0.5] py-3 rounded-b-xl'>
                                            <div>
                                                <ul>
                                                    <li>
                                                        <div className='flex items-center'>
                                                            <img src={listing && listing[i]?.user?.user_image} alt="detail" className='object-cover w-[35px] h-[35px] rounded-full' />
                                                            <h2 className='px-2 text-white text-[20px]'>{listing && listing[i]?.user?.first_name}</h2>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='flex items-center'>
                                                            <img src={locat} alt="detail" className='object-cover' />
                                                            <h2 className='px-2 text-white text-[14px]'>{
                                                                `${listing && listing[i]?.city} ${listing && listing[i]?.state}`}</h2>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='flex items-center'>
                                                            <img src={desc} alt="detail" className='object-cover' />
                                                            <h2 className='px-2 text-white text-[14px]'>{Truncate(listing[i]?.description, 20)}</h2>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div>
                                                <p className='text-white bg-gray-50 bg-opacity-[0.5] px-2 '>{t('rent_amount')} </p>
                                                <h2 className='text-white text-[25px] '>${listing && listing[i]?.rent}</h2>
                                                <p className='text-white'>
                                                    {`${t('Sec_dp')} : ${listing && listing[i]?.security_deposit === "0" ? 'NR' : `$${listing && listing[i]?.security_deposit}`}`}
                                                </p>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                            <animated.svg

                                className="tinder-icon  reject"
                                style={{
                                    opacity: interpolate([x, y], (x, y) => `${x / -80} `),
                                    transform: interpolate([x, scale], (x, scale) => `translate(${x / 10}px, -50%) scale(${(x > -140) ? x / -80 : 1.6})`),
                                }}
                                viewBox="0 0 96 96">
                                <svg xmlns="http://www.w3.org/2000/svg" width="57.264" height="57.264" viewBox="0 0 57.264 57.264">
                                    <g id="broken-heart" transform="translate(-157.652 -318.769) rotate(-1)">
                                        <circle id="Ellipse_869" data-name="Ellipse 869" cx="28.145" cy="28.145" r="28.145" transform="translate(152.048 322.455)" fill="#f90000" />
                                        <path id="icon-broken-heart" d="M31.825,5.058l-.161-.168A8.779,8.779,0,0,0,20.269,3.9l2.3,6.947-6.45,4.3,3.225,8.6L9.674,14.068l6.45-4.3L14.2,3.957A8.771,8.771,0,0,0,2.734,4.9l-.161.161A9.233,9.233,0,0,0,2.083,17.3l14.25,14.686a1.2,1.2,0,0,0,1.727,0L32.315,17.292a9.225,9.225,0,0,0-.49-12.234Z" transform="translate(163.271 333.489)" fill="#fff" />
                                    </g>
                                </svg>
                            </animated.svg>
                            <animated.svg

                                className="tinder-icon interest"
                                style={{
                                    opacity: interpolate([x, y], (x, y) => `${x / 80} `),
                                    transform: interpolate([x, scale], (x, scale) => `translate(${x / 10}px, -50%) scale(${(x < 140) ? x / 80 : 1.6})`),
                                }}
                                viewBox="0 0 96 96">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60.075" height="60.075" viewBox="0 0 60.075 60.075">
                                    <g id="heart" transform="matrix(0.998, 0.07, -0.07, 0.998, -125.158, -331.804)">
                                        <circle id="Ellipse_24" data-name="Ellipse 24" cx="28.143" cy="28.143" r="28.143" transform="translate(151.915 321.991)" fill="#11d157" />
                                        <path id="icon-heart" d="M31.058,2.058a9.187,9.187,0,0,0-12.536.914L17.2,4.335,15.876,2.972A9.186,9.186,0,0,0,3.34,2.058a9.647,9.647,0,0,0-.665,13.967l13,13.423a2.106,2.106,0,0,0,3.043,0l13-13.423a9.641,9.641,0,0,0-.658-13.967Z" transform="matrix(0.995, -0.105, 0.105, 0.995, 161.409, 339.356)" fill="#fff" />
                                    </g>
                                </svg>

                            </animated.svg>


                        </animated.div>

                    </animated.div>
                )
            })}
        </div>
    )
}

export default Deck;
