import React, { useState, useRef, useEffect } from 'react';
import Transition from '../Transition';
// import user from '../../assests/images/comment1.png'
import { Link } from 'react-router-dom';
// import { DeleteRental } from '../../lib/Api/RantelServiceApi';
import { BsThreeDots } from 'react-icons/bs';
import { DeleteLeased } from '../../lib/Api/LeasedBuilder';
import { useTranslation } from 'react-i18next';
import { Loader } from '../loaderButton';
import { callPrivateApi } from '../../lib/Apiendpoint';
function Action({ id, item,  fetchServicer , deletePopToggle, type }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation()
    const DeleteRentals = async () => {
        if (type === "leased") {
            await DeleteLeased(id)

        }
        else {
            deletePopToggle(id)
        }
    }

    const handleToggle = async () => {
        setLoading(true)
        let payload = {
            "property_id": item?.id,
            "active": item?.active === 1 ? false : true
        }
        let response = await callPrivateApi(`/servicer/properties/update`, 'post', payload)
        if (response) {
            setLoading(false)
            fetchServicer()
        }
    }

    // let checkLink = item?.active === 1 ? t('Inactive') : t('active')

    const list2 = [
        // {
        //     id: 0,
        //     period: 'Edit',
        //     link: '/register'
        // },
        {
            id: 1,
            period: t('del_lab'),
            name: "delete",
            link: "Delete"
        },

        // {
        //     id: 1,
        //     period: checkLink,
        //     name: "toggle",
        //     link: "toggle"
        // },

    ]


    const trigger = useRef(null);
    const dropdown = useRef(null);

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!dropdown.current) return;
            if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    return (
        <div className="relative">
            <button
                ref={trigger}
                className={` px-2 py-1 rounded-full mx-3 shadow-md border w-[60%] items-center flex justify-center bg-white border-slate-200 text-slate-500 `}
                aria-label="Select date range"
                aria-haspopup="true"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                aria-expanded={dropdownOpen}
            >
                <h2 className='px-3 font-semibold'>
                    <BsThreeDots />
                </h2>

            </button>
            <Transition
                show={dropdownOpen}
                tag="div"
                className="z-10 absolute top-full w-full right-1   bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
                enter="transition ease-out duration-100 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-100"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
            >
                <div
                    ref={dropdown}
                    className="font-medium text-sm text-slate-600 "
                    onFocus={() => setDropdownOpen(true)}
                    onBlur={() => setDropdownOpen(false)}
                >

                    {

                        list2?.map(option => {
                            return (
                                <button
                                    key={option.id}
                                    tabIndex="0"
                                    className={`text-center hover:bg-slate-50 w-full py-2 cursor-pointer`}
                                // onClick={() => { setSelected(option.id); setDropdownOpen(false); }}
                                >
                                    {option.link === "Edit" ?

                                        <Link className='text-center' to={`${option.link}/${id}`}>{option.period}</Link>
                                        :
                                        option.link === "toggle" ?
                                            <span className='text-center' onClick={() => handleToggle()}>
                                                {loading ? <div className="flex justify-center items-center"><Loader type="social" /> </div>: option.period}
                                            </span>
                                            :
                                            <span className='text-center' onClick={() => DeleteRentals()}>{option.period}</span>
                                    }
                                </button>
                            )
                        })

                    }


                </div>
            </Transition>
        </div>
    );
}

export default Action;


