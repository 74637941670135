import React, { useEffect, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import { Loader } from '../../../utils/loaderButton'
import Home from '../../../assests/svg/home.svg'
import { CheckRentalApp, GetSavedList, LoardScreeningReport, PenddingSendRent, RemoveSavedList, SaveForLater } from '../../../lib/Api/RentalAppApi'
import DynamicPopup from '../../../utils/Popup/DynamicPopup'
import BookAppointment from './BookAppointment'
import { useSelector, useDispatch } from 'react-redux'
import gp4 from '../../../assests/svg/Group 4188.svg'
import flash from '../../../assests/images/f5.png'
import reazy from '../../../assests/images/f6.png'
import RentalsDetail from '../../../pages/Property/PropertyDetail'
import { AddIndex, AddRentalAppUser } from '../../../Redux/UserAuthSlice/RentalSlice'
import { useNavigate } from 'react-router-dom'
import { MdMessage } from "react-icons/md";
import PdfPreview from '../RentalApp/PdfPreview'
import { MylistDetail, MylistUserInfo } from '../../GenericComp/MylistDetail'
import PropertyOffCampus from '../FilterRommate/PropertyDetaiOffCamp'
import PropertyOnCampus from '../FilterRommate/PropertyDetailOnCamp'
import DeletePopup from '../../../utils/Popup/DeletePopup'
// import { setlocation } from '../../../Redux/UserAuthSlice/UserAuthSlice'
// import { API } from '../../../config/GoolePlace'
// import GooglePlacesAutocomplete from "react-google-autocomplete";
import VerifyRentalApp from '../RentalApp/VerifyRentalApp'
import CreditConfirm from '../../GenericComp/CreditConfirmation'

// import Feedback from '../../GenericComp/Feedback'

// import lists from '../../../lib/contentData.json'
const Saved = ({ t }) => {
  const [savedList, setSaveList] = useState([])
  const [loading, setLoading] = useState(false)
  const [remover, setRemover] = useState(false)
  const [book, setBook] = useState(false)
  const [del, setDel] = useState(false)
  const [type, setType] = useState("")
  const [property, setProperty] = useState({})
  const [propertyDetail, setPropertyDetail] = useState({})
  const [checkLoading, setCheckLoading] = useState(false)
  const [leasePdf, setLeasePdf] = useState("")
  const [isVerify, setIsVerify] = useState(false)
  const [isCredit, setIsCredit] = useState(false)
  const [purchaseType, setPurchaseType] = useState("")

  // const lng = useSelector((state) => state?.userAuth?.language)
  const location = useSelector((state) => state?.userAuth?.location)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  let check = location && Object.keys(location)?.length > 0;

  let fetchData = async () => {
    let { resSaved } = await GetSavedList(location)
    if (resSaved) {
      setSaveList(resSaved)
      return true
    }
    else {
      return false
    }
  }

  // const autocompleteOptions = {
  //   types: ['address'],
  //   componentRestrictions: { country: 'us' }
  // };

  const handleReschdual = (list) => {
    setType("seducla")
    setProperty(list)
    setBook(true)
  }

  const handleBooking = (list) => {
    setType("book")
    // let check = list.property === null ? list?.roommate : list?.property
    setProperty(list)
    setBook(true)
  }


  const OpenModel = (item) => {
    setIsVerify(true)
  }


  const handlePopup = async () => {
    try {
      let res = await LoardScreeningReport("rental_application")
      if (res) {
        let obj = Object.assign({}, ...res)
        setType("pdf")
        setBook(true)
        setIsVerify(false)
        setLeasePdf(obj?.rental_application)
      }
      else {
        setBook(false)
      }
    }
    catch (err) { }

  }



  const handleMessage = (data) => {

    dispatch(AddRentalAppUser(data))
    navigate(`/message/${data?.id}`)
  }

  // const handleRequestLease = async (item, typedata) => {
  //   setType("sendRent")
  //   setProperty(item)
  //   let payload = {
  //     "property_id": item?.property_id,
  //   }
  //   setCheckLoading(true)
  //   let { res } = await ConfirmRequestLease(payload)
  //   if (res) {
  //     await fetchData()
  //     setCheckLoading(false)
  //   }
  // }

  const handleSendRent = async (item) => {
    setType("sendRent")
    setProperty(item)
    setCheckLoading(true)
    let { response } = await CheckRentalApp()
    if (response?.purchased === true) {
      let credetCheck = response?.rental_application?.credits
      const isAnyFlagFalse = Object.values(response?.status).some(flag => flag === false);
      if (isAnyFlagFalse) {
        dispatch(AddIndex(2))
        navigate('/rentalApp')
        setCheckLoading(false)
        // setLoadingSubmit(false)
      }
      else if (credetCheck === 0) {
        setIsCredit(true)
        setCheckLoading(false)
        setPurchaseType("Purchsed")
      }
      else {
        let payload = {
          "property_id": item?.property_id,
        }
        setCheckLoading(true)
        let { res } = await PenddingSendRent(payload)
        if (res) {
          await fetchData()
          setCheckLoading(false)
        }
      }
    }

    else {
      setPurchaseType("notPurchsed")
      setIsCredit(true)
      // setLoadingSubmit(false)

    }
  }

  const handleOpen = (item) => {
    setProperty(item)
    setDel(true)
  }

  const handleRemove = async () => {
    let payload = {
      "list": "archived",
      "action": "removed_from_saved"
    }
    if (property?.property === null) {
      payload.roommate_id = property?.roommate?.id
    }
    else {
      payload.property_id = property?.property_id

    }
    setRemover(true)
    let { resSaved } = await RemoveSavedList(payload)
    if (resSaved) {
      let res = fetchData()
      setDel(false)
      if (res === true) {
        setRemover(false)
      }
      setRemover(false)

    }
  }


  const handleDetail = (detail) => {
    setPropertyDetail(detail)
    setType("detail")
    setBook(true)
  }


  const handleClose = () => {
    setBook(false)
  }

  let fetch = async () => {
    setLoading(true)
    // if (check) {
    const res = await fetchData()
    if (res === true) {
      setLoading(false)
    }
    else {
      setLoading(false)
    }
    // } else {
    //   setLoading(false)
    //   setSaveList([])
    // }
  }

  useEffect(() => {
    if (book === false) {
      fetch()
    }
  }, [book])

  const handleClosePopup = () => {
    setIsVerify(false)
    setBook(false)
    setType("")
  }

  useEffect(() => {
    fetch()
  }, [location])

  if (isVerify) {
    return (<DynamicPopup modalOpen={isVerify} data={<VerifyRentalApp handleClose={handlePopup} />}
      close={() => handleClosePopup()}
    />)
  } else {
    return (
      <>
        {isCredit && <DynamicPopup data={<CreditConfirm purchaseType={purchaseType} close={() => setIsCredit(false)} />} modalOpen={isCredit} close={() => setIsCredit(false)} />}
        {book && <DynamicPopup modalOpen={book} data={type === "detail" ?
          propertyDetail?.property === null ? propertyDetail?.roommate?.property_details !== null ? <PropertyOffCampus detail={propertyDetail?.roommate} close={() => setBook(false)} /> : <PropertyOnCampus detail={propertyDetail?.roommate} close={() => setBook(false)} /> : <RentalsDetail detail={propertyDetail?.property} close={() => setBook(false)} /> : type === "pdf" ? <PdfPreview pdf={leasePdf} t={t} /> : <BookAppointment property={property} />} close={() => handleClose()} />}

        {del && <DeletePopup permition={del} loading={remover} Toggle={(val) => setDel(val)} callback={handleRemove} title={t('archive_this')} />}

        {/* {show && <DynamicPopup modalOpen={show} data={<Feedback />} close={() => setShow(false)} />} */}

        <div className=' blog_list  mt-4'>
          {
            savedList?.length > 0 ?
              <div className='grid lg:grid-cols-4 md:grid-cols-2  gap-4'>
                {

                  savedList?.map((list, i) => {
                    return (
                      <div className='rounded-md bg-white shadow-md pb-4 mb-8 w-full relative' key={i} >
                        <div className='relative'>
                          <img src={list?.property !== null ? list?.property?.image : list?.roommate?.images?.length > 0 ? list?.roommate?.images[0]?.image : list?.roommate?.user?.user_image} className="w-full h-[200px] object-cover" alt="faverit_img" />

                          <div className='absolute top-0 w-full bg-black h-full bg-opacity-[0.5] rounded-md ' />
                          <div className='absolute top-[7%]  right-[5%]'>
                            <div className=' cursor-pointer rounded-full p-[3px] w-8 h-8 flex justify-center items-center text-white bg-[#F63030] ' onClick={() => handleOpen(list)}>
                              <div className='text-[20px] ' >
                              </div>{property?.id === list?.id && remover === true && type === "remove" ? <div className='flex items-start' ><Loader type="single" /> </div> : <IoClose />}
                            </div>
                            <div onClick={() => handleMessage(list?.property !== null ? list?.property?.user : list?.roommate?.user)} className='mt-1 cursor-pointer p-[3px] text-white bg-blue-500 w-8 h-8 flex justify-center items-center rounded-full' >
                              <div className='text-[18px] ' >
                              </div> <MdMessage />
                            </div>

                          </div>

                          <div className='absolute p-[2px] top-[7%] text-white  left-[5%]'>
                            <div className='text-[20px] cursor-pointer bg-gray-200 rounded-full' onClick={() => handleDetail(list)}>
                              <img src={gp4} alt="detail" className='w-6 h-6' />
                            </div>
                            <div className='flex flex-col gap-2 mt-2'>
                              {list?.property?.flashlane && list?.property?.flashlane.seconds > 0 && (
                                <div className=''>
                                  <img src={flash} alt="detail" className='w-6 h-6' />
                                </div>
                              )}
                              {list?.property?.reazy_lane && list?.property?.reazy_lane.seconds > 0 && (
                                <div className=' bg-white rounded-md mx-w-max'>
                                  <img src={reazy} alt="detail" className='w-6 h-6' />
                                </div>
                              )}
                            </div>

                          </div>
                          <div>
                            <MylistDetail list={list} />
                          </div>
                        </div>
                        {check &&
                          <div className='px-3 pt-2'>
                            <p className='text-[12px]'>{list?.property && list?.property ? list?.property?.distance :list?.roommate?.property_details?.distance}</p>
                          </div>
                        }
                        <div className='flex justify-between items-center p-3 h-[50px]'>
                          <MylistUserInfo list={list} />
                          {
                            list?.booking_details && Object?.keys(list?.booking_details).length > 0 &&
                            <div className='w-[50%]'>
                              <h2 className='text-[11px] font-semibold text-right'>{list?.booking_details?.date_of_appointment}</h2>
                              <h2 className='text-[11px] font-semibold text-right'>{list?.booking_details?.time_of_appointment} </h2>
                            </div>
                          }

                        </div>

                        <div className={` w-full`}>
                          {list?.roommate === null ?
                            <div className='flex justify-between flex-wrap gap-2 mx-2'>
                              {
                                list?.booking_details === null ?
                                  <>
                                    <button onClick={() => handleBooking(list)} className='px-6 py-1 rounded-md bg-[#00AF0C] w-full text-white'>{
                                      property?.id === list?.id && type === "book" && book ? <div className='flex items-center justify-center' ><Loader type="single" /> </div> : t('book')
                                    }</button>
                                  </>
                                  :
                                  <>
                                    <button onClick={() => handleReschdual(list)} className={`px-3 w-full py-1 rounded-md bg-green-500 text-white `}>
                                      {t('reschedule')}
                                    </button>

                                  </>
                              }
                              {list?.rental_app_sent !== null ?
                                <button onClick={() => OpenModel(list)} className={`px-[6px] py-1   rounded-md bg-blue-500 w-full text-white`}>
                                  {t('view_rent_app')}
                                </button>
                                :
                                <button onClick={() => handleSendRent(list)}
                                  className={`px-[5px] py-1  w-full  rounded-md bg-blue-500 text-white `}>
                                  {property?.id === list?.id && type === "sendRent" && checkLoading ? <div className='flex items-center justify-center ' ><Loader type="single" /> </div> : t('send_rent_ap')}
                                </button>
                              }
                              {/* {
                                list?.rental_app_sent !== null ?
                                  <>
                                    <button onClick={() => OpenModel(list)} className={`px-[6px] py-1   rounded-md bg-blue-500 w-full text-white`}>
                                      {t('view_rent_app')}
                                    </button>
                                  </>
                                  :
                                  list?.roommate === null ? null :
                                    <>
                                      <button onClick={() => handleSendRent(list)}
                                        className={`px-[5px] py-1  w-full  rounded-md bg-blue-500 text-white `}>
                                        {property?.id === list?.id && type === "sendRent" && checkLoading ? <div className='flex items-center justify-center ' ><Loader type="single" /> </div> : t('send_rent_ap')}
                                      </button>
                                    </>
                              } */}
                            </div>
                            :
                            <div className='flex justify-between flex-wrap gap-2 mx-2'>
                              {
                                list?.roommate !== null && list?.booking_details === null ?
                                  <>
                                    <button onClick={() => handleBooking(list)} className='px-6 py-1 rounded-md bg-[#00AF0C] w-full text-white'>{
                                      t('book')
                                    }</button>
                                  </>
                                  :
                                  <>
                                    <button onClick={() => handleReschdual(list)} className={`px-3 w-full py-1 rounded-md bg-green-500 text-white `}>
                                      {t('reschedule')}
                                    </button>

                                  </>
                              }
                            </div>
                          }
                        </div>

                      </div>

                    )
                  })

                }
              </div >
              :
              loading ? <div className='flex justify-start items-start'><Loader type="loadData" /> </div>
                :
                // isLocationDenied ?
                //   <div>
                //     <p>{t('enter_loc')}</p>
                //     <GooglePlacesAutocomplete
                //       apiKey={API}
                //       options={autocompleteOptions}
                //       onPlaceSelected={async (place) => {
                //         let latitude = place?.geometry?.location?.lat()
                //         let longitude = place?.geometry?.location?.lng()
                //         setIsLocation(false)
                //         dispatch(setlocation({ latitude, longitude }))
                //       }}
                //       className='border p-2 w-full focus:outline-none rounded-md my-2'
                //       placeholder={t('location_search')}
                //     />

                //   </div>
                //   :
                <div>
                  <div className='text-center'>
                    <div className='flex justify-center items-center w-full '>
                      <div className='flex justify-center items-center'>
                        <img src={Home} alt="home" className='w-[70%]' />
                      </div>
                    </div>
                    <div className='pt-5'>
                      <h2 className='text-xl font-bold '>{t('record_not_found')}</h2>
                    </div>
                  </div>
                </div>

          }


        </div >
      </>
    )
  }
}

export default Saved