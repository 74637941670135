import React, { useEffect, useState } from 'react'
import { Loader } from '../../../utils/loaderButton'
import { Controller } from "react-hook-form";
import Select from 'react-select'
import { GetCountries } from '../../../lib/Api/RantelServiceApi';
import { GetCampusActivities, GetCampusInterest } from '../../../lib/Api/RentalAppApi';
import { useSelector } from 'react-redux';

const OnCampusSidebar = ({
  t,
  register,
  control,
  error,
  errors,
  setAct,
  setIntr,
  Act,
  Intr,
  setActive,
  active,
  searchLoading,
  loading,
  setLoading



}) => {
  const [options, setOptions] = useState([]);
  const [loadingCon, setLoadingCon] = useState(false)
  const [intrests, setInterest] = useState([])
  const [activity, setActivity] = useState([])

  const gradle = [
    { heading: t('gradle_op1'), key: "Freshmen" },
    { heading: t('gradle_op2'), key: "Sophomore" },
    { heading: t('gradle_op3'), key: "Junior" },
    { heading: t('gradle_op4'), key: "Senior" },
    { heading: t('gradle_op5'), key: "Post-Graduate" },
    { heading: t('gradle_op6'), key: "Graduate" },
    { heading: t('gradle_op7'), key: "Other" }
  ]

  const { universityInfo } = useSelector((state) => state?.rommate)

  // console.log("universityInfo", universityInfo)


  const HandleInterestChecks = (id) => {
    if (Intr?.includes(id)) {
      let l = Intr.filter((f) => f !== id)
      setIntr(l)
    }
    else {
      setIntr((prev) => ([...prev, id]))
    }

  }
  const handleActivityChecks = (id) => {
    if (Act?.includes(id)) {
      let l = Act.filter((f) => f !== id)
      setAct(l)
    }
    else {
      setAct((prev) => ([...prev, id]))
    }
  }

  let getCountries = async () => {
    try {
      setLoadingCon(true)
      let res = await GetCountries()
      if (res) {
        setLoadingCon(false)
        const formattedOptions = res.map(item => ({ label: item.name, value: item.id }));
        setOptions(formattedOptions);
      }
    }
    catch (err) { }
  }

  let GetActivityInterest = async () => {
    setLoading(true)
    let { resActivity } = await GetCampusActivities()
    let { resInterest } = await GetCampusInterest()
    const ConvertActi = Object.assign({}, ...resActivity)
    const ConvertIntr = Object.assign({}, ...resInterest)
    if (resActivity && resInterest) {
      setLoading(false)
      setActivity(ConvertActi.activities)
      setInterest(ConvertIntr.interests)
      if (Object.keys(universityInfo)?.length > 0) {
        setAct(universityInfo?.extra_curricular_activities)
        setIntr(universityInfo?.interests)
      }
    }
    else {
      setLoading(false)
    }
  }


  useEffect(() => {
    getCountries()
    GetActivityInterest()

  }, [])


  // useEffect(() => {
  //   if (Object.keys(universityInfo)?.length > 0) {

  //   }
  // }, [activity, intrests])

  // console.log("universityInfo", universityInfo)


  return (
    <>
      <div className='grid grid-cols-1 gap-2'>
        <div className='flex flex-col'>
          <label className='font-medium' htmlFor="">{t('rommate_uni')}</label>
          <Controller
            name="university_name"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className="rounded-md mt-1  focus:outline-none"
                placeholder={t('search_lab')}
                isClearable={true}
                isSearchable={true}
                isLoading={loadingCon}
                options={options}
              />
            )}
          />
          {errors.university_name && (
            <p className="text-red-500 text-sm ">{errors.university_name.message}</p>
          )}
        </div>
        <div className='flex flex-col'>
          <label className='font-medium' htmlFor="">{t('rommate_uni_dorm')}</label>
          <input {...register('domm_name')} type='text' placeholder={t('rommate_uni_dorm')} className='p-2 rounded-md border focus:outline-none' />
          {/* {errors.domm_name && (
                        <p className="text-red-500 text-sm ">{errors.domm_name.message}</p>
                      )} */}
        </div>
      </div>
      <div className='grid grid-cols-1 gap-2 mt-2 '>
        <div className='flex flex-col  '>
          <label className='font-medium' htmlFor="">{t('rommate_uni_major')}</label>
          <input {...register('major')} type='text' placeholder={t('rommate_uni_major')} className='p-2 rounded-md border focus:outline-none' />
          {errors.major && (
            <p className="text-red-500 text-sm ">{errors.major.message}</p>
          )}
        </div>
        <div className='flex flex-col '>
          <label className='font-medium' htmlFor="">{t('rommate_uni_grad')}</label>
          <select
            className='p-2 rounded-md border focus:outline-none'
            {...register('grade_level')}>
            <option>{t('please_select')}</option>
            {
              gradle?.map((grad, index) => (
                <option key={index} value={grad?.key}>{grad?.heading}</option>
              ))
            }
          </select>
          {errors.grade_level && (
            <p className="text-red-500 text-sm ">{errors.grade_level.message}</p>
          )}
        </div>
      </div>
      <div>
        <h2 className='text-[20px] font-semibold my-4'>{t('rommate_uni_stay')}</h2>
        <div className='flex items-center xl:gap-2 lg:gap-2 md:gap-2  '>
          <div onClick={() => setActive(0)} className={`${active === 0 ? 'border-[#F79F15] border-2' : 'border-gray-100'} bg-white font-medium text-[14px] shadow-md border  rounded-md p-2 cursor-pointer`}>
            {t('rommate_uni_one_sm')}
          </div>
          <div onClick={() => setActive(1)} className={`${active === 1 ? 'border-[#F79F15] border-2' : 'border-gray-100'} bg-white font-medium text-[14px] shadow-md xl:mx-5 lg:mx-5 md:mx-5 ml-[10px] border rounded-md p-2 cursor-pointer`}>
            {t('rommate_uni_two_sm')}
          </div>
        </div>

      </div>
      <div>
        <h2 className='text-[20px] font-semibold my-4'>{t('rommate_ex_acti')}</h2>
        {
          loading ?
            <div className='flex justify-start items-start'>
              <Loader type="loadData" />
            </div>
            :
            <div className='flex flex-col'>
              <div className='flex flex-wrap items-center '>
                {
                  activity?.map((c, i) => {
                    let d = Act.find((f) => f === c.id)
                    return (
                      <div className='flex items-center mx-1' key={i}>
                        <input id={c?.id} type="checkbox" onChange={() => handleActivityChecks(c.id)} defaultChecked={d} className='w-[20px] h-[20px]' />
                        <label htmlFor={c?.id} className='mx-1 text-[16px]'>{c.activity}</label>
                      </div>
                    )
                  })
                }
              </div>
              {error && (
                <p className="text-red-500 text-sm pt-2 ">{error.active}</p>
              )}
            </div>
        }

        <h2 className='text-[20px] font-semibold my-4'>{t('interested')}</h2>
        {
          loading ?
            <div className='flex justify-start items-start'>
              <Loader type="loadData" />
            </div>
            :
            <div className='flex flex-col'>

              <div className='flex flex-wrap items-center'>
                {
                  intrests?.map((c, i) => {
                    let d = Intr.find((f) => f === c.id)
                    return (
                      <div className='flex items-center mx-1' key={i}>
                        <input id={c?.id} name="interst" type="checkbox" onChange={() => HandleInterestChecks(c.id)} defaultChecked={d} className='w-[20px] h-[20px]' />
                        <label htmlFor={c?.id} name="interst" className='mx-1 text-[16px]'>{c.interest}</label>
                      </div>
                    )
                  })
                }
              </div>
              {error && (
                <p className="text-red-500 text-sm pt-2 ">{error.intrest}</p>
              )}
            </div>
        }
        <div className='flex justify-end py-3'>
          {
            <button type='submit' className='font-semibold bg-[#F79F15] w-full px-6 py-1.5 text-white rounded-md'>{searchLoading ? <Loader type="Save" /> : t('search_lab')}</button>
          }
        </div>
      </div>
    </>
  )
}

export default OnCampusSidebar