import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    payment_id: null,
    active_Index: null,
    active_Tab: [],
    app_id: null,
    rentalapp: null,
    rentAppSend: {},
    rentalUserInfo: {},
    rentalData: {},
    searchProperty: {
        min: 0,
        max: 0,
        address: "",
        bath: 0,
        bed: 0,
        property: "",
        leasedPeriod: "",
        radius: 0,
        aminity: []
    },
    flashLane : {},
    isNew:false ,
    isLoginPop: false

}
export const CoSignerRentalSlice = createSlice({
    name: 'CoSignerRentalSlice',
    initialState,
    reducers: {

        AddPayment: (state, action) => {
            state.payment_id = action?.payload
        },
        SendRent: (state, action) => {
            state.rentAppSend = action?.payload
        },
        AddRentalId: (state, action) => {
            state.app_id = action?.payload
        },
        CheckRentalApp: (state, action) => {
            state.rentalapp = action?.payload
        },
        RemoveCheckRentalApp: (state, action) => {
            state.rentalapp = action?.payload
        },
        AddRentalAppUser: (state, action) => {
            state.rentalUserInfo = action?.payload
        },
        AddIndex: (state, action) => {
            state.active_Index = action?.payload
        },
        GoStart: (state, action) => {
            state.active_Tab = action?.payload
        },
        addTabs: (state, action) => {
            state.active_Tab = action.payload
        },
        addRentals: (state, action) => {
            state.rentalData = action.payload
        },
        addPopup: (state, action) => {
            state.isLoginPop = action.payload
        },
        addExplore: (state, action) => {
            state.searchProperty[action.payload?.propertyName] = action.payload?.value;
        },
        addFlashLane: (state, action) => {
            state.flashLane = action.payload;
        },
        resetSearch: (state, action) => {
            state.searchProperty = initialState?.searchProperty
        },
        AddActiveTab: (state, action) => {
            const updatedTabs = state.active_Tab.map((tab, index) => {
                if (index === state.active_Tab.length - 1) {
                    return { ...tab, submited: true };
                } else {
                    return tab;
                }
            });
            // Check if the tab already exists
            const existingTab = state.active_Tab.find(tab => tab.tab === action.payload.tab);
            if (!existingTab) {
                updatedTabs.push({ tab: action.payload.tab, submited: action.payload.submited });
            }
            return {
                ...state,
                active_Tab: updatedTabs
            };
        },
        AddFilterTab: (state, action) => {
            return {
                ...state,
                active_Tab: action.payload
            }

        },
        setIsNewStatus:(state , action ) => {
           state.isNew = action.payload
        },
        UpdateTab: (state, action) => {
            const existingTabIndex = state.active_Tab.findIndex(tab => tab.tab === action.payload.tab);
            if (existingTabIndex !== -1) {
                // Keep all tabs up to the clicked one and filter out subsequent tabs with submited: false
                const updatedTabs = state.active_Tab.slice(0, existingTabIndex + 1).concat(
                    state.active_Tab.slice(existingTabIndex + 1).filter(tab => tab.submited === true)
                );

                return {
                    ...state,
                    active_Tab: updatedTabs
                };
            } else {
                // Add the new tab if it does not exist
                return {
                    ...state,
                    active_Tab: [...state.active_Tab, { tab: action.payload.tab, submited: action.payload.submited }]
                };
            }
        },
        SplitActiveTab: (state, action) => {
            const newActiveTab = state.active_Tab.filter(tab => tab.tab !== action.payload.tab);
            return {
                ...state,
                active_Tab: newActiveTab
            };
        },

        ClearAll:()=> {
            return initialState
        }

    },
})

// Action creators are generated for each case reducer function
export const { AddPayment, AddRentalId, CheckRentalApp, RemoveCheckRentalApp, AddRentalAppUser, AddIndex, AddActiveTab, GoStart, SendRent, addTabs, addRentals, addExplore, addPopup, resetSearch, UpdateTab , AddFilterTab, ClearAll , addFlashLane, setIsNewStatus } = CoSignerRentalSlice.actions

export default CoSignerRentalSlice.reducer