import React, { useEffect } from 'react'
import { BsTrash } from 'react-icons/bs';
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { CreateDebtInfo } from '../../../lib/Api/RentalAppApi';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Loader } from '../../../utils/loaderButton';
import { useTranslation } from 'react-i18next';
import { compareArrays } from '../../../hooks/ValidateRentalApp';
import { addRentals } from '../../../Redux/UserAuthSlice/CoSignerRentalSlice';



let DebtValues = {
  debt_type: "",
  balance: "",
  monthly_payment: "",
  payments_cuurent: false,
}

const DebtInfo = ({ handleNext, handleBack, userid }) => {
  const [selectOne, setSelectedOne] = useState(false)
  const { rentalData , app_id } = useSelector((state) => state?.coSignerRental)
  let rentalApp = rentalData?.debt
  let checkBoxList = rentalData?.debt_info
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [checkList, setCheckList] = useState([
    { id: 0, heading: t('suid_f_bal'), name: "sued_for_bills", value: false },
    { id: 1, heading: t('bankrupt'), name: "bankrupt", value: false },
    { id: 2, heading: t('locked_out'), name: "locked_out", value: false },
    { id: 3, heading: t('lock_to_cart'), name: "brought_to_court", value: false },
    { id: 4, heading: t('flony_conv'), name: "felony_convict", value: false },
    { id: 5, heading: t('broken_leas'), name: "broken_lease", value: false },
    { id: 6, heading: t('demage'), name: "damaged", value: false }
  ])

  const [loading, setLoading] = useState(false)
  const lang = useSelector((state) => state?.userAuth?.language)

  const schemaWithValidation = yup.object().shape({
    values: yup.array().of(
      yup.object().shape({
        debt_type: yup.string().required(t('dbt_invalid')),
        balance: yup.string().required(t('blanc_invalid')),
        monthly_payment: yup.string().required(t('mon_p_invalid')),
        payments_cuurent: yup.string(),
      })
    ),
  });

  const schemaWithoutValidation = yup.object().shape({
    values: yup.array().of(
      yup.object().shape({
        debt_type: yup.string(),
        balance: yup.string(),
        monthly_payment: yup.string(),
        payments_cuurent: yup.string(),
      })
    ),
  });

  const schema = selectOne ? schemaWithoutValidation : schemaWithValidation;

  const { register, handleSubmit, control, reset, setValue, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema), defaultValues: { values: rentalApp?.length > 0 ? rentalApp : [DebtValues] } });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'values'
  });



  const formatCurrency = (value) => {

    // Remove all non-digit characters
    if (!rentalApp) {
      const numericValue = value?.replace(/[^0-9]/g, '');
      const formattedValue = new Intl.NumberFormat('en-US').format(numericValue || 0);
      return `$${formattedValue || 0}`;
    }
    else {
      const formattedValue = new Intl.NumberFormat('en-US').format(value || 0);
      return `$${formattedValue || 0}`;
    }
  };

  const handleChecks = (item) => {
    setCheckList((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) =>
        checkbox.id === item ? { ...checkbox, value: !checkbox.value } : checkbox
      )
    );
  }

  // Convert Array of object to single object
  const convertedObject = checkList?.reduce((result, item) => {
    result[item.name] = item.value;
    return result;
  }, {});
  const CompareCheckList = () => {
    let chcekCompare = true; // Assume they match until proven otherwise
    checkList.forEach(element => {
      const val = element.value ? "1" : "0";
      const matchingValue = checkBoxList[element.name];
      if (matchingValue !== val) {
        chcekCompare = false; // If any pair doesn't match, set to false
      }
    });

    return chcekCompare;
  };


  const handleDebtTypeChange = (index, event) => {
    if (event.target.value === "Not Applicable (N/A)") {
      setSelectedOne(true)
    } else {
      setSelectedOne(false)
      setValue(`values.${index}.debt_type`, event.target.value);
    };
  }

  const debtTypes = useWatch({
    control,
    name: fields.map((_, index) => `values.${index}.debt_type`),
  });

  const onSubmit = async (data) => {
    data.values.forEach((element) => {
      element.payments_cuurent = element.payments_cuurent === "true" ? true : false;
    });

    const checkCompare = CompareCheckList()
    let check = !compareArrays(rentalApp, data.values)

    if (check || checkCompare === false) {
      try {
        let paylod = {
          "app_id": rentalData?.id || app_id,
          "co_signer_id": userid ?? null,
          "debt": selectOne ? [] : data?.values,
          ...convertedObject
        }
        setLoading(true)
        let { res } = await CreateDebtInfo(paylod)
        if (res) {
          dispatch(addRentals(res[0]?.rental_application))
          setLoading(false)
          handleNext(5)
        }
        else {
          setLoading(false)
        }
      }
      catch (err) {
        setLoading(false)

      }
    }
    else {
      handleNext(5)
    }
  }



  useEffect(() => {
    if (checkBoxList) {
      Object.entries(checkBoxList).map(([key, value]) => {
        for (let index = 0; index < checkList.length; index++) {
          const element = checkList[index];
          if (element.name === key) {
            setCheckList((prevCheckboxes) =>
              prevCheckboxes.map((checkbox) =>
                checkbox.name === key ? { ...checkbox, value: value === '1' ? true : false } : checkbox
              )
            );

          }

        }
      })
    }
  }, [lang])

  useEffect(() => {
    if (rentalApp?.length > 0) {
      let payload = {
        values: rentalApp.map(app => ({
          payments_cuurent: app.payments_cuurent === 0 ? false : true
        })),
      }
      if (rentalApp[0]?.debt_type === "Not Applicable (N/A)") {
        setSelectedOne(true)
      } else {
        setSelectedOne(false)
      }
      reset(payload)
    }
  }, [reset, rentalApp])

  // //console.log("Sele", selectOne)

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} >
        <h2 className='text-[20px] font-bold text-center p-8'>{t('d_info')}</h2>

        <div className='xl:h-[250px]  lg:h-[250px]  md:h-[250px] h-full xl:overflow-y-auto lg:overflow-y-auto md:overflow-y-auto'>
          {
            fields.map((field, index) => {
              // const isNotApplicable = debtTypes[index] === "Not Applicable (N/A)";
              const isNotApplicable = selectOne && index === 0;
              const hideItem = selectOne && index !== 0;
              return (
                <div className={`border rounded-md px-4 py-2 my-2 ${hideItem ? 'hidden' : ''}`} key={index}>
                  <div className='flex justify-end'>
                    {
                      index !== 0 &&
                      <button type='button' onClick={() => remove(index)} className=' p-2 bg-red-500 rounded-md '><BsTrash className='text-white' /></button>
                    }
                  </div>
                  <div className='grid xl:grid-cols-2 lg:grid-cols-2 xl:gap-20 lg:gap-20'>
                    <div className='flex flex-col mb-4'>
                      <label className='mb-2 text-[16px] font-semibold'>{t('dbt_lbl')}<span className='text-red-500'>*</span></label>
                      <select
                        name={`values[${index}].debt_type`}
                        {...register(`values.${index}.debt_type`)}
                        onChange={(e) => handleDebtTypeChange(index, e)}
                        className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none'>
                        <option value="">{t('please_select')}</option>
                        <option value="Car">{t('dbt_car')}</option>
                        <option value="Lien Holder">{t('dbt_lier')}</option>
                        <option value="Credit Card">{t('dbt_credit')}</option>
                        <option value="Mortgage">{t('dbt_bank')}</option>
                        <option value="Bank">{t('dbt_mort')}</option>
                        <option value="Student Loan">{t('dbt_student')}</option>
                        {index === 0 && <option value="Not Applicable (N/A)">{t('not_aplicable')}</option>}
                      </select>

                      {errors.values && errors.values[index] && errors.values[index].debt_type && (
                        <span className='text-red-500 text-sm '>{errors.values[index].debt_type.message}</span>
                      )}

                    </div>
                    {!isNotApplicable &&
                      <div className='flex flex-col mb-4'>
                        <label className='mb-2 text-[16px] font-semibold'>{t('blanc_lbl')}<span className='text-red-500'>*</span></label>

                        <Controller
                          name={`values[${index}].balance`}
                          control={control}
                          defaultValue=''
                          render={({ field }) => (
                            <input
                              {...field}
                              type="text"
                              className=' p-2 rounded-md bg-white border focus:outline-none'
                              value={formatCurrency(field.value) || 0}
                              placeholder='$50'
                              onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}
                            />
                          )}
                        />
                        {errors.values && errors.values[index] && errors.values[index].balance && (
                          <span className='text-red-500 text-sm '>{errors.values[index].balance.message}</span>
                        )}

                      </div>
                    }
                  </div>
                  {!isNotApplicable &&
                    <div className='grid xl:grid-cols-3 lg:grid-cols-3 xl:gap-20 lg:gap-20 '>
                      <div className='flex flex-col mb-4'>
                        <label className='mb-2 text-[16px] font-semibold'>{t('mon_p_lbl')} <span className='text-red-500'>*</span></label>

                        <Controller
                          name={`values[${index}].monthly_payment`}
                          control={control}
                          defaultValue=''
                          render={({ field }) => (
                            <input
                              {...field}
                              type="text"
                              className=' p-2 rounded-md bg-white border focus:outline-none'
                              value={formatCurrency(field.value) || 0}
                              placeholder='$50'
                              onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}
                            />
                          )}
                        />
                        {errors.values && errors.values[index] && errors.values[index].monthly_payment && (
                          <span className='text-red-500 text-sm '>{errors.values[index].monthly_payment.message}</span>
                        )}
                      </div>
                      <div className='flex flex-col mb-4'>
                        <label className='mb-2 text-[16px] font-semibold'>{t('payment_ac')}<span className='text-red-500'>*</span></label>
                        <div className="flex justify-start">
                          <label className="relative inline-flex items-center cursor-pointer">
                            <Controller
                              name={`values[${index}].payments_cuurent`}
                              control={control}
                              defaultValue={field.payments_cuurent} // Set the initial value of the checkbox
                              render={({ field }) => (
                                <>
                                  <input
                                    type="checkbox"
                                    className="sr-only peer"
                                    role="switch" id="flexSwitchCheckDefault"
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    checked={field.value}
                                  />
                                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#F79F15] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#F79F15]"></div>
                                  {/* <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Toggle me</span> */}
                                  <label className="form-check-label inline-block text-gray-800 ms-3" for="flexSwitchCheckDefault">{field.value ? t('yes') : t('no')}</label>
                                </>
                              )}
                            />
                          </label>

                        </div>

                      </div>

                    </div>
                  }
                </div>
              )
            })
          }
        </div>
        {selectOne === true ? "" :
          <div className='flex justify-end mb-4'>
            <button onClick={() => append(DebtValues)} type='button' className=' px-3 py-2 bg-green-500 rounded-md text-white font-semibold '>{t('add_m_lbl')}</button>
          </div>
        }
        <div>
          <div className='grid xl:grid-cols-4 lg:grid-cols-4  xl:gap-10 lg:gap-10'>
            {
              checkList?.map((list, i) => (
                <div className='flex flex-col mb-4 ' key={i}>
                  <label className='mb-2 text-[16px] font-semibold'>{list.heading}</label>
                  <div className="flex justify-start">
                    <label className='relative inline-flex items-center cursor-pointer'>
                      <input
                        type="checkbox"
                        name={list?.name}
                        checked={list.value === true ? true : false}
                        className="sr-only peer"
                        defaultValue={list.value}
                        onChange={() => handleChecks(i)}
                      />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#F79F15] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#F79F15]"></div>

                      <label className="form-check-label inline-block text-gray-800 ms-3" for={i}>{list.value === true ? t('yes') : t('no')}</label>

                    </label>
                  </div>
                </div>

              ))
            }
          </div>

        </div>


        {/* <div className='flex justify-end py-3'>
          <button type="button" onClick={() => OnSubmit()} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{`Next > `}</button>
        </div> */}

        <div className='flex justify-between items-center'>

          <div className='flex justify-end py-3'>
            <button onClick={() => handleBack(5)} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Back')}</button>
          </div>


          <div className='flex justify-end py-3'>

            <button className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
              {
                loading ? <Loader type="saveLoder" />
                  :
                  t('Next')
              }
            </button>
          </div>
        </div>
      </form >

    </>
  )
}

export default DebtInfo