import React, { useState, useEffect } from 'react'
import blogImg from '../../../assests/images/blogImg.png'
import bath from '../../../assests/images/bath.png'
import bed from '../../../assests/images/bed.png'
import person from '../../../assests/svg/Icon ionic-ios-person.svg'
import locat from '../../../assests/svg/Icon material-location-on.svg'
import { Link, useParams } from 'react-router-dom';
import LikeDislikePopup from '../../../utils/Popup/LikeDisLike';
import { MdArrowBackIosNew, MdMail, MdPhone, MdShoppingBag, MdVerifiedUser } from 'react-icons/md'
import { RiEditBoxLine } from 'react-icons/ri'
import DynamicPopup from '../../../utils/Popup/DynamicPopup'
import EditAminty from './EditProperty/EditAminty'
import EditRentAmount from './EditProperty/EditRentAmount'
import EditDesc from './EditProperty/EditDesc'
import EditPropertyPic from './EditProperty/EditPropertyPic'
import EditPropertyType from './EditProperty/EditPropertyType'
import { GetLeasedPeriod, GetPublicRentById, GetRentById } from '../../../lib/Api/RantelServiceApi'
import { Loader } from '../../../utils/loaderButton'
import { Swiper, SwiperSlide } from 'swiper/react';
// import { toast } from "react-toastify"
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
// import required modules
import { Pagination, Navigation } from 'swiper';
import { useTranslation } from 'react-i18next'
import PdfPreview from '../../../components/RenatalPanelComp/RentalApp/PdfPreview'
import { useSelector } from 'react-redux'
import { GetPublicLeasedPeriod } from '../../../lib/Api/RentalAppApi'
import SelectLanguage from '../../../components/ServicerPanelComp/Flash/SelectLanguage'
const RenterDetails = ({ detail }) => {
    const [show, setShow] = useState(false)
    const [edit, setEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [pdf, setPdf] = useState('')
    const [properties, setProperties] = useState({})
    const [type, setType] = useState("")
    const [editType, setEditType] = useState('')
    const [Leased, setLeased] = useState([])
    const { token } = useSelector((state) => state?.userAuth?.loginInfo)

    const ViewPdf = (lease) => {
        setPdf(lease?.lease_built?.pdf)
        setEdit(true)
        setEditType("leased")

    }

    // const ShowMessage = () => {
    //     toast.success(t('property_report'))
    // }
    const id = useParams()

    let fetchData = async () => {
        setLoading(true)
        let check = token === null ? GetPublicRentById(detail?.id) : GetRentById(id)
        let { res } = await check
        let obj = Object.assign({}, ...res)
        if (res) {
            setLoading(false)
            setProperties(obj.property || obj?.listing)
        }

    }

    // const handlBlock = () => {}

    useEffect(() => {
        if (editType === '') {
            fetchData()
        }
    }, [id, editType])

    useEffect(() => {
        let fetchLeased = async () => {
            let check = token === null ? GetPublicLeasedPeriod() : GetLeasedPeriod()
            let { res } = await check;
            const ConvertObj = Object.assign({}, ...res)
            if (res) {
                setLeased(ConvertObj?.lease_periods)
            }
        }
        fetchLeased()
    }, [])


    let property = Number(properties?.leased_period_id)
    let leasedFind = Leased?.find((f) => f.id === property)

    const url = `https://www.google.com/maps?q=${properties?.complete_address}`
    const { t } = useTranslation()

    return (
        <>
            <>
                {show && <LikeDislikePopup permition={show} Toggle={setShow} type={type} />}
                {edit && <DynamicPopup close={() => setEdit(false)} modalOpen={edit} data={editType === "desc" ? <EditDesc data={properties} close={(val) => setEdit(val)} setEditType={setEditType} /> : editType === "rent_amount" ? <EditRentAmount data={properties} close={(val) => setEdit(val)} setEditType={setEditType} /> : editType === "aminity" ? <EditAminty data={properties} close={(val) => setEdit(val)} setEditType={setEditType} /> : editType === "leased" ? <PdfPreview pdf={pdf} t={t} /> :
                    editType === "propertyPic" ? <EditPropertyPic data={properties} setProperties={setProperties} setEditType={setEditType} close={(val) => setEdit(val)} /> : editType === "language" ? <SelectLanguage fetchData={fetchData} data={properties} type="property" close={() => setEdit(false)} /> : <EditPropertyType data={properties} close={(val) => setEdit(val)} setEditType={setEditType} />} />}
                {
                    loading ?
                        <div className="flex justify-center items-center text-[120px]">
                            <Loader type="loadData" />
                        </div>
                        :
                        <div className="xl:w-[700px]  lg:w-[700px] md:w-full w-[500px] max-w-full col-span-3  xl:mx-auto lg:mx-auto md:mx-auto  xl:px-10 lg:px-10 xl:pb-0 lg:pb-0 md:pb-0 pb-20  ">
                            <div className="flex justify-center flex-col items-center">

                                {/* center section  */}
                                <div className="xl:max-w-[500px] xl:col-span-3 w-full   mx-auto  ">
                                    <div className='relative border shadow'>
                                        <Swiper
                                            pagination={{
                                                type: 'progressbar',
                                            }}
                                            navigation={true}
                                            centeredSlides={true}
                                            modules={[Pagination, Navigation]}
                                            className="h-[400px] w-full"
                                        >
                                            {
                                                properties?.images?.length > 0 ?
                                                    properties?.images?.map((item, i) => (
                                                        <SwiperSlide key={i} className='bg-[#efefef]'>
                                                            <img
                                                                src={item?.image || blogImg}
                                                                alt="blogImg"
                                                                className='h-full object-contain'
                                                            />
                                                        </SwiperSlide>
                                                    ))
                                                    :
                                                    <SwiperSlide className='bg-[#efefef]'>
                                                        <img src={properties?.user?.user_image || blogImg} alt="blogImg" className='h-full' />
                                                    </SwiperSlide>

                                            }


                                        </Swiper>
                                        {token &&
                                            <div className='py-5'>
                                                <div onClick={() => { return setEditType('propertyPic'), setEdit(true) }} className='flex items-center justify-end mr-3 pl-1 text-blue-500 cursor-pointer '>
                                                    <h2>{t('edit')}</h2>
                                                    <RiEditBoxLine />
                                                </div>
                                            </div>
                                        }
                                        <div className=' absolute bottom-4 left-4  '>
                                            <div className='bg-gray-100  rounded-full px-2'>
                                                {properties?.human_readable_time}
                                            </div>
                                        </div>
                                        <div className="absolute top-5 z-40  w-full px-4">
                                            <div className="flex justify-between items-center">
                                                <Link to="/servicerPanel">
                                                    <MdArrowBackIosNew className='text-[30px] text-red-500 ' />
                                                </Link>
                                                {/* <div>
                                                    <div className='cursor-pointer' onClick={() => ShowMessage()}>
                                                        <BsFlag className='text-[30px] text-red-500  ' />
                                                    </div>

                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='pt-2'>
                                        <div className='flex xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap justify-between items-center   pb-2'>
                                            <div>
                                                <ul>
                                                    <li>
                                                        <div className='flex items-center'>
                                                            <img src={person} alt="detail" className='object-cover' />
                                                            <h2 className='px-2 text-black font-bold break-all xl:text-[25px] lg:text-[25px] md:text-[25px] text-[16px]'>{properties?.user?.first_name || "N/A"}</h2>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='flex items-center'>
                                                            <img src={locat} alt="detail" className='object-cover' />
                                                            <h2 className='px-2 text-black text-[14px]'>{properties?.complete_address || "N/A"}</h2>
                                                        </div>
                                                    </li>
                                                    {properties?.complete_address &&
                                                        <li>
                                                            <div className='flex items-center'>
                                                                <img src={locat} alt="detail" className='object-cover' />
                                                                <a href={url} target="_blank" className='px-2 text-black text-[14px]' rel="noopener noreferrer">
                                                                    {t('view_map')}
                                                                </a>
                                                            </div>
                                                        </li>
                                                    }

                                                </ul>
                                            </div>

                                            <div className='pt-8'>
                                                {token &&
                                                    <div onClick={() => { return setEditType('rent_amount'), setEdit(true) }} className='flex items-center justify-end pl-1 text-blue-500 cursor-pointer '>
                                                        <h2>{t('edit')}</h2>
                                                        <RiEditBoxLine />
                                                    </div>
                                                }
                                                <div className='flex items-center'>
                                                    <h2 className=' text-[18px]   flex items-center'>${properties?.rent?.toLocaleString()} <span className='text-[15px]'>/ {t('Month')}</span></h2>
                                                </div>
                                                <p className=''>{t('Sec_dp')} : ${properties?.security_deposit?.toLocaleString()}</p>
                                                {properties?.lister_type === "1" &&
                                                    <p className=''>{t('broker_fee')} : {properties?.broker_fee_type !== "percent" ? "$" : ""}{properties?.broker_fee}{properties?.broker_fee_type === "percent" ? "%" : ""}</p>
                                                }
                                            </div>
                                        </div>
                                    </div>



                                    <div className='pt-5'>
                                        <div className="flex items-center justify-center">
                                            <div className='flex items-center bg-gray-200 rounded-md p-3'>
                                                <img src={bed} alt="bed" className='object-cover' />
                                                <h2 className='text-[16px] px-2 font-medium'>{properties?.number_of_beds} {t('bedRom')}</h2>
                                            </div>
                                            <div className='flex items-center bg-gray-200 rounded-md p-3 mx-5'>
                                                <img src={bath} alt="bath" className='object-cover' />
                                                <h2 className='text-[16px] px-2 font-medium'>{properties?.number_of_baths} {t('bathRom')}</h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="pt-8">
                                        <div className=''>
                                            <h2 className='font-semibold text-[20px]'>{t('lister_type')}</h2>
                                            <p>{properties?.lister_type === "0" ? t('propty_owner') : t('Realtor')}</p>
                                        </div>
                                    </div>

                                    <div className="pt-8">
                                        <div className='flex items-center'>
                                            <h2 className='font-semibold text-[20px]'>{t('list_by')}</h2>
                                        </div>
                                        <div className='flex items-center'>
                                            <div className='flex items-center gap-2 pr-1'>
                                                < MdMail className="text-[#FF8C00]" size={20} />
                                                <h2 className='font-medium text-[16px]'>{t('email')}</h2>
                                            </div> :
                                            <p className='py-1 pl-2'> {properties?.user?.email || "N/A"}</p>
                                        </div>
                                        <div className='flex items-center'>
                                            <div className='flex items-center gap-2 pr-1'>
                                                <MdPhone className="text-[#FF8C00]" size={20} />
                                                <h2 className='font-medium text-[16px]'>{t('contact_phone')}</h2>
                                            </div> :
                                            <p className='py-1 pl-2'>{properties?.user?.phone_number || "N/A"}</p>

                                        </div>
                                        <div className='flex items-center'>
                                            <div className='flex items-center gap-2 pr-1'>
                                                <MdVerifiedUser className="text-[#FF8C00]" size={20} />
                                                <h2 className='font-medium text-[16px]'>{properties?.lister_type === "0" ? t('comp_name_lab') : t('realtor_lab')}</h2>
                                            </div> :
                                            <p className='py-1 pl-2'>{properties?.lister_type === "0" ? properties?.company_name : properties?.realtor_license || "N/A"}</p>

                                        </div>
                                        <div className='flex items-center'>
                                            <div className='flex items-center gap-2 pr-1'>
                                                <MdShoppingBag className="text-[#FF8C00]" size={20} />
                                                <h2 className='font-medium text-[16px]'>{properties?.lister_type === "0" ? t('professional_lab') : t('sponser_broker')}</h2>
                                            </div> :
                                            <p className='py-1 pl-2'>{properties?.lister_type === "0" ? properties?.professional_license : properties?.sponsoring_broker || "N/A"
                                            }</p>

                                        </div>
                                        {
                                            properties?.listed_with_realtor &&
                                            <>
                                                <div className='flex items-center'>
                                                    <div className='flex items-center gap-2 pr-1'>
                                                        <MdShoppingBag className="text-[#FF8C00]" size={20} />
                                                        <h2 className='font-medium text-[16px]'>{t('sponser_broker')}</h2>
                                                    </div> :
                                                    <p className='py-1 pl-2'>{properties?.sponsoring_broker || "N/A"
                                                    }</p>

                                                </div>
                                                <div className='flex items-center'>
                                                    <div className='flex items-center gap-2 pr-1'>
                                                        <MdShoppingBag className="text-[#FF8C00]" size={20} />
                                                        <h2 className='font-medium text-[16px]'>{t('realtor_lab')}</h2>
                                                    </div> :
                                                    <p className='py-1 pl-2'>{properties?.realtor_license || "N/A"
                                                    }</p>

                                                </div>
                                            </>
                                        }
                                    </div>

                                    <div className="pt-8">
                                        <div className='flex items-center'>
                                            <h2 className='font-semibold text-[20px]'>{t('language_in')}</h2>
                                            {token &&
                                                <div onClick={() => { return setEditType('language'), setEdit(true) }} className='flex items-center pl-1 text-blue-500 cursor-pointer '>
                                                    <h2>{t('edit')}</h2>
                                                    <RiEditBoxLine />
                                                </div>
                                            }
                                        </div>
                                        <p className='pt-2 '> {properties?.lang !== "" ? properties?.lang === "en" ? 'English' : 'Spanish' : 'N/A'}</p>


                                    </div>

                                    <div className="pt-8">
                                        <div className='flex items-center'>
                                            <h2 className='font-semibold text-[20px]'>{t('desc')}</h2>
                                            {token &&
                                                <div onClick={() => { return setEditType('desc'), setEdit(true) }} className='flex items-center pl-1 text-blue-500 cursor-pointer '>
                                                    <h2>{t('edit')}</h2>
                                                    <RiEditBoxLine />
                                                </div>
                                            }
                                        </div>
                                        <p className='pt-2 '> {properties?.description}</p>
                                    </div>

                                    <div className="pt-8">
                                        <div className='flex items-center'>
                                            <h2 className='font-semibold text-[20px]'>{t('amanity')}</h2>
                                            {token &&
                                                <div onClick={() => { return setEditType('aminity'), setEdit(true) }} className='flex items-center pl-1 text-blue-500 cursor-pointer '>
                                                    <h2>{t('edit')}</h2>
                                                    <RiEditBoxLine />
                                                </div>
                                            }
                                        </div>
                                        <div className='grid grid-cols-3 gap-5 pt-2 bg-white rounded-md shadow-md p-3 mt-2'>
                                            {
                                                properties?.amenities?.map((c, i) => (
                                                    <div className='flex flex-col' key={i}>
                                                        <h2 className='text-gray-400'>{c?.name}</h2>

                                                    </div>
                                                ))
                                            }


                                        </div>

                                        <div className='flex items-center pt-8'>
                                            <h2 className='font-semibold text-[20px]'>{t('leased_p')}</h2>
                                            {token &&
                                                <div onClick={() => { return setEditType(''), setEdit(true) }} className='flex items-center pl-1 text-blue-500 cursor-pointer '>
                                                    <h2>{t('edit')}</h2>
                                                    <RiEditBoxLine />
                                                </div>
                                            }
                                        </div>
                                        <div className=''>

                                            <h2 className='text-[18px] text-gray-400'>{leasedFind?.title}</h2>
                                        </div>

                                        {properties?.lease_built && Object.keys(properties?.lease_built).length > 0 ?
                                            <div className="pt-8">
                                                <div className='flex items-start flex-col'>
                                                    <div className='flex items-center   cursor-pointer ' onClick={() => ViewPdf(properties)}>
                                                        <div className='flex items-center text-white bg-[#FF8C00] rounded-md p-3'>
                                                            {t('view_lease')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""}

                                        {/* <div className="pt-8">
                                            <div className='flex items-start flex-col'>
                                                <div className='flex items-center   cursor-pointer ' onClick={() => handlBlock(properties)}>
                                                    <div className='flex items-center text-white bg-[#FF8C00] rounded-md p-3'>
                                                        {t('block_button')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}


                                    </div>
                                </div >


                            </div>
                        </div>
                }

            </>
        </>
    )
}

export default RenterDetails