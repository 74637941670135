import React, { useEffect, useState } from 'react'
import flash from '../../assests/images/flash.png'
import Purchase from '../../components/ServicerPanelComp/Flash/Purchase'
import { Loader } from '../../utils/loaderButton'
import { GetPricesServicer } from '../../lib/Api/RantelServiceApi'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import ServicerFlashpass from './FlashPass'
import PropertiesList from './PropertiesList'
import { t } from 'i18next'
import { GetSubscription } from '../../lib/Api/RentalAppApi'
import { setIsInitials, setPurchaseinfo } from '../../Redux/UserAuthSlice/RommateSlice'
const FlashServicer = () => {
    const [packages, setPackage] = useState("")
    const [property, setProperty] = useState("")
    const [loadingStatus, setLoadingStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [priecs, setPrices] = useState([])
    const [purchaseInfo, setPurchaseInfo] = useState({})
    const [activeTab, setActiveTab] = useState(0)
    const [purchasedHistory, setPurchaseHistory] = useState()
    const [ReazyHistory, setReazyHistory] = useState("")
    const [revious, setRevious] = useState("")


    const location = useLocation().pathname
    const dispatch = useDispatch()
    const { isRommatePurchased, isInitial, purchaseInf } = useSelector((state) => state?.rommate)


    let fetchServicerFlashLane = async () => {
        setLoading(true)
        let { res } = await GetPricesServicer()
        if (res) {
            setLoading(false)
            setPrices(res)
        } else {
            setLoading(false)
        }
    }

    let fetchRenterSubsc = async () => {
        setLoadingStatus(true)
        let { res } = await GetSubscription()
        if (res) {
            setLoadingStatus(false)
            setPurchaseInfo(res)
            setPurchaseHistory(res)
        }
    }

    const handleToggle = (toggle) => {
        if(toggle === 1){
            dispatch(setIsInitials(false))
        }
        setActiveTab(toggle)
        setProperty("")
        setPackage(false)
    }

    useEffect(() => {
        fetchRenterSubsc()
        fetchServicerFlashLane()
    }, [])

    useEffect(() => {
            if (revious === "") {
                if (isInitial) {
                    setActiveTab(0);
                } else {
                    if (revious === "") {
                        if (purchaseInfo?.purchased === true && purchaseInfo?.roommate?.purchased === true) {
                            if (isRommatePurchased === true) {
                                setActiveTab(1)
                                setPurchaseInfo(purchasedHistory)
                            } else {
                                setActiveTab(0)
                                setPurchaseInfo(purchasedHistory)
                            }

                        }
                        else if (purchaseInfo?.roommate?.purchased === true && purchaseInfo?.purchased === false) {
                            setActiveTab(1)
                        }
                        else if (activeTab === 0 &&  purchaseInfo?.roommate?.purchased === false && purchaseInfo?.purchased === false) {
                            setActiveTab(0)
                            setPurchaseInfo(purchasedHistory)
                        }
                    }
                }
            }

    }, [purchaseInfo]);



    return (
        <>
            {
                loadingStatus ? <div className="pt-5"><Loader type="loadData" /> </div>
                    :
                    activeTab === 0 && purchaseInfo?.purchased === true || activeTab === 1 && purchaseInfo?.roommate?.purchased === true ?
                        <>
                            <div className=''>
                                <ServicerFlashpass
                                    setPurchaseInfo={(val) => setPurchaseInfo(val)}
                                    info={purchaseInfo}
                                    activeTabs={activeTab}
                                    setActiveTabs={setActiveTab}
                                    handleToggle={handleToggle}
                                    setReazyHistory={setReazyHistory}
                                    setRevious={setRevious}
                                />
                            </div>
                        </>
                        :
                        <>
                            {
                                <div className='pt-5 max-w-[300px]'>
                                    <div className={` flex items-center border-b border-[#F79F15] `}>
                                        <div onClick={() => handleToggle(0)} className={`${activeTab === 0 ? `${location === "/servicer_reazy" || location === "/reazy" ? "text-[#B913F0] border-[#B913F0] font-bold border-b-2" : "text-[#F79F15] border-[#F79F15] font-bold border-b-2"}   text-white` : 'text-black font-bold'} cursor-pointer w-full  px-6 h-full py-3 `}>
                                            <p>{t('servicer_rentals')}</p>
                                        </div>

                                        <div onClick={() => handleToggle(1)} className={`${activeTab === 1 ? `${location === "/servicer_reazy" || location === "/reazy" ? "text-[#B913F0] border-[#B913F0] font-bold border-b-2" : "text-[#F79F15] border-[#F79F15] font-bold border-b-2"}  text-white ` : 'text-black font-bold'}  cursor-pointer w-full px-6 h-full py-3`}>
                                            <p>{t('rommates')}</p>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                packages ? <Purchase
                                    data={packages}
                                    activeTab={activeTab}
                                    property={property}
                                    setPackage={() => setPackage(false)} />
                                    :
                                    <>
                                        {property === "" && location === "/servicer_flash" ?
                                            <PropertiesList activeTab={activeTab} purchasedHistory={purchasedHistory} setPurchaseInfo={setPurchaseInfo} setProperty={setProperty} />
                                            :
                                            <div className=' lg:max-w-[550px] m-auto bg-cover   h-full w-full  '>
                                                <div className='lg:p-[3rem] p-[20px]'>
                                                    <h2 className='text-[30px] font-semibold text-center'>{t('welcom_flash')}</h2>
                                                    <h2 className='text-[15px] text-center cursor-pointer'>{t('skip_sr')}</h2>

                                                    <h2 className='text-[23px] font-semibold'>{t('how_work')}</h2>
                                                    <div className='pt-4'>
                                                        <h2 className='text-[15px] '>{t('flash_desc')}</h2>
                                                    </div>
                                                    <ul className='my-3'>
                                                        {
                                                            location !== "/flash" || location === "/servicer_flash" &&
                                                            <li className='flex items-center '>
                                                                <img src={flash} alt="flash" />
                                                                <h2 className='mx-2 font-medium lg:text-[16px] text-[14px]'>{t('reazy_inf')}</h2>
                                                            </li>
                                                        }
                                                        <li className='flex items-center my-2'>
                                                            <img src={flash} alt="flash" />
                                                            <h2 className='mx-2 font-medium lg:text-[16px] text-[14px]'>{t('flash_book')}</h2>
                                                        </li>
                                                        <li className='flex items-center my-2 '>
                                                            <img src={flash} alt="flash" />
                                                            <h2 className='mx-2 font-medium lg:text-[16px] text-[14px]'>{t('submit_rent')}</h2>
                                                        </li>
                                                        <li className='flex items-center '>
                                                            <img src={flash} alt="flash" />
                                                            <h2 className='mx-2 font-medium lg:text-[16px] text-[14px]'>{t('have_all')}</h2>
                                                        </li>
                                                    </ul>
                                                    <h2 className='text-[23px] font-semibold'>{t('pricing')}</h2>
                                                    <ul className='my-2'>
                                                        {

                                                            !loading && priecs?.map((list, i) => {
                                                                let addDot = list?.unit_amount_decimal / 100
                                                                return (
                                                                    <li key={i} onClick={() => setPackage(list)} className='border cursor-pointer p-1 px-2  flex justify-between items-center bg-gradient-to-r from-[#FFC000] to-orange-500 h-full rounded-full '>
                                                                        <div className='flex mx-3 items-center'>
                                                                            <img src={list?.metadata?.image} className='object-cover w-6 h-6' alt="icon_img" />
                                                                            <div className='flex flex-col items-start '>
                                                                                <h2 className='text-white mx-1 text-[18px]'>{
                                                                                    list?.metadata?.name
                                                                                }</h2>
                                                                                <p className='text-[12px] text-white px-2'>{list?.metadata?.desc}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <p className='text-white px-2'>${addDot}</p>
                                                                        </div>
                                                                    </li>


                                                                )
                                                            })

                                                        }
                                                        {loading ? <Loader type="loadData" /> : ""}
                                                    </ul>
                                                </div>

                                            </div>
                                        }
                                    </>
                            }

                        </>
            }
        </>
    )
}

export default FlashServicer


