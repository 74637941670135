import React, { useState } from 'react'
import lottie from "lottie-web";
import modelPic from "../../assests/Lottie/medal.json";
import modelPPic from "../../assests/Lottie/purplemedal.json";
import { useLocation, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ServicerPaymentIntent } from '../../lib/Api/RantelServiceApi';
import { RegisterPaymentIntent } from '../../lib/Api/RentalAppApi';
import { setPurcahseInfo } from '../../Redux/UserAuthSlice/UserAuthSlice';
import { setUpdatePurchaseRoommate } from '../../Redux/UserAuthSlice/RommateSlice';
const ThankYouReasy = () => {
    const [flashPage, setFlashpage] = useState(false)

    const { userRole } = useSelector((state) => state?.userAuth?.userInfo)
    const Purchase = useSelector((state) => state.userAuth?.purchaseInfo)
        const lang = useSelector((state) => state.userAuth?.languageSelect)
    
    const dispatch = useDispatch()

    let handleUpdate = async () => {
        let info = {
            "price": Purchase?.price,
            "blocked_times": Purchase?.blocked_times
        }
        if (Purchase?.is_roommate === true) {
            info.is_roommate = true
        } else {
            info.property_id = Purchase?.property_id
        }
        if (Purchase?.is_roommate === true) {
            dispatch(setUpdatePurchaseRoommate(true))
        } else {
            dispatch(setUpdatePurchaseRoommate(false))

        }
        // let response = await (Purchase?.type === "flash_reazy"
        //     ? RegisterPaymentIntent({"price" : Purchase?.price})
        //     : ServicerPaymentIntent(info))
        let response = await (Purchase?.type === "flash_reazy"
            ? RegisterPaymentIntent(info)
            : ServicerPaymentIntent(info , lang))
        if (response) {

            dispatch(setPurcahseInfo({}))
        }
    }

    // console.log("Purcahse", Purchase)

    const location = useLocation().pathname
    React.useEffect(() => {
        lottie.loadAnimation({
            container: document.querySelector("#react-logo"),
            animationData: modelPPic
        });
        if (Purchase && Object.keys(Purchase)?.length) {
            handleUpdate()
        }
        setTimeout(() => {
            setFlashpage(true)
        }, 4000)
    }, []);

    const { t } = useTranslation()

    return (
        <>
            {flashPage && location === "/thankYouR-easy" && userRole === "servicer" ? <Navigate to="/filterPrefrence" /> : flashPage && location === "/thankYouR-easy" && userRole === "renter" && <Navigate to="/reazy" />}
            <div className='p-20'>
                <h1 className='text-center font-bold text-[20px]'>{t('thank_you')} </h1>
                <div className='shadow-md border border-gray-100 flex flex-col justify-center items-center rounded-md bg-white h-[250px] max-w-[350px] m-auto mt-6 p-5 my-5'>
                    <div id="react-logo" className='w-[120px]' />
                    <p className={`text-[14px] text-[#B913F0] `}>{t('r_ezy_plane')} </p>
                    <p className={`text-[14px] text-center text-[#B913F0] `}>{t('r_ezy_enjoy')}</p>
                </div>

                <div className=' flex flex-col justify-center items-center '>
                    <p className={`text-[16px] max-w-[250px] text-center text-[#B913F0] `}>{t('skip_for')}</p>
                    <div className={` px-5 text-white rounded-md py-2  bg-gradient-to-r from-[#FFC000] to-orange-500  max-[120px] m-auto my-4`}>
                        {t('flash_lane')}
                    </div>

                </div>
            </div>
        </>
    )
}

export default ThankYouReasy
