import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Loader } from '../../../utils/loaderButton'
import { CheckScreensing, CreateScreensing, LoardScreeningReport } from '../../../lib/Api/RentalAppApi'
import DynamicPopup from '../../../utils/Popup/DynamicPopup'
import PdfPreview from './PdfPreview'
import { useDispatch, useSelector } from 'react-redux'
import { setReportInitial } from '../../../Redux/UserAuthSlice/RommateSlice'
import { CheckCoSignerApp, DeleteCoSignerApp, CreateAppInfo } from '../../../lib/Api/RentalAppApi';
import { Link } from 'react-router-dom'
import { BsTrash, BsPencilSquare, BsEye } from 'react-icons/bs'
// import { toast } from 'react-toastify'

const Screening = ({ status, handleBack, handleNext }) => {
    const [loading, setLoading] = useState(false)
    const [loadingReport, setLoadingReport] = useState(false)
    const [loadingCreate, setLoadingCreate] = useState(false)
    const [show, setShow] = useState(false)
    const [type, setType] = useState("")
    const [screenData, setScreenData] = useState([])
    const [ssn, setSsn] = useState("")
    const [error, setError] = useState("")
    const [linkIfram, setLinkIfram] = useState("")
    const [coSigners, setCoSigners] = useState([]);
    const [showPopup, setShowPopup] = useState(null);
    const popupRef = useRef(null);

    const { t } = useTranslation()

    const dispatch = useDispatch()
    // const report = useSelector((state) => state?.rommate?.viewReport)
    const lng = useSelector((state) => state?.userAuth?.language)


    let fetch = async () => {
        try {
            let res = await CheckScreensing()
            if (res) {
                setLoading(false)
                setScreenData(res)
                return res
            }
            else {
                setLoading(false)
            }
        }
        catch (err) {
            setLoading(false)
         }
    }

    useEffect(() => {
        const fetchCoSigners = async () => {
            const result = await CheckCoSignerApp();
            setCoSigners(result.response);
        };

        fetchCoSigners();
    }, []);

    const coSignersWithPlaceholders = [...coSigners, ...Array(Math.max(0, 3 - coSigners.length)).fill({ id: 'new' })];

    const handleDelete = (id) => {
        DeleteCoSignerApp(id);
        const updatedCoSigners = coSigners.filter(coSigner => coSigner.id !== id);
        setCoSigners(updatedCoSigners);
    };

    const CheckScreening = async () => {
        try {
            setLoading(true)
            let res = await fetch()
            if (res) {
                setLoading(false)
                setScreenData(res)
                dispatch(setReportInitial())

            }
            else {
                setLoading(false)
            }
        }
        catch (err) { }
    }


    const handleChange = (e) => {
        const inputValue = e.target.value;

        if (inputValue) {
            // Reset the error message if there is input
            setError("");
            // Remove non-numeric characters
            const rawValue = inputValue.replace(/\D/g, '');
            // Initialize the formatted value
            let formattedValue = '';

            // Apply formatting based on the length of rawValue
            if (rawValue.length <= 3) {
                formattedValue = rawValue;
            } else if (rawValue.length <= 5) {
                formattedValue = `${rawValue.slice(0, 3)}-${rawValue.slice(3)}`;
            } else {
                formattedValue = `${rawValue.slice(0, 3)}-${rawValue.slice(3, 5)}-${rawValue.slice(5, 9)}`;
            }
            // Update the SSN state with the formatted value
            setSsn(formattedValue);
        } else {
            // Set an error message if the input is empty
            setError(t('social_sc'));
            setSsn(""); // Clear the SSN state when input is empty
        }
    };




    const handleCheck = async () => {
        if (ssn === "") {
            setError(t('social_sc'))
        }
        else {
            try {
                setLoadingCreate(true)
                let payload = {
                    "ssn_number": ssn
                }
                let { res , error } = await CreateScreensing(payload)
                if (res) {
                    setLoadingCreate(false)
                    setScreenData(res)
                }
                else if(error){
                    setLoadingCreate(false)
                    setSsn("")
                }

            }
            catch (err) { }
        }
    }

    const capitalizeFirstLetter = (string) => {
        return string.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    };

    const loadReport = async (name, coSignerId = '') => {
        try {
            setLoadingReport(true)
            setType(name)
            let res = await LoardScreeningReport(name, coSignerId)
            if (res) {
                let obj = Object.assign({}, ...res)
                setLinkIfram(obj.criminal_report || obj?.evictions || obj?.liens_and_judgements || obj?.credit_report?.report_pdf || obj?.credit_report || obj?.rental_application)
                setLoadingReport(false)
                setShow(true)
            }
            else {
                setLoadingReport(false)
            }
        }
        catch (err) { }
    }

    useEffect(() => {
        if (status?.screening === true)
            CheckScreening()
    }, [status])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
            setShowPopup(null);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handlePopupToggle = (coSignerId) => {
        setShowPopup(showPopup === coSignerId ? null : coSignerId);
    };

    return (
        <>
            {show && <DynamicPopup modalOpen={show} data={<PdfPreview pdf={linkIfram} t={t} />} close={() => setShow(false)} />}

            {loading ?
                <Loader type="loadData" />
                :
                <div className=''>
                    <h2 className='text-[20px] font-bold text-center p-8'>{t('screening')}</h2>

                    {loadingCreate ?
                        <div className='flex justify-center items-center h-full'>
                            <p className='p-5 text-center text-md'>{t('screen_process_desc')}</p>
                        </div>
                        :
                        status?.screening || screenData?.length > 0 ?
                            <div className='max-w-xl mx-auto'>
                                <ul className='border p-3 rounded-md'>
                                    {screenData?.map((list, i) => {
                                        const seenReports = new Set();
                                        return (
                                            Object.entries(list).map(([key, value]) => {
                                                if (key !== "liens_and_judgements" && (key !== "credit_report" || !seenReports.has(key))) {
                                                    seenReports.add(key);
                                                    return (
                                                        <li className='flex justify-between items-center py-2' key={`${i}-${key}`}>
                                                            <div className=''>
                                                                <p>{capitalizeFirstLetter(t(`${key}`))}</p>
                                                            </div>
                                                            <div onClick={() => loadReport(key)}>
                                                                {type === key && loadingReport ? <Loader type="single" /> :
                                                                    <p className={`text-[16px] font-medium cursor-pointer text-[#FFA525]`}>
                                                                        {"View"}
                                                                    </p>
                                                                }
                                                            </div>
                                                        </li>
                                                    );
                                                }
                                                return null;
                                            })
                                        );
                                    })}
                                </ul>
                                <div className='flex justify-between items-center'>
                                    <div className='flex justify-end py-3'>
                                        <button onClick={() => handleBack(10)} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Back')}</button>
                                    </div>
                                    <div className='flex justify-end py-3'>
                                        <button onClick={() => handleNext(10)} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
                                            {t('save')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                <div className='flex flex-col mb-4 xl:mx-20 lg:mx-20 md:mx-10 '>
                                    <label className='mb-2 text-[16px] font-semibold'>{t('ssn')} </label>
                                    <input
                                        type="text"
                                        className=' p-2 border rounded-md bg-[#F5F6FC] focus:outline-none'
                                        placeholder="xxx-xx-xxxx"
                                        maxLength="11"
                                        value={ssn}
                                        onChange={handleChange}
                                    />
                                    {error && (
                                        <p className="text-red-500 text-sm ">{error}</p>
                                    )}
                                </div>
                                <p className='p-5 text-center text-md'>{t('screen_desc_1')} <span > <a href={lng === "en" ? "/privacy" : "/sp/privacy"} target='_blank' className='underline text-[#F79F15] decoration-[#F79F15]'>{t('screen_desc_2')}</a></span> {t('and')} <span> <a href={lng === "en" ? "/terms" : "/sp/terms"} target='_blank' className='underline text-[#F79F15] decoration-[#F79F15]'>{t('screen_desc_3')}</a></span> {t('screen_desc')}</p>

                                <div className='flex justify-center items-center'>
                                    <button onClick={() => handleCheck()} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('continue')}</button>
                                </div>
                            </>
                    }

                </div>
            }
            <div className='flex justify-center items-center'>
                {coSignersWithPlaceholders.slice(0, 3).map((coSigner, index) => (
                    <div key={index} className='m-5 flex items-center relative'>
                    {coSigner.id === 'new' ? (
                        <Link
                        to={`/coSignerApp/${coSigner.id}`}
                        className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md block'
                        >
                        Add Co-Signer
                        </Link>
                    ) : (
                        <>
                        <button
                            type='button'
                            onClick={() => handlePopupToggle(coSigner.id)}
                            className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'
                        >
                            Manage Co-Signer {index + 1}
                        </button>

                        {showPopup === coSigner.id && (
                            <div ref={popupRef} className='absolute top-full mt-2 bg-white shadow-lg rounded-md p-2 z-10'>
                            <button
                                onClick={() => loadReport('rental_application', coSigner.id)}
                                className='block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100 rounded'
                            >
                                <BsEye className='inline mr-2' /> View
                            </button>
                            <Link
                                to={`/coSignerApp/${coSigner.id}`}
                                className='block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100 rounded'
                            >
                                <BsPencilSquare className='inline mr-2' /> Edit
                            </Link>
                            <button
                                type='button'
                                onClick={() => handleDelete(coSigner.id)}
                                className='block w-full text-left px-4 py-2 text-red-600 hover:bg-gray-100 rounded'
                            >
                                <BsTrash className='inline mr-2' /> Delete
                            </button>
                            </div>
                        )}
                        </>
                    )}
                    </div>
                ))}
            </div>
        </>
    )
}

export default Screening