import React, { useEffect, useRef, useState } from 'react'
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { CreateIncomeInfo, UpdateIncomeInfo } from '../../../lib/Api/RentalAppApi';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../../utils/loaderButton';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';
import { MdEdit } from 'react-icons/md';
import { ConvertBase64 } from '../../../utils/ConvBase64';
import { addRentals } from '../../../Redux/UserAuthSlice/RentalSlice';
// import { IoMdTrash } from 'react-icons/io';


const Vehcial = {
  id: "",
  monthly_income: "",
  sources: "",
  proof: "",
  proof2: ""

}

const IncomInformation = ({ handleNext, handleBack }) => {
  // const [formData, setFormData] = useState([])
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState("")
  const [selectedIndex, setSelectedIndex] = useState()
  const [uploadloading, setUplaodLoading] = useState(true)

  const inputFile = useRef(null)
  const inputFile2 = useRef(null)

  const onButtonClick = (index) => {
    inputFile.current.click();
    setSelectedIndex(index)

  }

  const onButtonClick2 = (index) => {
    inputFile2.current.click();
    setSelectedIndex(index)


  }


  const { rentalData , app_id } = useSelector((state) => state?.rental)
  let rentalApp = rentalData?.income_info
  
  const dispatch = useDispatch()
  const { t } = useTranslation()


  const schema = yup.object().shape({
    values: yup.array().of(
      yup.object().shape({
        monthly_income: yup.string().required(t('mont_invalid')),
        sources: yup.string().required(t('souc_invalid')),
      })
    )
  })



  const { register, handleSubmit, control, setValue, reset, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema), defaultValues: { values: [Vehcial] } });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'values'
  });
  // const { app_id } = useSelector((state) => state?.rental)


  const formatCurrency = (value) => {
    // Remove all non-digit characters
    if (!rentalApp) {
      const numericValue = value?.replace(/[^0-9]/g, '');
      // Format the value with commas
      const formattedValue = new Intl.NumberFormat('en-US').format(numericValue);
      // Add the dollar sign
      return `$${formattedValue}`;
    }
    else {
      const formattedValue = new Intl.NumberFormat('en-US').format(value);
      // Add the dollar sign
      return `$${formattedValue}`;
    }
  };


  const handleImageChang = async (event, fieldName,) => {
    const file = event.target.files[0];
    setType(fieldName)
    const base64 = await ConvertBase64(file)
    setUplaodLoading(true)
    let payload = {
      [fieldName]: base64
    };
    const { res } = await UpdateIncomeInfo(payload, rentalApp[selectedIndex]?.id)
    if (res) {
      setUplaodLoading(false)
      dispatch(addRentals(res[0]?.rental_application))
    }
  }

  // const handleTrash = (filename, i) => {
  //   let updatedRentalApp = rentalApp.map((f , index ) => {
  //     if (index === i) {
  //       if (filename === "proof") {
  //         return { ...f, proof_of_income: "" };
  //       } else if (filename === "proof2") {
  //         return { ...f, proof_of_income2: "" };
  //       }
  //     }
  //     return f;
  //   });
  // }


  const handleImageChange = (index, event, filename) => {
    const files = event.target.files[0];
    if (filename === "proof") {
      const reader1 = new FileReader();
      reader1.onloadend = async () => {
        setValue(`values[${index}].${"proof"}`, reader1.result);
      };
      reader1.readAsDataURL(files);
    }
    else {
      const reader2 = new FileReader();
      reader2.onloadend = () => {
        setValue(`values[${index}].${"proof2"}`, reader2.result);
      }
      reader2.readAsDataURL(files);
    }
    // } else {
    //   alert(t('upload_img'));
    // }
  };



  const OnSubmit = async (data) => {

    const compareArrays = (arr1, arr2) => {
      if (arr1.length !== arr2?.length) {
        return false;
      }

      return arr1.every((item, index) => JSON.stringify(item) === JSON.stringify(arr2[index]));
    };

    const result = compareArrays(rentalApp, data.values);
    if (result === false) {
      try {
        const updatedData = data.values.map(obj => {
          if (obj.proof.startsWith('https://') || obj.proof2.startsWith('https://')) {
            const new_obj = {
              ...obj,
              proof_of_income: obj.proof,
              proof_of_income2: obj.proof2,
            };
            delete new_obj.proof;
            delete new_obj.proof2;
            return new_obj;
          }
          else {
            delete obj.id;
          }
          return obj;
        });
        let payload = {
          "app_id": rentalData?.id || app_id,
          "income_info": updatedData,
        }

        setLoading(true)
        let { res } = await CreateIncomeInfo(payload)
        if (res) {
          dispatch(addRentals(res[0]?.rental_application))
          setLoading(false)
          handleNext(5)
        }
        else {
          setLoading(false)
        }
      }
      catch (err) {
        setLoading(false)
      }
    }
    else {
      handleNext(5)

    }
  }


  useEffect(() => {
    if (rentalApp?.length > 0) {
      let payload = {
        values: rentalApp.map(app => ({
          id: app?.id,
          monthly_income: app?.monthly_income,
          sources: app?.sources,
          proof: app?.proof_of_income,
          proof2: app?.proof_of_income2
        }))
      }
      reset(payload)
    }
    else {
      reset({ values: [Vehcial] })
    }
  }, [reset, rentalApp, rentalData])



  return (
    <>
      <form onSubmit={handleSubmit(OnSubmit)}>
        <h2 className='text-[20px] font-bold text-center p-8'>{t('i_info')}</h2>

        {
          fields.map((field, index) => {
            return (
              <div className='border rounded-md px-4 py-2 my-2 ' key={index}>

                <div className='flex justify-end'>
                  {
                    index !== 0 &&
                    <button type='button' onClick={() => remove(index)} className=' p-2 bg-red-500 rounded-md '><BsTrash className='text-white' /></button>
                  }
                </div>
                <div className='grid xl:grid-cols-2 lg:grid-cols-2 xl:gap-20 lg:gap-20'>
                  <div className='flex flex-col mb-4'>
                    <label className='mb-2 text-[16px] font-semibold'> {t('mont_lbl')}<span className='text-red-500'>*</span></label>
                    <Controller
                      name={`values[${index}].monthly_income`}
                      control={control}
                      defaultValue={field.monthly_income}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          className=' p-2 rounded-md bg-white border focus:outline-none'
                          value={formatCurrency(field.value)}
                          placeholder='$50'
                          onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}
                        />
                      )}
                    />
                    {errors.values && errors.values[index] && errors.values[index].monthly_income && (
                      <span className='text-red-500 text-sm '>{errors.values[index].monthly_income.message}</span>
                    )}

                  </div>
                  <div className='flex flex-col mb-4'>
                    <label className='mb-2 text-[16px] font-semibold'>{t('souc_lbl')} <span className='text-red-500'>*</span></label>
                    <input
                      type="text"
                      name={`values[${index}].sources`}
                      {...register(`values.${index}.sources`)}
                      placeholder={t('souc_lbl')}
                      defaultValue={field.sources}
                      className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
                    {errors.values && errors.values[index] && errors.values[index].sources && (
                      <span className='text-red-500 text-sm '>{errors.values[index].sources.message}</span>
                    )}
                  </div>
                </div>
                <div className='grid xl:grid-cols-2 lg:grid-cols-2 xl:gap-20 lg:gap-20'>
                  {field?.proof && field?.proof2 !== "" ?
                    <div className='flex items-center gap-5'>
                      <div className='relative'>
                        <img src={field?.proof} alt="profee" className='
                      w-[200px] h-[200px] rounded-md object-contain border ' />
                        <div className='absolute top-20 left-20'>
                          {selectedIndex === index && uploadloading && type === "proof" && <Loader type="socialLoder" />}
                        </div>
                        <div className='absolute top-0 -right-2'>
                          <div onClick={() => onButtonClick(index)} className='border cursor-pointer rounded-full p-2 bg-white'>
                            <MdEdit />
                          </div>
                          
                          <input
                            type="file"
                            ref={inputFile}
                            id="proof"
                            onChange={(e) => handleImageChang(e, 'proof')}
                            className='p-2 rounded-md bg-[#F5F6FC] focus:outline-none hidden'
                          />
                        </div>
                      </div>
                      <div className='relative'>
                        <img src={field?.proof2} alt="profee" className='
                      w-[200px] h-[200px] rounded-md object-contain border ' />
                        <div className='absolute top-20 left-20'>
                          {selectedIndex === index && uploadloading && type === "proof2" && <Loader type="socialLoder" />}
                        </div>
                        <div className='absolute top-0 -right-2'>
                          <div onClick={() => onButtonClick2(index)} className='border cursor-pointer rounded-full p-2 bg-white'>
                            <MdEdit />
                          </div>
                          {/* <div onClick={() => handleTrash("proof2",index)} className='border cursor-pointer rounded-full p-2 bg-white'>
                            <IoMdTrash />
                          </div> */}
                          <input
                            id="proof2"
                            ref={inputFile2}
                            type="file"
                            onChange={(e) => handleImageChang(e, 'proof2')}
                            className='p-2 rounded-md bg-[#F5F6FC] hidden focus:outline-none'
                          />
                        </div>
                      </div>

                    </div>
                    :
                    <>
                      <div className='flex flex-col mb-4'>
                        <label className='mb-2 text-[16px] font-semibold'>{t('Proof')} </label>
                        <input
                          type="file"
                          onChange={(e) => handleImageChange(index, e, "proof")}
                          className='p-2 rounded-md xl:w-full lg:w-full md:w-full w-[90%] bg-[#F5F6FC] focus:outline-none'
                          accept="image/*"
                        />
                      </div>
                      <div className='flex flex-col mb-4'>
                        <label className='mb-2 text-[16px] font-semibold'>{t('Proof2')} </label>
                        <input
                          type="file"
                          onChange={(e) => handleImageChange(index, e, "proof2")}
                          className='p-2 rounded-md xl:w-full lg:w-full md:w-full w-[90%] bg-[#F5F6FC] focus:outline-none'
                          accept="image/*"
                        />
                      </div>
                    </>


                  }
                </div>
              </div>
            )
          })}
        <div className='flex justify-end my-4'>
          <button onClick={() => append(Vehcial)} type='button' className=' px-3 py-2 bg-green-500 rounded-md text-white font-semibold '>{t('add_m_lbl')}</button>
        </div>
        <div className='flex justify-between items-center'>
          <div className='flex justify-end py-3'>
            <button onClick={() => handleBack(5)} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Back')}</button>
          </div>

          <div className='flex justify-end py-3'>
            <button type='submit' className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
              {
                loading ? <Loader type="saveLoder" />
                  :
                  t('Next')
              }
            </button>
          </div>
        </div>
      </form>

    </>
  )
}

export default IncomInformation