import React from 'react'
import { useState ,useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import "react-pdf/dist/esm/Page/TextLayer.css";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import blockChain from '../../../assests/pdf/Massachusetts Residential Lease Agreement.pdf'
// import { Link, useLocation } from 'react-router-dom';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const PdfPreview = ({ pdf, t, handleSign, justOpn }) => {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    // const location = useLocation().pathname
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const handleNextpage = () => {
        let val = pageNumber
        setPageNumber(val + 1)

    }
    const handlePrv = () => {
        let val = pageNumber
        setPageNumber(val - 1)

    }


    useEffect(() => {
        const annotationLayer = document.querySelector('.react-pdf__Page__annotations');
        if (annotationLayer) {
            const links = annotationLayer.querySelectorAll('a');
            links.forEach(link => {
                link.target = "_blank";  // Ensure links open in a new tab
            });
        }
    }, [pageNumber]);
    return (
        <>
            <div className=''>
                {justOpn === "pending" &&
                    <div className='p-5'>
                        <button className='font-semibold mx-3 bg-[#F79F15] px-6 py-2 text-white rounded-md' onClick={() => handleSign()}>Sign Lease</button>
                    </div>
                }
                <div className="pdf-viewer-container">
                    <Document
                        file={pdf}
                        className="pdf-document"
                        onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} width={window.innerWidth * 0.8} renderTextLayer={true} />
                    </Document>
                    <div className="controls">
                        <p>{t('page')} {pageNumber} {t('of')} {numPages}</p>
                        <div className="navigation-buttons">
                            {pageNumber > 1 && (
                                <button className="button" onClick={() => handlePrv()}>{t('prev_lbl')}</button>
                            )}
                            {pageNumber < numPages && (
                                <button className="button" onClick={() => handleNextpage()}>{t('next_lbl')}</button>
                            )}
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default PdfPreview