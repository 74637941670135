import React, { useEffect, useState } from 'react'
// import { useState } from 'react'
import { BsTrash } from 'react-icons/bs';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { CreateVehicleInfo } from '../../../lib/Api/RentalAppApi';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../../utils/loaderButton';
import { useTranslation } from 'react-i18next';
import { compareArrays } from '../../../hooks/ValidateRentalApp';
import { addRentals } from '../../../Redux/UserAuthSlice/RentalSlice';


const Vehcial = {
  year: "",
  make: "",
  model: "",
  color: "",
  plate: "",
  state: ""
}

const VehicalInfo = ({ handleNext, handleBack }) => {
  // const [formData, setFormData] = useState({})
  const [loading, setLoading] = useState(false)
  const [havePets, setHavePets] = useState(true)

  const { rentalData , app_id} = useSelector((state) => state?.rental)

  let rentalApp = rentalData?.vehicle_info

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const validationSchema = yup.object().shape({
    values: yup.array().of(
      yup.object().shape({
        year: yup.string()
          .required(t('year_invalid')),
        make: yup.string()
          .required(t('make_invalid')),
        model: yup.string()
          .required(t('model_invalid')),
        color: yup.string()
          .required(t('color_invalid')),
        plate: yup.string()
          .required(t('plate_invalid')),
        state: yup.string()
          .required(t('stat_invalid')),
      })
    ),
  });

  const validationSchema2 = yup.object().shape({
    values: yup.array().of(
      yup.object().shape({
        year: yup.string()
          .nullable(true),
        make: yup.string()
          .nullable(true),
        model: yup.string()
          .nullable(true),
        color: yup.string()
          .nullable(true),
        plate: yup.string()
          .nullable(true),
        state: yup.string()
          .nullable(true),
      })
    ),
  });;


  const { register, handleSubmit, control, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(havePets ? validationSchema : validationSchema2), defaultValues: { values: rentalApp?.length > 0 ? rentalApp : [Vehcial] } });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'values'
  });

  // console.log("rentalData", rentalData)


  const onSubmit = async (data) => {
    const result = !compareArrays(rentalApp, data.values);
    if (result && havePets === true) {
      try {
        let paylod = {
          "app_id": rentalData?.id || app_id,
          "have_vehicles": havePets,
          "vehicles": data?.values
        }

        setLoading(true)
        let { res } = await CreateVehicleInfo(paylod)
        if (res) {
          dispatch(addRentals(res[0]?.rental_application))
          setLoading(false)
          handleNext(4)
        }
        else {
          setLoading(false)
        }
      }
      catch (err) {
        setLoading(false)
      }
    }
    else {
      if (havePets === false) {
        let newPayload = {
          "app_id": rentalData?.id || app_id,
          "have_vehicles": havePets

        }
        setLoading(true)
        let { res } = await CreateVehicleInfo(newPayload)
        if (res) {
          // console.log("res", res[0]?.rental_application )
          dispatch(addRentals(res[0]?.rental_application))
          setLoading(false)
          handleNext(4)
        }
        else {
          setLoading(false)
        }
      } else
        handleNext(4)

    }

  };



  useEffect(() => {
    if (rentalApp) {
      setHavePets(rentalData?.have_vehicles === 0 ? false : true)
    }
  }, [rentalApp])




  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} >
        <h2 className='text-[20px] font-bold text-center p-8'>{t('v_info')}</h2>
        <div className='flex flex-col mb-4'>
          <label className='mb-2 text-[16px] font-semibold'>{t('do_you_v_info')} </label>
          <div className="flex justify-start">
            <label className='relative inline-flex items-center cursor-pointer'>
              <input
                type="checkbox"
                // name={list?.name}
                checked={havePets ? true : false}
                className="sr-only peer"
                defaultValue={havePets}
                onChange={(e) => setHavePets(e.target.checked)}
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#F79F15] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#F79F15]"></div>

              <label className="form-check-label inline-block text-gray-800 mx-3">
                {havePets === true ? t('yes') : t('no')}
              </label>

            </label>
          </div>
        </div>
        {havePets &&
          <>
            <div className='xl:h-[400px]  lg:h-[400px]  md:h-[400px] h-full xl:overflow-y-auto lg:overflow-y-auto md:overflow-y-auto'>
              {
                fields.map((field, index) => (
                  <div className='border rounded-md px-4 py-2 my-2' key={index}>
                    <div className='flex justify-end'>
                      {
                        index !== 0 &&
                        <button type='button' onClick={() => remove(index)} className=' p-2 bg-red-500 rounded-md '><BsTrash className='text-white' /></button>
                      }
                    </div>
                    <div className='grid xl:grid-cols-2 lg:grid-cols-2 xl:gap-20 lg:gap-20'>
                      <div className='flex flex-col mb-4'>
                        <label className='mb-2 text-[16px] font-semibold'>{t('year_lbl')}<span className='text-red-500'>*</span></label>
                        <Controller
                          name={`values[${index}].year`}
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <input
                              {...field}
                              type="text"
                              className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none'
                              placeholder={t('year_lbl')}
                              maxLength="4"
                              onChange={(e) => {
                                const value = e.target.value.replace(/\D/g, ''); // Remove all non-numeric characters
                                field.onChange(value);
                              }}
                            />
                          )}
                        />
                        {/* <input type="text" placeholder='year'
                      name={`values[${index}].year`}
                      {...register(`values.${index}.year`)}
                      defaultValue={field.year}
                      className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' /> */}
                        {errors.values && errors.values[index] && errors.values[index].year && (
                          <span className='text-red-500 text-sm '>{errors.values[index].year.message}</span>
                        )}
                        {/* {errors.year && (
                      <p className="text-red-500 text-sm ">{`errors[${index}].year`}</p>
                    )} */}
                      </div>
                      <div className='flex flex-col mb-4'>
                        <label className='mb-2 text-[16px] font-semibold'>{t('make_lbl')}<span className='text-red-500'>*</span></label>
                        <input type="text" placeholder={t('make_lbl')}
                          name={`values[${index}].make`}
                          {...register(`values.${index}.make`)}
                          defaultValue={field.make}
                          className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
                        {errors.values && errors.values[index] && errors.values[index].make && (
                          <span className='text-red-500 text-sm '>{errors.values[index].make.message}</span>
                        )}
                        {/* {errors.relation && (
              <p className="text-red-500 text-sm ">{errors.relation.message}</p>
            )} */}
                      </div>
                    </div>
                    <div className='grid xl:grid-cols-2 lg:grid-cols-2 xl:gap-20 lg:gap-20'>
                      <div className='flex flex-col mb-4'>
                        <label className='mb-2 text-[16px] font-semibold'>{t('model_lbl')} <span className='text-red-500'>*</span></label>
                        <input type="text" placeholder={t('model_lbl')}
                          name={`values[${index}].model`}
                          {...register(`values.${index}.model`)}
                          defaultValue={field.year}
                          className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
                        {errors.values && errors.values[index] && errors.values[index].model && (
                          <span className='text-red-500 text-sm '>{errors.values[index].model.message}</span>
                        )}
                      </div>
                      <div className='flex flex-col mb-4'>
                        <label className='mb-2 text-[16px] font-semibold'>{t('color_lbl')}<span className='text-red-500'>*</span></label>
                        <input type="text" placeholder={t('color_lbl')}
                          name={`values[${index}].color`}
                          {...register(`values.${index}.color`)}
                          defaultValue={field.color}
                          className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
                        {errors.values && errors.values[index] && errors.values[index].color && (
                          <span className='text-red-500 text-sm '>{errors.values[index].color.message}</span>
                        )}
                      </div>
                    </div>
                    <div className='grid xl:grid-cols-2 lg:grid-cols-2 xl:gap-20 lg:gap-20'>
                      <div className='flex flex-col mb-4'>
                        <label className='mb-2 text-[16px] font-semibold'>{t('plate_lbl')}<span className='text-red-500'>*</span> </label>
                        <input type="text" placeholder={t('plate_lbl')}
                          name={`values[${index}].plate`}
                          {...register(`values.${index}.plate`)}
                          defaultValue={field.plate}
                          className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
                        {errors.values && errors.values[index] && errors.values[index].plate && (
                          <span className='text-red-500 text-sm '>{errors.values[index].plate.message}</span>
                        )}
                      </div>
                      <div className='flex flex-col mb-4'>
                        <label className='mb-2 text-[16px] font-semibold'>{t('stat_lbl')}<span className='text-red-500'>*</span></label>
                        <input type="text" placeholder={t('stat_lbl')}
                          name={`values[${index}].state`}
                          {...register(`values.${index}.state`)}
                          defaultValue={field.state}
                          className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
                        {errors.values && errors.values[index] && errors.values[index].state && (
                          <span className='text-red-500 text-sm '>{errors.values[index].state.message}</span>
                        )}
                      </div>
                    </div>

                  </div>
                ))
              }
            </div>

            <div className='flex justify-end my-4'>
              <button onClick={() => append(Vehcial)} type='button' className=' px-3 py-2 bg-green-500 rounded-md text-white font-semibold '>{t('add_m_lbl')}</button>
            </div>
          </>
        }

        {/* <div className='flex justify-end py-3'>
          <button type='button' onClick={() =>OnSubmit()} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{`Next > `}</button>
        </div> */}

        <div className='flex justify-between items-center'>
          <div className='flex justify-end py-3'>
            <button onClick={() => handleBack(4)} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Back')}</button>
          </div>

          <div className='flex justify-end py-3'>
            <button type="submit" className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
              {
                loading ? <Loader type="saveLoder" />
                  :
                  t('Next')
              }
            </button>
          </div>
        </div>
      </form>

    </>
  )
}

export default VehicalInfo