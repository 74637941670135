import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from '../../../utils/loaderButton'
import { useTranslation } from 'react-i18next'
import { AddIndex } from '../../../Redux/UserAuthSlice/RentalSlice'

const ThankYou = ({ setActiveIndex, getRentalApp, setActiveTab }) => {
    const [loading, setLoading] = useState(false)
    const { rentalData } = useSelector((state) => state?.rental)

    const dispatch = useDispatch()

    const handleBack = () => {
      
        if (rentalData) {
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
                dispatch(AddIndex(2))
                setActiveIndex(0)
                setActiveTab([1])
                getRentalApp()
            }, 3000);
        }
        else {
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
                dispatch(AddIndex(0))
            }, 3000);
        }
    }
    const { t } = useTranslation()
    return (
        <>
            <div className='p-10'>
                <h1 className='text-center text-[25px]'>{t('thank_you')}</h1>
                <div className='flex justify-center py-3'>
                    <button onClick={() => handleBack()} type='submit' className='font-semibold bg-[#F79F15] w-[30%] px-6 py-1.5 text-white rounded-md'>
                        {loading ? <Loader /> : t('done')}
                    </button>
                </div>
            </div>
        </>
    )
}

export default ThankYou