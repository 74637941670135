import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setLanguageSelect } from '../../../Redux/UserAuthSlice/UserAuthSlice'
import { Loader } from '../../../utils/loaderButton'
import { UpdateRental } from '../../../lib/Api/RantelServiceApi'
import { toast } from 'react-toastify'
const SelectLanguage = ({ close, type, data, fetchData }) => {
    const [lang, setLang] = useState("")
    const [loader, setLoader] = useState(false)

    const dispatch = useDispatch()

    const handleChange = (e) => {
        let value = e.target.value
        setLang(value)
        dispatch(setLanguageSelect(value))
    }

    const handleUpdateLang = async (e) => {
        e.preventDefault()
        if (lang === "") {
            toast.error(t('select_lang_error'))
        } else {
            const obj = data;
            obj.store_in_language = lang;
            let payload = {
                ...obj,
            }
            setLoader(true)
            data.images = null
            let { res, error } = await UpdateRental(payload)
            if (res) {
                setLoader(false)
                fetchData()
                close()
            }
            else if (error) {
                setLoader(false)

            }
        }
    }

    const handleSelect = () => {
        if (lang === "") {
            toast.error(t('select_lang_error'))
        } else {
            close()
        }
    }

    // useEffect(() => {
    //     if (type) {
    //         setLang(data?.lang)
    //     }
    // }, [])

    const { t } = useTranslation()

    return (
        <>
            {
                type === "property" ?

                    <div className='flex justify-center items-center flex-col'>
                        <h2 className="text-center text-[18px] py-5 font-semibold">{t('language_sel')}</h2>
                        <div className='flex items-center gap-3'>
                            <select onChange={handleChange} value={lang} className=' max-w-[300px] border rounded-md normal-case text-left text-[16px] font-semibold focus:outline-none bg-white text-black h-[40px] px-2'>
                                <option value="" >{t('language_sel')} </option>
                                <option value="en">English</option>
                                <option value="es">Spanish</option>
                            </select>
                        </div>
                        <div className='pt-5'>
                            <button onClick={(e) => handleUpdateLang(e)} className='w-[200px]  py-2 px-6 rounded-md flex justify-center items-center bg-[#F79F15] text-white text-[18px]'>
                                {loader ? <Loader type="single" /> : t('contact_submit')}</button>
                        </div>

                    </div>
                    :
                    <div className='flex justify-center items-center flex-col'>
                        <h2 className="text-center text-[18px] py-5 font-semibold">{
                            type === "rentals" ? t('select_language_rentals') : t('select_language')

                        }</h2>
                        <div className='flex items-center gap-3'>
                            <h2>{t('language_sel')}</h2>
                            <select onChange={handleChange} className=' max-w-[300px] border rounded-md normal-case text-left text-[16px] font-semibold focus:outline-none bg-white text-black h-[40px] px-2'>
                                <option value="" >{t('language_sel')} </option>
                                <option value="en">English</option>
                                <option value="es">Spanish</option>
                            </select>
                        </div>
                        <div className='pt-5'>
                            <button onClick={() => handleSelect()} className='w-[200px]  py-2 px-6 rounded-md  bg-[#F79F15] text-white text-[18px]'>
                                {loader ? <Loader type="single" /> : t('contact_submit')}</button>
                        </div>

                    </div>
            }
        </>
    )
}

export default SelectLanguage