import React from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const HowItsWork = ({ handleNext, type = 'rental_app' }) => {
  let location = useLocation().pathname
  let {t} = useTranslation()
  return (
    <>
      <div className='bg-white relative pt-5'>
        {type === 'co_signer_app' ? (
          <>
            <h2 className='text-[18px] font-bold'>{t('need_a_co_signer')}</h2>
            <p className='text-[14px]'>{t('co_signer_description')}</p>
            <div className='pt-5'>
              <h1 className='text-[18px] font-bold'>{t('how_its_work')}</h1>
              <p className='text-[14px]'>{t('co_signer_how_it_works')}</p>
            </div>
          </>
        ) : (
          <>
            <h2 className='text-[18px] font-bold'>
              {location === '/leasedBuilder' ? t('try_lease_b') : t('try_rental_app')}
            </h2>
            <p className='text-[14px]'>
              {location === '/leasedBuilder' ? t('free_lease') : t('pay_ren_app')}
            </p>
            <div className='pt-5'>
              <h1 className='text-[18px] font-bold'>{t('how_its_work')}</h1>
              <p className='text-[14px]'>
                {location === '/leasedBuilder' ? t('lease_b') : t('rent_desc')}
              </p>
            </div>
          </>
        )}

        <div className='pt-[5rem] flex justify-center items-center'>
          <button className='font-semibold bg-[#F79F15] xl:w-[20%] lg:w-[20%] md:w-[20%] w-full py-2 text-white rounded-md' onClick={() => handleNext()}>{t('get_start')}</button>
        </div>

        {/* <div className='pt-5'>
          <h1 className='text-[18px] font-bold '>Discolsure:</h1>
          <div className="border rounded-md p-3 my-2">
            <p className='text-center font-bold '>Rental Leased Agreement </p>
          </div>
        </div> */}
      </div>
    </>
  )
}

export default HowItsWork