import React, { useState, useEffect, useRef } from 'react'
import avatar from '../../../assests/images/f10.png'
import { useDispatch, useSelector } from 'react-redux'
import { MdOutlinePhotoCamera } from 'react-icons/md'
import { Loader } from '../../../utils/loaderButton'
import { FaRegImages } from 'react-icons/fa'
import { IoMdCloseCircle } from 'react-icons/io'
import { ConvertBase64 } from '../../../utils/ConvBase64'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import MultiRangeSlider from "multi-range-slider-react";
import { CheckRentalApp, GetReazySettings, RegReazySettings } from '../../../lib/Api/RentalAppApi'
import { setIsNewStatus } from '../../../Redux/UserAuthSlice/RentalSlice'
import { useNavigate } from 'react-router-dom'
// import Select from 'react-select'
import Autocomplete from "react-google-autocomplete";

const ReazySettings = ({ title, close }) => {
    const [Createloading, setCreateLoading] = useState(false)
    const [ImgLoading, setImgLoading] = useState(false)
    const [loadingCheck, setLoadingCheck] = useState(false)
    const [ImgUpload, setImgUpload] = useState(false)
    const [profileImgUrl, setProfileImgUrl] = useState("")
    const [isRentalApp, setIsRentalApp] = useState(false)
    const [fileImages, setFileImages] = useState([])
    const [ImageObj, setImgObj] = useState({})
    const [step, setStep] = useState(0)
    const [defaultAddress, setDefaultAdress] = useState("")

    const [formData, setFormData] = useState({
        images: [],
        user_image_base64: "",
        credit_score_range: [350, 500],
        bi_weekly_income_range: [2000, 4000],
        rent_affording_range: [1000, 2000],
        description: "",
        city: "",
        state: "",
        have_pets: false,
        have_children: false,
        qualify_for_federal_aid: false,
        looking_for_smoke_free: false
    });

    const dispatch = useDispatch()
    const inputFile = useRef(null)
    let navigate = useNavigate()

    const { t } = useTranslation()
    const user = useSelector((state) => state?.userAuth?.userInfo)

    const handleImage = async (e) => {
        const file = e.target.files[0]
        const convert = await ConvertBase64(file)
        setProfileImgUrl(convert)
        setFormData(prevState => ({
            ...prevState,
            user_image_base64: convert
        }));
    }


    const autocompleteOptions =
    {
        types: ['(regions)'],
        componentRestrictions: { country: 'us' },
    }


    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        let fileLeng = formData?.images?.length
        if (fileLeng > 5) {
            toast.error(t('upload_img_info'))
        }
        else if (files?.length > 5) {
            toast.error(t('upload_img_info'))
        }
        else {
            const acceptedFileTypes = ['image/jpeg', 'image/png']; // Add more accepted file types as needed
            const imageFiles = files.filter(file => acceptedFileTypes.includes(file.type));
            setFileImages(imageFiles)
            Promise.all(
                imageFiles.map(file => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = error => reject(error);
                        reader.readAsDataURL(file);
                    });
                })
            )
                .then(base64Images => {
                    if (formData?.images?.length) {
                        // setImages((prev) => [...prev, base64Images])
                        setFormData(prevState => ({
                            ...prevState,
                            images: [...(prevState.images || []), ...base64Images]
                        }));
                    }
                    else {
                        // setImages(base64Images)
                        setFormData(prevState => ({
                            ...prevState,
                            images: base64Images
                        }));
                    }
                }
                )
        }
    }

    const RemoveImage = async (index) => {
        setImgLoading(true)
        setImgObj(index)
        let findList = formData?.images?.find((f, i) => i === index)
        let ImagesList = formData?.images?.filter((_, i) => i !== index)
        if (findList) {
            setImgLoading(false)
            // setImages(ImagesList)
            setFormData(prevState => ({
                ...prevState,
                images: ImagesList
            }));
        }
        else {
            setTimeout(() => {
                setImgLoading(false)
                // setImages(ImagesList)
                setFormData(prevState => ({
                    ...prevState,
                    images: ImagesList
                }));
            }, 2000);
        }

    }

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleMultiRangeChange = (minValue, maxValue, name) => {
        // const step = name === 'credit_score_range' ? 10 : 100;  // Define step values based on the slider type
        // const adjustedMinValue = Math.round(minValue / step) * step;
        // const adjustedMaxValue = Math.round(maxValue / step) * step;
        setFormData(prevState => ({
            ...prevState,
            [name]: [minValue, maxValue],
        }));
    };


    const handleNext = async (e) => {
        e.preventDefault();
        try {
            const filterImages = formData?.images?.flat()?.filter((image) => !image.hasOwnProperty("id"));
            let payload = { ...formData };
            if (filterImages?.length > 0) {
                payload.images = filterImages
            } else {
                payload.images = formData?.images || []
            }
            setCreateLoading(true);
            let res = await RegReazySettings(payload);
            if (res) {
                if (title) {
                    close()
                } else {
                    dispatch(setIsNewStatus(false))

                }
            }
        } catch (err) {
        } finally {
            setCreateLoading(false);
        }
    };


    const onButtonClick = () => {
        inputFile.current.click();

    }
    const handleMove = (type) => {
        if (type === "yes") {
            navigate('/rentalApp')
            dispatch(setIsNewStatus(false))
        } else {
            setStep(1)
            // dispatch(setIsNewStatus(false))
        }
    }

    let fetch = async () => {
        setLoadingCheck(true)
        let { response } = await CheckRentalApp()
        if (response?.purchased === true) {
            setLoadingCheck(false)
            setIsRentalApp(true)
            setStep(1)
        } else {
            setLoadingCheck(false)
            setStep(0)
            setIsRentalApp(false)
        }
    }
    const fetchSettigns = async () => {
        let res = await GetReazySettings();
        if (res) {
            let obj = Object.assign({}, ...res);
            debugger
            if (obj?.settings !== null) {
                const data = {
                    images: obj.settings?.images,
                    credit_score_range: obj.settings?.credit_score_range || [350, 500],
                    bi_weekly_income_range: obj.settings?.bi_weekly_income_range || [2000, 4000],
                    rent_affording_range: obj.settings?.rent_affording_range || [1000, 2000],
                    description: obj.settings?.description,
                    have_pets: obj?.settings?.have_pets,
                    city: obj?.settings?.city,
                    state: obj?.settings?.state,
                    have_children: obj.settings?.have_children === true ? true : false ,
                    qualify_for_federal_aid: obj.settings?.qualify_for_federal_aid === true ? true : false ,
                    looking_for_smoke_free: obj.settings?.looking_for_smoke_free === true ? true : false 
                };
                setFormData(data);
                setDefaultAdress(`${obj.settings?.city && obj.settings?.city || ""} ${obj.settings?.state && obj.settings?.state || ""}` || "")

            } else {
                // setCities([]);
            }
        }
    };
    useEffect(() => {
        fetch()
        fetchSettigns()
        if (user) {
            setProfileImgUrl(user?.userimg !== "https://leased.s3.amazonaws.com/" ? user?.userimg : avatar)
        }
    }, [])

    console.log("formDta", formData)

    return (
        <>
            {
                loadingCheck ?
                    <div>
                        <Loader type={"loadData"} />
                    </div>
                    :
                    <div>
                        {
                            step === 0 ?
                                <div className='max-w-[800px] mx-auto flex justify-center items-center flex-col pt-10'>
                                    <h2 className='text-center text-[25px] font-semibold pt-2'>{t('r_check_rent_app')}</h2>
                                    <div className='pt-4  flex justify-center items-center gap-2'>
                                        <button onClick={() => handleMove("yes")} className="px-6 py-2 text-center rounded-xl text-white bg-[#F79F15]" >Yes</button>
                                        <button onClick={() => handleMove("no")} className="px-6 py-2 text-center rounded-xl text-white bg-[#F79F15]" >No</button>
                                    </div>
                                </div>
                                :
                                <div>
                                    {title ? "" :
                                        <h1 className='text-center text-[25px] font-semibold pt-2'>{t('rommate_p_detail')}</h1>
                                    }
                                    <form className='pb-4' >
                                        <div className='mt-5 border shadow-md p-5 rounded-sm'>
                                            <div className='flex justify-center items-center relative  max-w-[180px] mx-auto'>
                                                <div className='w-[150px] h-[150px] border rounded-full'>
                                                    <img src={profileImgUrl} alt="profile" className="w-[150px] h-[150px] rounded-full" />
                                                </div>
                                                <div className='absolute top-5 left-5 '>
                                                    {
                                                        ImgUpload &&
                                                        <div className=' absolute top-9 left-14'>
                                                            <Loader type="socialLoder" />
                                                        </div>
                                                    }
                                                </div>
                                                <div className='absolute  top-[40%] right-[0%] cursor-pointer'>
                                                    <div onClick={onButtonClick} className='border bg-white bg-opacity-[0.7] p-1 rounded-full'>
                                                        <MdOutlinePhotoCamera className='text-[15px] text-[#F79F15]' />
                                                    </div>
                                                    <input type="file" ref={inputFile} accept="image/*" onChange={handleImage} className='hidden' />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='pt-6'>
                                            <label className='mb-2  text-[16px] font-semibold'>{t('rommate_p_upload_p')} </label>
                                            <div className='border rounded-md h-auto pl-3 mt-5 flex xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap  items-center '>
                                                <label htmlFor='upload' className={`${formData?.images?.length === 5 ? 'cursor-not-allowed' : 'cursor-pointer'} bg-gray-100 cursor-po <label htmlFor='upload'inter border rounded-md px-6 h-[35px] flex justify-center items-center xl:mt-0 lg:mt-0 md:mt-0 mt-2`}>
                                                    <div className='flex items-center text-gray-400  ' >
                                                        <FaRegImages />
                                                        <h2 className='px-2'>{t('uplod_lbl')}</h2>
                                                    </div>
                                                </label>
                                                <input type="file" id="upload" className='hidden' disabled={formData?.images?.length === 5 ? true : false} name="image_upload" accept="image/*" multiple onChange={(e) => handleImageChange(e, "images")} />
                                                <div className=' xl:ml-3 lg:ml-3 md:ml-3 xl:mt-0 lg:mt-0 md:mt-0 mt-2  grid xl:grid-cols-8 lg:grid-cols-8 md:grid-cols-8 grid-cols-4 gap-2  '>
                                                    {
                                                        formData?.images?.map((u, i) => (
                                                            <div className={`relative xl:first:mx-2 lg:first:mx-2 md:first:mx-2 first:mx-0 border ${title ? 'w-[50px] h-[50px]' : 'w-[70px] h-[70px]'} rounded-md`} key={i}>
                                                                <img src={u} alt="user_image" className={`object-cover  rounded-md ${title ? 'w-[50px] h-[50px]' : 'w-[70px] h-[70px]'}`} />
                                                                {
                                                                    ImgLoading && ImageObj === i ?
                                                                        <div className='absolute top-1'>
                                                                            <Loader type="socialLoder" />
                                                                        </div>
                                                                        :
                                                                        <div className='absolute right-1 top-1' onClick={() => RemoveImage(i)}>
                                                                            <IoMdCloseCircle className='text-red-500 cursor-pointer' />
                                                                        </div>
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <h2 className='text-[16px] font-semibold pt-4'>{t('r_current_credit')}</h2>
                                            <div className='flex items-center gap-2'>
                                                <span>{formData.credit_score_range[0]}</span>
                                                <MultiRangeSlider
                                                    min={0}
                                                    max={850}
                                                    step={10}
                                                    stepOnly={true}
                                                    minValue={formData.credit_score_range[0]} // Use the state value
                                                    maxValue={formData.credit_score_range[1]} // Use the state value
                                                    barInnerColor={"#176B87"}
                                                    labels={false}
                                                    ruler={false}
                                                    label={false}
                                                    onChange={({ minValue, maxValue }) =>
                                                        handleMultiRangeChange(minValue, maxValue, 'credit_score_range')
                                                    }
                                                />
                                                <p>{formData.credit_score_range[1]}</p>
                                            </div>
                                        </div>

                                        <div className='grid grid-cols-2 gap-5'>
                                            <div>
                                                <h2 className='text-[16px] font-semibold pt-4'>{t('r_bi_icome')}</h2>
                                                <div className='flex items-center gap-2'>
                                                    <input
                                                        type="text"
                                                        className="border rounded-md focus:outline-none w-full px-2 h-[40px] mt-2"
                                                        value={formData.bi_weekly_income_range[1]}
                                                        onChange={(e) =>
                                                            handleMultiRangeChange(0, e.target.value, 'bi_weekly_income_range')
                                                        }
                                                    />
                                                    {/* <span>{formData.bi_weekly_income_range[0]}</span>
                                                <MultiRangeSlider
                                                    min={0}
                                                    max={10000}
                                                    step={100}
                                                    stepOnly={true}
                                                    minValue={formData.bi_weekly_income_range[0]} // Use the state value
                                                    maxValue={formData.bi_weekly_income_range[1]} // Use the state value
                                                    barInnerColor={"#176B87"}
                                                    labels={false}
                                                    ruler={false}
                                                    label={false}
                                                    onChange={({ minValue, maxValue }) =>
                                                        handleMultiRangeChange(minValue, maxValue, 'bi_weekly_income_range')
                                                    }
                                                />
                                                <p>{formData.bi_weekly_income_range[1]}</p> */}
                                                </div>
                                            </div>
                                            <div>
                                                <h2 className='text-[16px] font-semibold pt-4'>{t('r_utility_bil')}</h2>
                                                <div className='flex items-center gap-2'>
                                                    <input
                                                        type="text"
                                                        className="border rounded-md focus:outline-none w-full px-2 h-[40px] mt-2"
                                                        value={formData.rent_affording_range[1]}
                                                        onChange={(e) =>
                                                            handleMultiRangeChange(0, e.target.value, 'rent_affording_range')
                                                        }
                                                    />
                                                    {/* <span>{formData.rent_affording_range[0]}</span>
                                                <MultiRangeSlider
                                                    min={0}
                                                    max={10000}
                                                    step={100}
                                                    stepOnly={true}
                                                    minValue={formData.rent_affording_range[0]} // Use the state value
                                                    maxValue={formData.rent_affording_range[1]} // Use the state value
                                                    barInnerColor={"#176B87"}
                                                    labels={false}
                                                    ruler={false}
                                                    label={false}
                                                    onChange={({ minValue, maxValue }) =>
                                                        handleMultiRangeChange(minValue, maxValue, 'rent_affording_range')
                                                    }
                                                />
                                                <p>{formData.rent_affording_range[1]}</p> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <h2 className='text-[16px] font-semibold pt-4'>{t('city_state')}</h2>
                                            <div className=' bg-[#F5F7F9] rounded-md w-full p-1 max-w-[500px] flex  items-center'>
                                                <Autocomplete
                                                    apiKey={"AIzaSyCpBFsGdMzJ_qo1PPGUM6i0g__WTZuzTQk"}
                                                    options={autocompleteOptions}
                                                    defaultValue={defaultAddress}
                                                    onPlaceSelected={async (place) => {
                                                        setDefaultAdress(place?.formatted_address)
                                                        // setLatitude(place.geometry?.location?.lat());
                                                        // setLongitude(place.geometry?.location?.lng());
                                                        let city = "";
                                                        let country = "";
                                                        let zipcode = "";
                                                        let street = "";
                                                        let propertyId = "";
                                                        // Iterate through the address components
                                                        place?.address_components.forEach(component => {
                                                            const types = component.types;
                                                            // Check for the types relevant to city, country, and zipcode
                                                            if (types.includes("locality")) {
                                                                city = component.long_name;
                                                            } else if (types.includes("administrative_area_level_1")) {
                                                                country = component.short_name;
                                                            } else if (types.includes("postal_code")) {
                                                                zipcode = component.long_name;
                                                            }
                                                            else if (types.includes("route")) {
                                                                street = component.long_name;
                                                            }
                                                            else if (types.includes("street_number")) {
                                                                propertyId = component.long_name;
                                                            }
                                                        })
                                                        // let payload = {
                                                        //     city,
                                                        //     state: country,
                                                        //     zipcode,
                                                        //     street: `${propertyId} ${street}`
                                                        // }
                                                        setFormData((prev) => ({
                                                            ...prev,
                                                            city: city,
                                                            state: country

                                                        }))
                                                        // setAddressCityState(payload)

                                                    }}

                                                    className='w-full bg-[#F5F7F9]
                                            border-transparent px-2 focus:outline-none placeholder:text-[#A5A5A5]'
                                                    placeholder={t('select_location')}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className='grid grid-cols-2 py-2 gap-5'>
                                <div className='flex flex-col'>
                                    <h2 className='text-[16px] font-semibold '>{t('state')}</h2>
                                    <label className="relative inline-flex items-center cursor-pointer mt-2">
                                    
                                        <Select
                                            name="state"
                                            options={states}
                                            onChange={handleSelectState}
                                            className='w-full'
                                            placeholder={t('please_select')}
                                            value={states.find(state => state.label === formData.state)}
                                        />

                                    </label>
                                </div>
                                <div className='flex flex-col'>
                                    <h2 className='text-[16px] font-semibold '>{t('city')}</h2>
                                    <label className="relative inline-flex items-center cursor-pointer mt-2">
                                       
                                        <Select
                                            name="city"
                                            options={cities}
                                            onChange={handleSelectCity}
                                            className='w-full'
                                            placeholder={t('please_select')}
                                            isDisabled={!cities.length}
                                            value={cities.find(city => city.label === formData.city)}
                                        />

                                    </label>
                                </div>
                            </div> */}
                                        <div className='grid grid-cols-2 py-2'>
                                            <div className='flex flex-col'>
                                                <h2 className='text-[16px] font-semibold '>{t('r_have_pets')}</h2>
                                                <label className="relative inline-flex items-center cursor-pointer mt-2">
                                                    <input type="checkbox"
                                                        checked={formData.have_pets}
                                                        name="have_pets"
                                                        onChange={handleChange}
                                                        className="sr-only peer" />
                                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#B913F0] dark:peer-focus:ring-[#B913F0] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#B913F0]"></div>
                                                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 px-3">
                                                        {formData.have_pets ? t('yes') : t('no')}
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='flex flex-col'>
                                                <h2 className='text-[16px] font-semibold '>{t('r_have_children')}</h2>
                                                <label className="relative inline-flex items-center cursor-pointer mt-2">
                                                    <input
                                                        type="checkbox"
                                                        name="have_children"
                                                        checked={formData.have_children}
                                                        onChange={handleChange}
                                                        className="sr-only peer" />
                                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#B913F0] dark:peer-focus:ring-[#B913F0] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#B913F0]"></div>
                                                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 px-3">
                                                        {formData.have_children ? t('yes') : t('no')}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className='grid grid-cols-2 py-2'>
                                            <div className='flex flex-col'>
                                                <h2 className='text-[16px] font-semibold '>{t('r_have_aid')}</h2>
                                                <label className="relative inline-flex items-center cursor-pointer mt-2">
                                                    <input type="checkbox"
                                                        checked={formData.qualify_for_federal_aid}
                                                        name="qualify_for_federal_aid"
                                                        onChange={handleChange}
                                                        className="sr-only peer" />
                                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#B913F0] dark:peer-focus:ring-[#B913F0] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#B913F0]"></div>
                                                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 px-3">
                                                        {formData.qualify_for_federal_aid ? t('yes') : t('no')}
                                                    </span>
                                                </label>
                                            </div>
                                            <div className='flex flex-col'>
                                                <h2 className='text-[16px] font-semibold '>{t('r_you_smoke')}</h2>
                                                <label className="relative inline-flex items-center cursor-pointer mt-2">
                                                    <input
                                                        type="checkbox"
                                                        name="looking_for_smoke_free"
                                                        checked={formData.looking_for_smoke_free}
                                                        onChange={handleChange}
                                                        className="sr-only peer" />
                                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#B913F0] dark:peer-focus:ring-[#B913F0] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#B913F0]"></div>
                                                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 px-3">
                                                        {formData.looking_for_smoke_free ? t('yes') : t('no')}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="pt-4">
                                            <h2 className='text-[16px] font-semibold pb-2  focus:outline-none'>{t('describe')}</h2>
                                            <textarea rows={5} value={formData?.description} onChange={(e) => setFormData((prev) => ({ ...prev, description: e.target.value }))} className='border rounded-md w-full px-5 py-2' placeholder={t('desc')}></textarea>
                                        </div>
                                        <div className='flex justify-end items-end mt-5 gap-2'>
                                            <button onClick={(e) => handleNext(e)} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
                                                {Createloading ? <Loader type="saveLoder" /> : title ? t('contact_submit') : t('next_lbl')}
                                            </button>
                                        </div>
                                    </form>
                                </div>


                        }
                    </div>
            }

        </>
    )
}

export default ReazySettings