
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements
} from "react-router-dom";
import { useEffect } from "react";
import SignIn from "../pages/AuthPages/SignIn";
import SignUp from "../pages/AuthPages/SignUp";
import Landing from "../pages/LandingPage/Landing";
import AboutUs from "../pages/AboutUs/AboutUs";
import Blog from "../pages/Blog/Blog";
import BlogDetail from "../pages/Blog/BlogDetail";
import Home from "../pages/LandingPage/Home";
import List from "../pages/ListPage/List";
import Rommate from "../pages/RommatePage/Rommate";
import Rent from "../pages/ListPage/Rent";
import Property from "../pages/Property/Property";
import AuthProvider from "../hooks/AuthProvider";
import RentalDashboard from "../pages/RentalPanelPages/RentalDashboard";
import Payment from "../pages/RentalPanelPages/Payment";
import Profile from "../pages/RentalPanelPages/Profile";
import RenatalIndex from "../components/RenatalPanelComp/RentalApp/RenatalIndex";
import Faq from "../pages/FaqPage/Faq";
import ContactUs from "../pages/ContactUsPage/ContactUs";
import ListRommate from "../pages/ListPage/ListRommate";
import RentRommate from "../pages/RommatePage/RentRommate";
import ServicerDashboard from "../pages/ServicerPanelPages/ServicerDashboard";
import RegisterServicer from "../components/ServicerPanelComp/RentalRegister/RegisterServicer";
import LeasedLists from "../components/ServicerPanelComp/LeasedBuilder/LeasedLists";
import CreateLeasd from "../components/ServicerPanelComp/LeasedBuilder/CreateLeasd";
// import RootBoundary from "../Partials/ErrorFun";
import ErrorBoundary from "../Partials/RouterError";
import CreatePayment from "../components/RenatalPanelComp/Payment/createPayment/Create";
import Flash from "../pages/ServicerPanelPages/Flash";
import Reasy from "../pages/ServicerPanelPages/Reasy";
import Messages from "../pages/Messages/Mesaages";
import MyList from "../components/ServicerPanelComp/MyList/MyList";
// import PdfPreview from "../components/RenatalPanelComp/RentalApp/PdfPreview";
import PropertyDetail from "../pages/Property/PropertyDetail";
import RentalProperty from "../pages/RentalPanelPages/Property/Property";
// import Flashpass from "../pages/ServicerPanelPages/FlashPass";
// import REasypass from "../pages/ServicerPanelPages/EasyPass";
import ServicerFlashpass from "../pages/ServicerPanelPages/FlashPass";
// import RentalFlashPass from "../pages/RentalPanelPages/RentalFlashPass";
// import RentalsDetail from "../pages/Property/PropertyDetail";
import Privacy from "../pages/Privacy/Privacy";
import Terms from "../pages/Terms/Privacy";
import SuccessPayment from "../components/GenericComp/SuccessPayment";
import RenterDetails from "../pages/RentalPanelPages/Property/PropertyDetail";
import ThankYou from "../components/GenericComp/ThankYou";
// import BlockTime from "../components/GenericComp/BlockTime";
import RommatePage from "../pages/ServicerPanelPages/RommatePage";
import CreateRoommate from "../components/ServicerPanelComp/RoomateComp/CreateRoommate";
import NotFound from "../pages/NotFound/NotFound";
import { useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";
import i18next from "i18next"
import FilterRommate from "../components/RenatalPanelComp/FilterRommate/CreateRoommate";
import SearchProperty from "../components/LandingComp/SearchProperty";
import UsersList from "../pages/Messages/UsersList";
import ThankYouReasy from "../components/GenericComp/ThankYouReasy";
import FilterPreferance from "../components/ServicerPanelComp/ReazyLane/FilterPreferance";
import FlashServicer from "../pages/ServicerPanelPages/FlashServicer";
import CoSignerIndex from "../components/RenatalPanelComp/CoSignerApp/CoSignerIndex";


const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* Landing page Routes */}
      <Route path="/">
        <Route index element={<Landing />} />
        {/* <Route path="/home" element={<Home />} /> */}
        <Route path="/home" element={<Home />} />
        <Route path="/signIn" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blogDetail" element={<BlogDetail />} />
        <Route path="/list" element={<Rent />} />
        <Route path="/rommate" element={<Rommate />} />
        <Route path="/rent" element={<Rent />} />
        <Route path="/list/rentals" element={<List />} />
        <Route path="/list/rommate" element={<ListRommate />} />
        <Route path="/rent/rommate" element={<RentRommate />} />
        <Route path="/rent/rentals" element={<Rommate />} />
        <Route path="/property" element={<Property />} />
        <Route path="/propertyDetail/:id" element={<PropertyDetail />} />
        <Route path="/aboutus/faq" element={<Faq />} />
        <Route path="/aboutus/contactus" element={<ContactUs />} />
        <Route path="/privacy" element={<Privacy lang="en" />} />
        <Route path="/sp/privacy" element={<Privacy lang="sp" />} />
        <Route path="/search" element={<SearchProperty />} />
        {/* terms */}
        <Route path="/terms" element={<Terms lang="en" />} />
        <Route path="/sp/terms" element={<Terms lang="sp" />} />
        <Route path="/success" element={<SuccessPayment />} />
        <Route path="*" element={<NotFound type="mainPage" />} />
      </Route>
      {/* Rental Panel Routes */}

      <Route
        element={<AuthProvider />}
      >
        <Route path="/rental/rental_property" element={<RentalProperty />} />
        <Route path="/dashboard" element={<RentalDashboard />} />
        <Route path="/propertyDetails/:id" element={<PropertyDetail />} />
        <Route path="/createPayment" element={<CreatePayment />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/rentalApp" element={<RenatalIndex />} />
        <Route path="/coSignerApp/:coSignerId" element={<CoSignerIndex />} />
        <Route path="/flash" element={<Flash />} />
        {/* <Route path="/reazy-pass" element={<REasypass />} /> */}
        {/* <Route path="/flash-pass" element={<RentalFlashPass />} /> */}
        <Route path="/reazy" element={<Reasy />} />
        <Route path="/thankYou" element={<ThankYou />} />
        <Route path="/thankYouR-easy" element={<ThankYouReasy />} />
        {/* <Route path="/booking" element={<BlockTime />} /> */}
        <Route path="/message/:id" element={<Messages />} />
        <Route path="/userList" element={<UsersList />} />
        <Route path="/filterRommate" element={<FilterRommate />} />
        {/* <Route path="/preview" element={<PdfPreview />} /> */}
        {/* <Route path="*" element={<NotFound type="renter" />} /> */}

      </Route>

      {/* Servicer Panel Routes */}

      <Route
        element={<AuthProvider />}
        errorElement={<ErrorBoundary />}
      >
        <Route path="/servicerPanel"
          element={<ServicerDashboard />}
          errorElement={<p>An Error Occur</p>}
        />
        {/* My list  */}
        <Route path="/mylist" element={<MyList />} />
        {/* Register property  */}
        <Route path="/register" element={<RegisterServicer />} />
        <Route path="/register/:id" element={<RegisterServicer />} />
        <Route path="/renterDetails/:id" element={<RenterDetails />} />

        {/* Register Leased builder  */}
        <Route path="/leasedBuilder" element={<LeasedLists />} />
        <Route path="/createBuilder" element={<CreateLeasd />} />
        <Route path="/createBuilder/:id" element={<CreateLeasd />} />
        <Route path="/servicer-flash-pass" element={<ServicerFlashpass />} />
        {/* Roommate Component */}
        <Route path="/roommatesList" element={<RommatePage />} />
        <Route path="/CreateRoommate" element={<CreateRoommate />} />
        {/* other Components */}
        <Route path="/servicer_payment" element={<Payment />} />
        <Route path="/servicer_profile" element={<Profile />} />
        <Route path="/servicer_flash" element={<FlashServicer />} />
        <Route path="/servicer_reazy" element={<Reasy />} />
        <Route path="/filterPrefrence" element={<FilterPreferance />} />
        {/* <Route path="*" element={<NotFound />} /> */}
      </Route>
    </>
  )
);

function App() {
  const lng = useSelector((state) => state?.userAuth?.language)

  // const { t } = useTranslation()

  useEffect(() => {
    if (lng) {
      i18next.changeLanguage(lng)
    }
  }, [])
  return (
    <>

      <RouterProvider router={router} />
    </>
  );
}

export default App;
