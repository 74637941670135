import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import UserAuthReducer from './UserAuthSlice/UserAuthSlice';
import RentalSlice from './UserAuthSlice/RentalSlice';
import CoSignerRentalSlice from './UserAuthSlice/CoSignerRentalSlice';
import { combineReducers } from "redux";
import RommateSlice from './UserAuthSlice/RommateSlice';


const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    userAuth: UserAuthReducer,
    rental:RentalSlice,
    coSignerRental:CoSignerRentalSlice,
    rommate:RommateSlice,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)



export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
})


export const persistor = persistStore(store)