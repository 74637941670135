import React, { useEffect, useState } from 'react'
// import card from '../../../assests/images/master.png'
// import { BsPlus } from 'react-icons/bs'
import AddPaymentPopup from './AddPaymentPopup'
import ThankYou from '../../GenericComp/ThankYou'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { GetBillingDetailRenter, GetPaymentIntent, GetResyPaymentIntent, RegisterPaymentIntent, RegisterReasyIntent } from '../../../lib/Api/RentalAppApi'
import { stripePromise, stripePromiseDta } from '../../../config/Stripe'
import { Loader } from '../../../utils/loaderButton'
import {
    GetBillingDetailServicer, GetPaymentIntentServicer,
    //  GetServicerPaymentIntent, 
    // ServicerPaymentIntent
} from '../../../lib/Api/RantelServiceApi'
// import { MdArrowBack } from 'react-icons/md'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { setLanguageSelect, setPurcahseInfo } from '../../../Redux/UserAuthSlice/UserAuthSlice'
import DynamicPopup from '../../../utils/Popup/DynamicPopup'
import SelectLanguage from './SelectLanguage'
// import BlockTime from '../../GenericComp/BlockTime'
const Purchase = ({ activeTab, data, booked, autoBook, setPackage, property }) => {
    const [open, setOpen] = useState(false)
    const [isOpenPopup, setIsOpenPopup] = useState(false)
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [bills, setBills] = useState({})
    const [loadingSession, setLoadingSession] = useState(false)
    const location = useLocation().pathname

    let user = useSelector((state) => state?.userAuth?.userInfo)
    const dispatch = useDispatch()

    const websiteLink = window.location.origin;

    const link_loc = useLocation().pathname

    // console.log("activeTab", activeTab)

    let RentalFlashLaneSubs = async () => {
        try {
            setLoadingSession(true);
            let payload = {
                "price": data?.id,
                success_url: link_loc === "/reazy" ? `${websiteLink}/thankYouR-easy` : `${websiteLink}/thankYou`
            };
            let payload2 = {
                "price": data?.id,
                "blocked_times": booked,
            };
            if (activeTab === 1) {
                payload2.is_roommate = true
            }
            let payloadInfo = {
                ...payload2,
                type: link_loc === "/reazy" ? "flash_reazy" : "flash_lane"
            }
            dispatch(setPurcahseInfo(payloadInfo))
            if (Array.isArray(property) && location !== "/flash") {
                payload.property_id = property?.map((item) => item?.value);
            }
            if (Array.isArray(property) && location !== "/reazy") {
                payload2.property_id = property?.map((item) => item?.value);
            }
            let { res } = await GetPaymentIntent(payload);
            let updatedData = Object.assign({}, ...res);
            let stripe = await stripePromiseDta(updatedData?.publishable_key)
            const result = await stripe.redirectToCheckout({
                sessionId: updatedData?.checkout_session?.id,
            });

            if (result.error) {
                setLoadingSession(false);
                return;
            }

            // Call the intent API after successful checkout


            setLoadingSession(false);
        } catch (error) {
            setLoadingSession(false);
            // Handle error here
        }
    };

    // let RentalFlashLaneSubs = async (stripe) => {
    //     try {
    //         setLoadingSession(true);
    //         let payload = {
    //             "price": data?.id,
    //             success_url: link_loc === "/reazy" ? `${websiteLink}/thankYouR-easy` : `${websiteLink}/thankYou`
    //         };
    //         let payload2 = { "price": data?.id };
    //         if (Array.isArray(property) && location !== "/flash") {
    //             payload.property_id = property?.map((item) => item?.value);
    //         }
    //         if (Array.isArray(property) && location !== "/reazy") {
    //             payload2.property_id = property?.map((item) => item?.value);
    //         }
    //         let response = await (link_loc === "/reazy" ? RegisterReasyIntent(payload2) : RegisterPaymentIntent(payload2));
    //         let { res } = await GetPaymentIntent(payload);
    //         let updatedData = Object.assign({}, ...res);

    //         const result = await stripe.redirectToCheckout({
    //             sessionId: updatedData?.checkout_session?.id,
    //         });
    //         if (result.error) {
    //         }

    //         setLoadingSession(false);
    //     } catch (error) {
    //         setLoadingSession(false);
    //     }
    // };

    let ServicerFlashLaneSubs = async () => {

        try {
            let payload =
            {
                "price": data?.id,
                success_url: link_loc === "/servicer_reazy" ? `${websiteLink}/thankYouR-easy` : `${websiteLink}/thankYou`
            }
            let paylaod2 = {
                "price": data?.id,
                "blocked_times": booked,
            }
            if (property === "addRommate" || activeTab === 1) {
                payload.is_roommate = true
                paylaod2.is_roommate = true
            } else {
                payload.property_id = property?.map((item) => item.value)
                paylaod2.property_id = property?.map((item) => item.value)
            }
            let payloadInfo = {
                ...paylaod2,
                type: link_loc === "/servicer_reazy" ? "servicer_reazy" : "service_flash"
            }
            dispatch(setPurcahseInfo(payloadInfo))
            setLoadingSession(true)
            let { res } = await GetPaymentIntentServicer(payload)
            let updatedData = Object.assign({}, ...res)
            let stripe = await stripePromiseDta(updatedData?.publishable_key)
            const result = await stripe.redirectToCheckout({
                sessionId: updatedData?.checkout_session?.id,
            });
            if (result.error) {
                setLoadingSession(false)
                return
            }

        } catch (err) {
            setLoadingSession(false)
        }
    }



    const handleClick = async () => {
        // const stripe = await stripePromise;
        if (link_loc === "/flash" || link_loc === "/reazy") {
            RentalFlashLaneSubs()
        }
        else {
            ServicerFlashLaneSubs()

        }

    };


    let FetchBillRenter = async () => {
        setLoading(true)
        let payload = {
            price: data?.id
        }
        let { res } = await GetBillingDetailRenter(payload)
        if (res) {
            setLoading(false)
            setBills(res[0])
        }
        else {
            setLoading(false)
        }
    }

    let FetchBillServicer = async () => {
        setLoading(true)
        let payload = {
            price: data?.id,
            "automatically_accept_appointments": autoBook
        }
        if (property === "addRommate" || activeTab === 1) {
            payload.is_roommate = true
        } else {
            payload.property_id = Array.isArray(property) && property?.map((item) => item.value)
        }
        let { res } = await GetBillingDetailServicer(payload)
        if (res) {
            setLoading(false)
            setBills(res[0])
        }
        else {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (link_loc === "/flash" || link_loc === "/reazy") {
            FetchBillRenter()
        }
        else {
            // if (activeTab === 1) {
            // }
            setIsOpenPopup(true)
            FetchBillServicer()
        }
    }, [])




    const { t } = useTranslation()

    const total = bills?.total / 100; // Should be 52.6
    const totalPayment = total?.toFixed(2); // Ensures it shows 52.60

    return (
        <>
            <div className='flex items-center  font-semibold cursor-pointer ' onClick={() => setPackage()}>
                {t('Back')}
            </div>

            {isOpenPopup && <DynamicPopup type="verify" modalOpen={isOpenPopup} data={<SelectLanguage  close={() => setIsOpenPopup(false)} />} close={() => setIsOpenPopup(true)} />}
            <AddPaymentPopup open={open} setOpen={setOpen} />
            {
                show ? <ThankYou /> :

                    <div className='p-5 max-w-[550px] m-auto'>


                        <div className=''>
                            <h2 className='text-[16px]'>{user?.fname}</h2>
                            <p>{user?.address}</p>
                            <p>{user?.email}</p>
                        </div>
                        <div>
                            <div>
                                <h2 className='font-medium text-[20px] py-2'>{t('bill_de')}</h2>
                                <div className="bg-gray-300 rounded-md p-4">
                                    <div className='flex justify-between items-center'>
                                        <div>
                                            <h2 className='text-[18px] font-semibold'>{location === "/reazy" || location === "/servicer_reazy" ? t('REsy_lane') : t('flash_lane')}</h2>
                                            <p className='text-[14px]'>{t('validate')} {moment(bills?.createdAt).format('ll')}</p>
                                        </div>
                                        <h2 className={`text-[18px] ${location === "/reazy" || location === "/servicer_reazy" ? 'text-[#B913F0] ' : 'text-[#F79F15]'} `}>
                                            {loading ? <Loader type="socialLoder" /> : `$${bills?.price / 100}`}</h2>
                                    </div>

                                    <div className='flex justify-between items-center pt-6 pb-2'>
                                        <div>
                                            <h2 className='text-[18px] font-semibold'>{t('service_fee')}</h2>
                                        </div>
                                        {/* <h2 className='text-[18px] text-[#F79F15]'>$2.60</h2> */}
                                        <h2 className={`text-[18px] ${location === "/reazy" || location === "/servicer_reazy" ? 'text-[#B913F0] ' : 'text-[#F79F15]'} `}>
                                            {loading ? <Loader type="socialLoder" /> :
                                                // 2.60 / 100
                                                `$${(bills?.tax / 100).toFixed(2)}`
                                            }
                                        </h2>
                                    </div>
                                    <div className="w-full border border-gray-500"></div>

                                    <div className='flex justify-between items-center pt-6 pb-2'>
                                        <div>
                                            <h2 className='text-[18px] font-semibold'>{t('total_lbl')}</h2>
                                        </div>
                                        <h2 className={`text-[18px] ${location === "/reazy" || location === "/servicer_reazy" ? 'text-[#B913F0] ' : 'text-[#F79F15]'} `}>
                                            {loading ? <Loader type="socialLoder" /> : `$${totalPayment}`}

                                        </h2>
                                    </div>
                                </div>

                                <div className='my-4'>
                                    <button disabled={loading ? true : false} onClick={() => handleClick()} className={`w-full  py-3 ${location === "/reazy" || location === "/servicer_reazy" ? 'bg-[#B913F0] ' : 'bg-[#F79F15]'} text-white text-[18px] rounded-md`}>
                                        {loadingSession ? <Loader type="loadData" color="#B913F0" /> : t('pur_lbl')}
                                    </button>
                                </div>


                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default Purchase