import React from 'react'
import logo from '../../assests/images/logobg.png'
import { Link } from 'react-router-dom'
import MessageDrop from '../../utils/Dropdowns/MessageDrop'
import UserProfileAdmin from '../../utils/Dropdowns/UserProfileAdmin'
import { useTranslation } from 'react-i18next'

const Header = ({ setSidebarOpen, sidebarOpen , setShow , setproperty , setType  }) => {

    const { t } = useTranslation()
    return (
        <>
            <header className="fixed top-0 z-50 lg:w-full md:w-full w-full bg-white shadow-sm border-b border-slate-200 ">
                <div className="px-4 sm:px-6 lg:px-8">

                    {/* Header: Left side */}
                    <div>
                        <div className='lg:mx-[3rem] lg:w-auto w-full flex justify-between items-center'>
                            {/* Hamburger button */}
                            <button
                                className="text-slate-500 hover:text-slate-600 lg:hidden"
                                aria-controls="sidebar"
                                aria-expanded={sidebarOpen}
                                onClick={() => setSidebarOpen(!sidebarOpen)}
                            >
                                <span className="sr-only">Open sidebar</span>
                                <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="4" y="5" width="16" height="2" />
                                    <rect x="4" y="11" width="16" height="2" />
                                    <rect x="4" y="17" width="16" height="2" />
                                </svg>
                            </button>
                            <div className='w-full mx-4 '>
                                <ul className='lg:flex w-full  items-center cursor-pointer  font-semibold'>
                                    <li className='w-[90px]'>
                                        <Link to="/">
                                            <img src={logo} className=" w-[90px] object-contain " alt="logo" />
                                        </Link>
                                    </li>
                                    <li className=' px-5 py-5 hidden lg:block  '> <Link to="/list"> {t('list')} </Link></li>
                                    <li className=' px-5 py-5 hidden lg:block  '> <Link to="/rent"> {t('rent_lbl')}  </Link></li>
                                    <li className=' px-5 py-5 hidden lg:block  '> <Link to="/aboutus"> {t('about_us')} </Link></li>
                                </ul>
                            </div>
                            <div className='w-[70%] '>
                                <ul className='flex cursor-pointer items-center justify-end font-semibold '>
                                    <li>
                                        <MessageDrop setproperty={setproperty} setShow={setShow} setType={setType}/>
                                    </li>

                                    <li className=''>
                                        <MessageDrop type="notification" setproperty={setproperty} setShow={setShow}  setType={setType}/>

                                    </li>
                                    <li className='lg:px-5 '>
                                        <UserProfileAdmin setShow={setShow} setType={setType} />
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>

                    {/* Header: Right side */}
                    <div className="flex items-center space-x-3">

                    </div>

                </div>
            </header>
        </>
    )
}

export default Header