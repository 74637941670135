import React, { useEffect, useState } from 'react'
import PersonalInfo from './PersonalInfo'
import RentInfo from './RentalInfo'
import EmployeInfo from './EmployeInfo'
import IncomInformation from './IncomInfo'
import EmergencyInfo from './EmergencyInfo'
import DebtInfo from './DebtInfo'
import HowItsWork from '../../GenericComp/HowItsWork'
import { AiFillSetting } from 'react-icons/ai'
import SettingPopup from '../../../utils/Popup/SettingPopup'
import Purchase from '../../GenericComp/Purchase'
import ThankYou from './ThankYou'
import { Loader } from '../../../utils/loaderButton'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Autherization from './Autherization'
import { IoMdCheckmark } from "react-icons/io";
import { SendRent } from '../../../Redux/UserAuthSlice/RentalSlice'
import { AddActiveTab, AddFilterTab, AddIndex, AddRentalId, UpdateTab, addRentals, addTabs } from '../../../Redux/UserAuthSlice/CoSignerRentalSlice'
import { CheckRentalApp } from '../../../lib/Api/RentalAppApi'
import DynamicPopup from '../../../utils/Popup/DynamicPopup'
import VerifyRentalApp from './VerifyRentalApp'
import Screening from './Screening'
import { useNavigate, useParams } from 'react-router-dom'

const CoSignerIndex = () => {
    const [loading, setLoading] = useState(false)
    const [verify, setVerify] = useState(true)
    const [pass, setPass] = useState(false)
    const [status, setStatus] = useState({})
    const [activeIndex, setActiveIndex] = useState(0)
    const [clickTab, setClickTab] = useState("")
    const [activeTab, setActiveTab] = useState([1])
    const [isPurchase, setIsPurchase] = useState(false)
    const { active_Index, active_Tab } = useSelector((state) => state?.coSignerRental)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const lists = [t('p_info'), t('r_info'), t('i_info'), t('e_info'), t('d_info'), t('em_info'), t('autherize'), t('screening')]
    const { coSignerId } = useParams();
    const [finalCoSignerId, setFinalCoSignerId] = useState(coSignerId);

    const handleNext = (tab) => {
        setClickTab("")
        if (active_Index > 1) {
            setActiveIndex(activeIndex)
            dispatch(AddIndex(active_Index + 1))
            dispatch(AddActiveTab({ tab: tab, submited: false }))
            setActiveTab((prev) => [...prev, tab + 1]);
        }
        else {
            dispatch(AddIndex(active_Index === 0 ? 1 : active_Index))
        }
    }

    const handleBack = (tab) => {
        setClickTab("")
        let incValue = active_Index - 1
        let check = active_Tab?.find((f) => f.tab === tab - 1)
        if (check?.submited === false) {
            let filterTab = active_Tab.filter((f) => f?.tab !== check.tab)
            dispatch(AddFilterTab(filterTab))
        }
        if (activeTab?.length > 0) {
            const newActiveTab = activeTab.slice(0, -1);
            setActiveTab(newActiveTab);
        }
        setActiveIndex(incValue)
        dispatch(AddIndex(incValue))
    }

    const tabStatus = [
        true,
        status?.personal_information,
        status?.rental_information,
        status?.income_information,
        status?.employment_information,
        status?.debt,
        status?.emergency_contact,
        status?.authorization,
        status?.screening
    ];

    const getRentalApp = async () => {
        try {
            let newFinalCoSignerId = coSignerId;
            if (newFinalCoSignerId === 'new') {
                dispatch(SendRent({ id: 0, type: "coSignerRentalApp" }))
                dispatch(AddIndex(0))
            } else {

                setFinalCoSignerId(newFinalCoSignerId); // Update state
                const response = await CheckRentalApp(newFinalCoSignerId);

                if (response) {
                    setLoading(false)
                    setIsPurchase(true)
                    setStatus(response?.response.status)
                    if (response?.rental_application?.credits > 0) {
                        dispatch(AddIndex(2))
                    }

                    dispatch(addRentals(response?.response.rental_application))
                    dispatch(AddRentalId(response?.response.rental_application?.id))

                    delete response.response.status?.vehicle_information;
                    delete response.response.status?.pet_info;

                    if (Object?.keys(response?.response.status)?.length > 0) {
                        const tabStatus = [
                            "personal_information",
                            "rental_information",
                            "income_information",
                            "employment_information",
                            "debt",
                            "emergency_contact",
                            "authorization",
                            "screening"
                        ];
                        const trueKeys = Object.entries(response?.response.status)
                            .filter(([key, value]) => value === true)
                            .map(([key]) => key);
                        const trueValuesArray = trueKeys.map(key => {
                            const index = tabStatus.indexOf(key);
                            return {
                                tab: index,
                                submited: true
                            };
                        });
                        if (trueValuesArray?.length > 0) {
                            dispatch(addTabs(trueValuesArray))
                            let check = trueValuesArray.find((f) => f.tab === 0)
                            if (!check) {
                                dispatch(AddActiveTab({ tab: 0, submited: false }))
                            }
                        } else {
                            dispatch(AddActiveTab({ tab: 0, submited: false }))
                        }
                    }
                }
            }
        }
        catch (err) { }
    }

    const handleVerify = async () => {
        setVerify(false)
        if (isPurchase === true) {
            dispatch(AddIndex(2))
        } else {
            dispatch(AddIndex(0))
        }
    }

    const handleTab = (index, i) => {
        const allPreviousTabsTrue = tabStatus.slice(0, index).every(status => status === true);
        if (allPreviousTabsTrue) {
            setActiveTab((prev) => {
                const newActiveTabs = Array.from({ length: index + 1 }, (_, i) => i);
                return newActiveTabs;
            });
            setActiveIndex(index);
            setClickTab(`${index}`)
            dispatch(AddIndex(index + 1));
            dispatch(UpdateTab({ tab: i, submited: false }));
        } else {
            // //console.log("Please complete all previous tabs before proceeding.");
        }
    };

    const handleMove = () => {
        navigate('/dashboard')
    }

    useEffect(() => {
        getRentalApp();
    }, [])

    if (verify) {
        return (<DynamicPopup modalOpen={verify} data={<VerifyRentalApp handleClose={handleVerify} />}
            close={() => handleMove()}
        />)
    }
    else {


        return (
            <>
                {
                    active_Index === 10 ?
                        <ThankYou handleBack={handleBack} getRentalApp={getRentalApp} handleNext={handleNext} setActiveIndex={setActiveIndex} setActiveTab={setActiveTab} />
                        :
                        <>

                            {pass && <SettingPopup permition={pass} Toggle={setPass} />}
                            {
                                <div>
                                    {active_Index > 1 &&
                                        <div className='my-4'>
                                            {!tabStatus.every(status => status === true) &&
                                                <div className='px-14'>
                                                    <div className='mb-3 bg-red-500 border-red-700 border rounded-md text-black text-[18px] p-2'>
                                                        <span className='text-red-900 font-bold'>{t('rental_app_warning')}:</span> {t('check_rental_App')}
                                                    </div>
                                                </div>
                                            }
                                            <div className="flex justify-center items-center">
                                                <h2 className='text-[18px] font-semibold'>{t('co_signer_app')}</h2>
                                            </div>
                                        </div>
                                    }

                                    <div className='flex flex-wrap justify-center items-center  lg:px-14 px-1 lg:mb-12 p-8 mb-5'>
                                        {
                                            active_Index > 1 &&
                                            lists.map((_, i) => {
                                                let checkTab = active_Tab.find((s) => s.tab === i);
                                                let checkClick = clickTab === ""
                                                    ? activeTab[i] === i + 1  // When clickTab is not null, check if it's the clicked index
                                                    : i < activeTab?.length - 1 ; // If clickTab is null, fallback to activeTab condition

                                                return (
                                                    <React.Fragment key={`fragment-${i}`}>
                                                        {
                                                            checkTab && checkTab?.submited === true ? (
                                                                <div className={`${checkClick ? 'bg-[#F79F15]' : 'bg-gray-100'} md:w-[8%] md:-ml-5 first:hidden h-1 lg:w-[8%] w-[8%]`} key={`line-${i}`}></div>
                                                            ) : (
                                                                <div className={`${checkTab && checkTab?.submited === false ? 'bg-[#F79F15]' : 'bg-gray-100'} first:hidden md:w-[8%] md:-ml-5 h-1 lg:w-[8%] w-[8%]`} key={`line-${i}`}></div>
                                                            )
                                                        }

                                                        <div className='lg:w-[4%] w-[8%] text-center relative' key={`circle-${i}`}>
                                                            {checkTab && checkTab?.submited === true ? (
                                                                <div onClick={() => handleTab(i + 1, i)} className={` ${activeTab.some((s) => s === i + 1) ? 'bg-[#F79F15] text-white' : 'bg-white text-black'} cursor-pointer text-sm w-[40px] h-[40px] rounded-full border flex justify-center items-center font-bold text-[17px]`}>
                                                                    <IoMdCheckmark />
                                                                </div>
                                                            ) : (
                                                                <div onClick={() => handleTab(i + 1, i)} className={`${checkTab && checkTab?.submited === false ? 'rounded-full border bg-[#F79F15] text-white' : "bg-white"} cursor-pointer text-sm w-[40px] h-[40px] rounded-full border flex justify-center  items-center font-bold text-[17px]`}>
                                                                    {i + 1}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            })
                                        }

                                        <div className='py-5 w-full'>
                                            {active_Index > 1 &&
                                                <>
                                                    <div className='flex justify-end'>
                                                        <AiFillSetting className='text-[20px] cursor-pointer' onClick={() => setPass(true)} />
                                                    </div>
                                                </>
                                            }

                                            <div className=' shadow-md p-3 '>
                                                {
                                                    loading ?
                                                        <Loader type="loadData" />
                                                        :
                                                        <>
                                                            {active_Index === 0 && <HowItsWork handleNext={(val) => handleNext(val)} type="co_signer_app" />}
                                                            {active_Index === 1 && <Purchase type="co_signer_app" />}
                                                            {active_Index === 2 && <PersonalInfo handleNext={(val) => handleNext(val)} userid={finalCoSignerId} />}
                                                            {active_Index === 3 && <RentInfo handleBack={(val) => handleBack(val)} handleNext={(val) => handleNext(val)} userid={finalCoSignerId} />}
                                                            {active_Index === 4 && <IncomInformation handleBack={(val) => handleBack(val)} handleNext={(val) => handleNext(val)} userid={finalCoSignerId} />}
                                                            {active_Index === 5 && <EmployeInfo handleBack={(val) => handleBack(val)} handleNext={(val) => handleNext(val)} userid={finalCoSignerId} />}
                                                            {active_Index === 6 && <DebtInfo handleBack={(val) => handleBack(val)} handleNext={(val) => handleNext(val)} userid={finalCoSignerId} />}
                                                            {active_Index === 7 && <EmergencyInfo handleBack={(val) => handleBack(val)} handleNext={(val) => handleNext(val)} userid={finalCoSignerId} />}
                                                            {active_Index === 8 && <Autherization status={status} handleBack={(val) => handleBack(val)} handleNext={(val) => handleNext(val)} userid={finalCoSignerId} />}
                                                            {active_Index === 9 && <Screening status={status} handleBack={(val) => handleBack(val)} handleNext={(val) => handleNext(val)} userid={finalCoSignerId} />}
                                                        </>
                                                }
                                            </div>


                                        </div>

                                    </div >
                                </div>
                            }
                        </>
                }
            </>
        )
    }
}

export default CoSignerIndex