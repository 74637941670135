


import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { signout } from '../../Redux/UserAuthSlice/UserAuthSlice';
import { useTranslation } from 'react-i18next';
// import { logoutUser } from '../../lib/Api/UserApi';
import { Loader } from '../../utils/loaderButton';
import avatar from '../../assests/images/f10.png'
import { AddRommateIndex, IspopUp, setIsInitials, setIsStart, setResetStates, setSelectedCurrent, setUpdatePurchaseRoommate, setUpdateRoommate } from '../../Redux/UserAuthSlice/RommateSlice';
function Sidebar({
    sidebarOpen,
    setSidebarOpen,
    list,
    setType,
    setShow
}) {

    const location = useLocation().pathname;

    const [loading, setLoading] = useState(false)
    const [imageError, setImageError] = useState(false);
    const lng = useSelector((state) => state?.userAuth?.language)

    const handleImageError = () => {
        setImageError(true);
    };

    const trigger = useRef(null);
    const sidebar = useRef(null);

    // const dispatch = useDispatch()

    const handleLogout = async () => {
        setType("logout")
        setShow(true)
        // setLoading(true)
        // let res = await logoutUser()
        // if (res) {
        //     setLoading(false)
        //     dispatch(signout())
        // }
    }
    const user = useSelector((state) => state?.userAuth?.userInfo)


    // const location = useLocation().pathname
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
    const [sidebarExpanded, setSidebarExpanded] = useState(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true');

    const handleNavigate = (item) => {
        if (item?.heading === "rommate_text") {
            if (user?.userRole !== "servicer") {
                dispatch(IspopUp(true))
                dispatch(setResetStates())
                dispatch(setUpdateRoommate(false))
                dispatch(AddRommateIndex(0))
            }
        }
        else if (item?.heading === "r_eazy" ) {
            dispatch(setUpdateRoommate(false))
            dispatch(setResetStates())
            dispatch(setUpdatePurchaseRoommate(false))
            dispatch(setSelectedCurrent("renter"))
        } else if (item?.heading === "flash_lane") {
            dispatch(setUpdateRoommate(false))
            dispatch(setIsStart(true))
            dispatch(setIsInitials(true))
            dispatch(setUpdatePurchaseRoommate(false))
            dispatch(setResetStates())
        }
        setSidebarOpen(false)
        navigate(item.link)
    }

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!sidebarOpen || keyCode !== 27) return;
            setSidebarOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    useEffect(() => {
        let expand = localStorage.setItem('sidebar-expanded', sidebarOpen);
        if (sidebarExpanded) {
            document.querySelector('body').classList.add('sidebar-expanded');
        } else {
            document.querySelector('body').classList.remove('sidebar-expanded');
        }
    }, [sidebarOpen]);

    const { t } = useTranslation()


    return (
        <div>
            {/* Sidebar backdrop (mobile only) */}
            <div className={`fixed inset-0  bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200  ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} aria-hidden="true" ></div>

            {/* Sidebar */}
            <div id="sidebar" ref={sidebar} className={`flex  flex-col absolute z-40 ${lng === "sp" ? '-left-8' : 'left-0'} left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0  ${location === '/flash-pass' ? 'min-h-[100vh]' : location === "/rental/rental_property" ? 'min-h-[100vh]' : 'min-h-[100vh]'} no-scrollbar  lg:w-full shrink-0 bg-[#fcf8f4] transition-all duration-200 ease-in-out ${sidebarOpen ? ` ${lng === "sp" ? 'translate-x-5' : 'translate-x-0'} ` : '-translate-x-64'}`} >
                {/* Sidebar header */}
                <div className="flex justify-between mb-5 pr-3 sm:px-2">
                    {/* Close button */}
                    <button ref={trigger} className="lg:hidden mt-5 mx-5 border rounded-full text-slate-500 hover:text-slate-400" onClick={() => setSidebarOpen(!sidebarOpen)} aria-controls="sidebar" aria-expanded={sidebarOpen}  >
                        <span className="sr-only">Close sidebar</span>
                        <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
                        </svg>
                    </button>
                    {/* Logo */}

                </div>

                {/* Links */}
                <div className="space-y-10 ">
                    <ul className='pt-[2rem]'>
                        {
                            list.filter((f) => f.heading !== "logout" && f.heading !== "go_home" && f.heading !== "go_dashboard").map((item, i) => (

                                <div onClick={() => handleNavigate(item)}>
                                    <li className={`${location === item.link && 'bg-[#F7E6CB] border-l-4 border-[#F79F15] bg-opacity-[0.5]'} w-full flex cursor-pointer justify-center items-center py-4 `} key={i}>
                                        <div className='flex items-start justify-start w-full px-8'>
                                            {item.heading === "profile" ?
                                                <img
                                                    src={imageError ? avatar : user?.userimg}
                                                    onError={handleImageError}
                                                    alt="heart_image" className={'rounded-full border  h-8 w-8'} />
                                                :
                                                <img
                                                    src={item.image}
                                                    alt="heart_image" className={`${item.heading === "profile" && 'rounded-full border h-8'} w-8 `} />
                                            }

                                            <h2 className='mx-2 max-w-[200px] font-semibold'>{t(`${item.heading}`)}</h2>
                                        </div>
                                    </li>
                                </div>
                            ))
                        }



                    </ul>
                    <div className='flex justify-center '>
                        <button
                            onClick={() => handleLogout()}
                            className='px-[5rem] py-2 bg-[#F63030] text-white rounded-md'  >{loading ? <Loader type="loadData" color="color" /> : t('logout')}</button>
                    </div>

                </div>


            </div>


        </div>
    );
}

export default Sidebar;
