import React, { useState, useEffect } from 'react'
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { CreateAppInfo } from '../../../lib/Api/RentalAppApi';
import { Loader } from '../../../utils/loaderButton';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import DynamicPopup from '../../../utils/Popup/DynamicPopup';
import PhoneVerify from '../../../utils/Popup/PhoneVerify';
import { generateCap } from '../../../utils/PhoneCaptcGene';
import { useTranslation } from 'react-i18next';
import { BsPlus, BsTrash } from 'react-icons/bs'
import moment from 'moment';
import { compareObjects } from '../../../hooks/ValidateRentalApp';
import { useDispatch, useSelector } from 'react-redux';
import { addRentals } from '../../../Redux/UserAuthSlice/CoSignerRentalSlice';
import { isOver18 } from '../../../utils/CheckDateOfBirth';
import { toast } from 'react-toastify';

const Information = {
  first_name: "",
  last_name: "",
  // ssn: "",
  isAge: false,
  dob: "",
  relationship: "",
  phone: "",
  age: "",
}

const PersonalInfo = ({ handleNext, userid }) => {
  const [loading, setLoading] = useState(false)
  const [verify, setVerify] = useState(false)
  const [result, setResult] = useState("");
  const [type, setType] = useState("");
  const [formData, setFormData] = useState({})
  const [SavedData, setSavedData] = useState({})
  const [rentalApp, setRentalApp] = useState({})
  const { rentalData } = useSelector((state) => state?.coSignerRental)
  let rentalApps = rentalData?.personal_info

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const schema = yup.object({
    dependents: yup.array().of(
      yup.object().shape({
        first_name: yup.string(),
        last_name: yup.string(),
        dob: yup.string(),
        // ssn: yup.string().nullable(),
        relationship: yup.string().nullable(),
        phone: yup.string().nullable(),
        isAge: yup.string().nullable(),
        // age: yup.string().nullable(),
      })),
    first_name: yup.string().required(t('invalid_fname')),
    last_name: yup.string().required(t('invalid_lname')),
    date_of_birth: yup.string().required(t('invalid_db')),
    // social_security_number: yup.string().nullable().required(t('social_sc')),
    marital_status: yup.string().nullable().required(t('mrt_s')),
    phone: yup.string().nullable().required(t('phone_no')),
    // license_number: yup.nullable(),
    license_number: yup.string().nullable(),
  });

  const { register, reset, control, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema), defaultValues: { dependents: [] }, shouldFocusError: false, });
  const { fields, append, remove, } = useFieldArray({
    control,
    name: 'dependents'
  });

  const [isAgeChecked, setIsAgeChecked] = useState(Array(fields.length).fill(false));

  const GenetrateCap = async (data) => {
    setTimeout(() => {
      setVerify(false)
      setType("")
    }, 2000);
    const response = await generateCap(`+${data?.phone}`, setVerify);
    if (response) {
      setResult(response);
      // setVerify()
    }
  }

  const CloseVerify = async () => {
    setVerify(false)
    try {
      SavedData.id = userid
      SavedData.co_signer_id = userid
      setLoading(true)
      let { res } = await CreateAppInfo(SavedData)
      if (res) {
        setLoading(false)
        handleNext(1)
      }
    }
    catch (err) {
    }
  }

  const OnSubmit = async (data) => {
    data.co_signer_id = userid ?? null;
    setSavedData(data)
    // const areEqual = Object.keys(data).every(key => data[key] === formData[key]);
    const isOver18Check = isOver18(data?.date_of_birth);
    let check = !compareObjects(formData, data)
    if (check) {
      if (isOver18Check === false) {
        toast.error(t('18_year'))
      }
      else if (data?.phone !== formData?.phone) {
        setType("loader")
        setVerify(true)
        GenetrateCap(data)
      }
      else {
        try {
          data.id = userid
          if (!data.dependents || data.dependents.length === 0) {
            delete data.dependents;
          }
          data.dependents?.map((d) => {
            if (d?.dob === "Invalid date" || d?.isAge === "true") {
              return d.dob = ""
            }
            return d
          })
          setLoading(true)
          let { res } = await CreateAppInfo(data)
          if (res) {
            dispatch(addRentals(res[0]?.rental_app))
            setLoading(false)
            handleNext(1)
          }
          else {
            setLoading(false)
          }
        }
        catch (err) {
          setLoading(false)

        }
      }
    }
    else {
      const isOver18Check = isOver18(data?.date_of_birth);
      if (isOver18Check === false) {
        toast.error(t('18_year'))
      } else {
        handleNext(1)
      }
    }
  }


  const handleDel = (indexToRemove) => {
    remove(indexToRemove)
    const updatedDependents = formData.dependents.filter((_, index) => index !== indexToRemove);
    const updatedFormData = { ...formData, dependents: updatedDependents };

    if (updatedFormData.dependents.length === 0) {
      delete updatedFormData.dependents;
    }

    setFormData(updatedFormData);
  }



  useEffect(() => {
    setRentalApp(rentalApps)
    if (rentalApps) {
      // let parts = rentalApp?.date_of_birth?.split("/");
      let reversedDateStr = moment(rentalApps?.date_of_birth).format('YYYY-MM-DD')
      let depend = rentalApps?.dependents?.map(app => ({
        first_name: app?.first_name,
        last_name: app?.last_name,
        dob: moment(app?.dob).format('YYYY-MM-DD'),
        ssn: app?.ssn,
        phone: app?.phone,
        relationship: app?.relationship
      }))
      let payload = {
        first_name: rentalApps?.first_name,
        last_name: rentalApps?.last_name,
        date_of_birth: reversedDateStr,
        // social_security_number: rentalApps?.social_security_number,
        marital_status: rentalApps?.marital_status,
        phone: rentalApps.phone_number,
        license_number: rentalApps?.license_number
      }
      let secondpayload = {
        dependents: depend,
        ...payload
      }
      setFormData(rentalApps?.dependents?.length > 0 ? secondpayload : payload)
      reset(rentalApps?.dependents?.length > 0 ? secondpayload : payload)
    }
  }, [reset, rentalApps])

  useEffect(() => {
    const updatedCheckedState = [...isAgeChecked];

    fields.forEach((field, index) => {
      if (field.dob === "Invalid date") {
        updatedCheckedState[index] = true; // If DOB is empty, set checkbox to true
      }
    });
    setIsAgeChecked(updatedCheckedState); // Update checkbox state array
  }, [fields]);

  return (
    <>
      {
        verify && <DynamicPopup modalOpen={verify} close={() => setVerify(false)} data={<PhoneVerify close={() => CloseVerify()} result={result} setVerify={setVerify} type={type} />} />
      }
      <form onSubmit={handleSubmit(OnSubmit)}>
        <h2 className='text-[20px] font-bold text-center p-8'>{t('p_info')}</h2>

        <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 xl:gap-20 md:gap-20 lg:gap-20'>
          <div className='flex flex-col mb-4'>
            <label className='mb-2 text-[16px] font-semibold'>{t('contact_first_name')}<span className='text-red-500'>*</span></label>
            <input type="text" placeholder={t('contact_first_name')}  {...register('first_name')} className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
            {errors.first_name && (
              <p className="text-red-500 text-sm ">{errors.first_name.message}</p>
            )}
          </div>
          <div className='flex flex-col mb-4'>
            <label className='mb-2 text-[16px] font-semibold'>{t('contact_last_name')}<span className='text-red-500'>*</span></label>
            <input type="text" placeholder={t('contact_last_name')}    {...register('last_name')} className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
            {errors.last_name && (
              <p className="text-red-500 text-sm ">{errors.last_name.message}</p>
            )}
          </div>
        </div>
        <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 xl:gap-20 md:gap-20 lg:gap-20'>
          <div className='flex flex-col mb-4'>
            <label className='mb-2 text-[16px] font-semibold'>{t('db_lbl')}<span className='text-red-500'>*</span></label>
            <input type="date" {...register('date_of_birth')} pattern="\d{1,2}/\d{1,2}/\d{4}" className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
            {errors.date_of_birth && (
              <p className="text-red-500 text-sm ">{errors.date_of_birth.message}</p>
            )}
          </div>
          <div className='flex flex-col mt-2'>
            <label className=' text-[16px] font-semibold'>{t('matiral_S')}<span className='text-red-500'>*</span></label>
            <select {...register('marital_status')} className='w-full focus:outline-none p-2 mt-2 rounded-md bg-[#F5F6FC]'>
              <option value="">{t('please_select')}</option>
              <option value="Single">{t('single')}</option>
              <option value="Married">{t('marid')}</option>
              <option value="Widowed">{t('widow')}</option>
              <option value="Divorced ">{t('divoice')}</option>
            </select>
            {errors.marital_status && (
              <p className="text-red-500 text-sm ">{errors.marital_status.message}</p>
            )}
          </div>
          {/* <div className='flex flex-col mb-4'>
            <label className='mb-2 text-[16px] font-semibold'>{t('ssn')} </label>
            <Controller
              name="social_security_number"
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none'
                  placeholder="xxx-xx-xxxx"
                  maxLength="11" // SSN format allows for 11 characters
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, ''); // Remove all non-numeric characters
                    const formattedValue = value.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3'); // Format to XXX-XX-XXXX
                    field.onChange(formattedValue);
                  }}
                />
              )}
            />
            {errors.social_security_number && (
              <p className="text-red-500 text-sm ">{errors.social_security_number.message}</p>
            )}
          </div> */}
        </div>

        <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 xl:gap-20 md:gap-20 lg:gap-20  mb-4'>

          <div className='flex flex-col mt-2'>
            <label className='mb-2 text-[16px] font-semibold'>{t('phone_lbl')}<span className='text-red-500'>*</span></label>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  country={'us'}
                />
              )}
            />
            {errors.phone && (
              <p className="text-red-500 text-sm ">{errors.phone.message}</p>
            )}
          </div>
          <div className='mt-2 mb-4'>
            <label className='mb-2 text-[16px] font-semibold '>{t('licenc_lbl')}
              {/* <span className='text-red-500'>*</span> */}
              </label>
            <input type="text" {...register('license_number')} placeholder={t('licenc_lbl')} className='w-full mt-2 p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
            {errors.license_number && (
              <p className="text-red-500 text-sm ">{errors.license_number.message}</p>
            )}
          </div>
        </div>
        <div id="recaptcha-container"
          data-sitekey="6LcsaxsdAAAAAEBn0sPDCEncnU9564MisyRuDzD_"
          data-callback="sendForm"
          data-size="normal">
        </div>

        <div className='flex justify-between items-center'>
          <div className='flex gap-4'>
            <h1 className='font-bold text-[18px]'>{t('add_perspnal')}</h1>
            <div className={` ${formData?.dependents?.length > 0 || fields?.length > 0 ? 'hidden' : 'flex'}  `}>
              <button onClick={() => append(Information)} type='button' className='px-3 py-2 bg-green-500 rounded-md text-white font-semibold '>
                <BsPlus />
              </button>
            </div>
          </div>
          <div className={` ${formData?.dependents?.length > 0 || fields?.length > 0 ? 'flex' : 'hidden'}  justify-end my-4`}>
            <button onClick={() => handleDel(0)} type='button' className=' px-3 py-2 bg-red-500 rounded-md text-white font-semibold '>
              <BsTrash />
            </button>
          </div>
        </div>

        <div className={`${formData?.dependents?.length > 0 || fields?.length > 0 ? 'block' : 'hidden'} xl:h-[400px]  lg:h-[400px]  md:h-[400px] h-full xl:overflow-y-auto lg:overflow-y-auto md:overflow-y-auto `}>
          {
            fields.map((field, index) => {
              return (
                <div className='border rounded-md px-4 py-2 my-2 ' key={index}>
                  <div className='flex justify-end'>
                    {
                      index !== 0 &&
                      <button type='button' onClick={() => remove(index)} className=' p-2 bg-red-500 rounded-md '><BsTrash className='text-white' /></button>
                    }
                  </div>
                  <div className='flex items-center gap-2'>
                    <div className="flex items-center justify-start pb-2">
                      <p className='text-[16px] font-semibold'>{t('under_12')}</p>
                      <label className="relative inline-flex items-center cursor-pointer">
                        <Controller
                          name={`dependents[${index}].isAge`}
                          control={control}
                          defaultValue={field.isAge} // Set the initial value of the checkbox
                          render={({ field }) => (
                            <>
                              <input
                                type="checkbox"
                                className="sr-only peer"
                                role="switch"
                                id={`flexSwitchCheckDefault_${index}`}
                                onChange={(e) => {
                                  field.onChange(e.target.checked);
                                  const updatedCheckedState = [...isAgeChecked];
                                  updatedCheckedState[index] = e.target.checked;
                                  setIsAgeChecked(updatedCheckedState); // Update checkbox state array
                                }}
                                checked={isAgeChecked[index]}
                              />
                              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#F79F15] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#F79F15]"></div>
                              {/* <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Toggle me</span> */}

                            </>
                          )}
                        />

                      </label>
                    </div>
                  </div>
                  <div className='grid xl:grid-cols-2 lg:grid-cols-2 xl:gap-20 lg:gap-20'>
                    <div className='flex flex-col mb-4'>
                      <label className='mb-2 text-[16px] font-semibold'>{t('contact_first_name')}<span className='text-red-500'>*</span></label>
                      <input type="text" placeholder={t('contact_first_name')}
                        name={`dependents[${index}].first_name`}
                        {...register(`dependents.${index}.first_name`)}
                        defaultValue={field.first_name}
                        className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
                      {errors.dependents && errors.dependents[index] && errors.dependents[index].first_name && (
                        <span className='text-red-500 text-sm '>{errors.dependents[index].first_name.message}</span>
                      )}
                    </div>
                    <div className='flex flex-col mb-4'>
                      <label className='mb-2 text-[16px] font-semibold'>{t('contact_last_name')} <span className='text-red-500'>*</span></label>
                      <input type="text" placeholder={t('contact_last_name')}
                        name={`dependents[${index}].last_name`}
                        {...register(`dependents.${index}.last_name`)}
                        defaultValue={field.last_name}
                        className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
                      {errors.dependents && errors.dependents[index] && errors.dependents[index].last_name && (
                        <span className='text-red-500 text-sm '>{errors.dependents[index].last_name.message}</span>
                      )}
                    </div>
                  </div>

                  <div className='grid xl:grid-cols-2 lg:grid-cols-2 xl:gap-20 lg:gap-20'>
                    {!isAgeChecked[index] &&
                      <div className='flex flex-col mb-4'>
                        <label className='mb-2 text-[16px] font-semibold'>{t('db_lbl')}<span className='text-red-500'>*</span></label>
                        <input type="date"
                          name={`dependents[${index}].dob`}
                          {...register(`dependents.${index}.dob`)}
                          defaultValue={field.dob}
                          pattern="\d{1,2}/\d{1,2}/\d{4}"
                          className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
                        {errors.dependents && errors.dependents[index] && errors.dependents[index].dob && (
                          <span className='text-red-500 text-sm '>{errors.dependents[index].dob.message}</span>
                        )}
                      </div>
                    }
                  </div>
                  <div className='grid xl:grid-cols-2 lg:grid-cols-2 xl:gap-20 lg:gap-20  mb-4'>
                    <div className='flex flex-col mt-2'>
                      <label className=' text-[16px] font-semibold'>{t('relationship')} <span className='text-red-500'>*</span></label>
                      <input type="text"
                        name={`dependents[${index}].relationship`}
                        placeholder={t('relationship')}
                        {...register(`dependents.${index}.relationship`)}
                        defaultValue={field.relationship}
                        className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
                      {errors.dependents && errors.dependents[index] && errors.dependents[index].relationship && (
                        <span className='text-red-500 text-sm '>{errors.dependents[index].relationship.message}</span>
                      )}
                    </div>
                    <div className='flex flex-col mt-2'>
                      <label className='mb-2 text-[16px] font-semibold'>{t('phone_lbl')} <span className='text-red-500'>*</span></label>
                      <Controller
                        name={`dependents[${index}].phone`}
                        control={control}
                        defaultValue={field.phone}
                        render={({ field }) => (
                          <PhoneInput
                            {...field}
                            country={'us'}
                          />
                        )}
                      />
                      {errors.dependents && errors.dependents[index] && errors.dependents[index].phone && (
                        <span className='text-red-500 text-sm '>{errors.dependents[index].phone.message}</span>
                      )}
                    </div>

                  </div>
                </div>
              )
            })}
        </div>
        <div className={` ${formData?.dependents?.length > 0 || fields?.length > 0 ? 'flex' : 'hidden'}  justify-end my-4`}>
          <button onClick={() => append(Information)} type='button' className=' px-3 py-2 bg-green-500 rounded-md text-white font-semibold '>{t('add_m_lbl')}</button>
        </div>
        <div className='flex justify-end items-end '>
          <div className='py-3'>
            <button className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>

              {
                loading ? <Loader type="saveLoder" />
                  :
                  t('save')
              }
            </button>
          </div>
        </div>
      </form >

    </>
  )
}

export default PersonalInfo