import { toast } from "react-toastify"
import { callPrivateApi, callPublicApi } from "../Apiendpoint"


// Get Payment card
export const GetCards = async () => {
    try {
        let response = await callPrivateApi('/user/payments/get-cards', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// Get payment history
export const GetPaymentHistory = async () => {
    try {
        let response = await callPrivateApi('/user/payments/transactions', 'get')
        if (response.success === true) {
            return response.data

        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}






// Get Property
export const GetLeaseds = async () => {
    try {
        let response = await callPrivateApi('/renters/lease-builder/get-all', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Create Personal Info
export const CreatePersonalInfo = async (data, type) => {
    // let { property_id, leased_type,  animal_allowed , parking_allowed , legal_disclosure , additional_details } = data

    const list = ["app-info", "rental-info", "vehicle-info", "employer-info", "income-info", "debt-info", "emergency-contact"]

    try {

        let response = await callPrivateApi(`/renters/rental-application/${list[type]}`, 'post', data)
        if (response.success === true) {

            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Rental Update
export const UpdateLeased = async (data) => {
    // let { type, realtor, name, email, license, broker, latitude, longitude, street, city, state, zipcode, rent, security_deposit, propertyType, leased_period, number_of_beds, number_of_baths, amenities, description, images, video } = data
    try {
        let payload = {
            "property_id": 18,
            "leased_type": "monthly",
            "rent_collection_date": "0",
            "animal_allowed": "2 Cats, 3 Dogs, this is a textarea (optional)",
            "parking_allowed": "this is a textarea (optional)",
            "legal_disclosure": "this is a textarea (optional)",
            "additional_details": "this is a textarea (optional)"
        }

        let response = await callPrivateApi('/servicer/lease-builder/update', 'post', payload)
        if (response.success === true) {

            return {
                data: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// deleete
export const DeleteLeased = async (id) => {
    try {

        let response = await callPrivateApi(`/servicer/lease-builder/destroy/${id}`, 'post')
        if (response.success === true) {

            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


//     Payment Method    //

export const RegisterCard = async (data) => {

    try {

        let response = await callPrivateApi(`/user/payments/add-card`, 'post', data)
        if (response.success === true) {

            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}




// FILTER PROPERTY

// Get property Type
export const GetPropertyType = async () => {

    try {
        let response = await callPrivateApi('/renters/properties/property-types', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Get property Type
export const GetPublicPropertyType = async (lang) => {

    try {
        let response = await callPublicApi(`/website/properties/property-types?lang=${lang}`, 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Get property Amanities
export const GetPropertyAmenities = async () => {

    try {
        let response = await callPrivateApi('/renters/properties/get-property-amenities', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Get property Amanities
export const GetPublicPropertyAmenities = async (lang) => {

    try {
        let response = await callPublicApi(`/website/properties/get-property-amenities?lang=${lang}`, 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Get Leased peroid
export const GetLeasedPeriod = async () => {

    try {
        let response = await callPrivateApi('/user/get-lease-periods', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }

}
// Get Leased peroid
export const GetPublicLeasedPeriod = async (lang) => {

    try {
        let response = await callPublicApi(`/website/properties/get-lease-periods?lang=${lang}`, 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }

}

// Filter rent property
export const FilterRentProperties = async (data) => {
    let { lists,
        priceRange,
        updateArr,
        beds,
        baths,
        updateLeased, lat, lng, state, city, zipcode, street, radius } = data

    let payload = {
        "lat": `${lat}`,
        "lng": `${lng}`,
        "radius": radius,
        "state": state,
        "city": city,
        "street": street,
        "beds": beds !== null ? beds : 1,
        "baths": baths !== null ? baths : 1,
        "zipcode": zipcode,
        "lease_period_id": updateLeased,
        "property_type_id": updateArr,
        "max_price": priceRange,
        "amenities": lists
    }

//   let payload= {
//             "lat": "42.3555076",
//             "lng": "-71.0565364",
//             "radius": 30,
//             "state": "MA",
//             "city": "Boston",
//             "street": " ",
//             "beds": [
//                 0,
//                 1,
//                 2,
//                 3,
//                 4,
//                 5,
//                 6,
//                 7
//             ],
//             "baths": [
//                 0,
//                 1,
//                 2,
//                 3,
//                 4,
//                 5,
//                 6,
//                 7
//             ],
//             "zipcode": "",
//             "lease_period_id ": 1,
//             "property_type_id": [
//                 2,
//                 3,
//                 4,
//                 5,
//                 6,
//                 7,
//                 362,
//                 364
//             ],
//             "max_price": 20000,
//             "amenities": [
//                 1,
//                 430,
//                 431,
//                 432,
//                 434,
//                 435,
//                 436,
//                 437,
//                 439,
//                 440,
//                 441,
//                 442,
//                 443,
//                 444,
//                 445,
//                 446,
//                 447,
//                 448,
//                 449,
//                 450,
//                 451,
//                 452,
//                 454,
//                 455,
//                 456,
//                 457,
//                 458,
//                 459,
//                 460,
//                 461,
//                 481,
//                 482,
//                 483,
//                 484,
//                 485,
//                 486,
//                 487,
//                 488,
//                 491,
//                 496,
//                 502,
//                 503,
//                 516,
//                 518,
//                 531,
//                 532,
//                 534,
//                 535,
//                 544,
//                 616,
//                 622,
//                 623,
//                 641,
//                 711
//             ]
//         }
   
//   let payload= {
//             "lat": "42.3555076",
//             "lng": "-71.0565364",
//             "radius": 30,
//             "state": "MA",
//             "city": "Boston",
//             "street": " ",
//             "beds": [
//                 0,
//                 1,
//                 2,
//                 3,
//                 4,
//                 5,
//                 6,
//                 7
//             ],
//             "baths": [
//                 0,
//                 1,
//                 2,
//                 3,
//                 4,
//                 5,
//                 6,
//                 7
//             ],
//             "zipcode": "",
//             "lease_period_id ": 1,
//             "property_type_id": [
//                 2,
//                 3,
//                 4,
//                 5,
//                 6,
//                 7,
//                 362,
//                 364
//             ],
//             "max_price": 20000,
//             "amenities": [
//                 1,
//                 430,
//                 431,
//                 432,
//                 434,
//                 435,
//                 436,
//                 437,
//                 439,
//                 440,
//                 441,
//                 442,
//                 443,
//                 444,
//                 445,
//                 446,
//                 447,
//                 448,
//                 449,
//                 450,
//                 451,
//                 452,
//                 454,
//                 455,
//                 456,
//                 457,
//                 458,
//                 459,
//                 460,
//                 461,
//                 481,
//                 482,
//                 483,
//                 484,
//                 485,
//                 486,
//                 487,
//                 488,
//                 491,
//                 496,
//                 502,
//                 503,
//                 516,
//                 518,
//                 531,
//                 532,
//                 534,
//                 535,
//                 544,
//                 616,
//                 622,
//                 623,
//                 641,
//                 711
//             ]
//         }

        try {
        let response = await callPrivateApi('/renters/properties/filter', 'post', payload)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Filter rent property
export const FilterPublicRentProperties = async (data) => {
    let { aminities,
        priceRange,
        updateArr,
        beds,
        baths,
        updateLeased, lat, lng, state, city, zipcode, street, radius } = data

    let payload = {
        "lat": `${lat}`,
        "lng": `${lng}`,
        "radius": radius,
        "state": state,
        "city": city,
        "street": street,
        "beds": beds !== null ? beds : 1,
        "baths": baths !== null ? baths : 1,
        "zipcode": zipcode,
        "lease_period_id": updateLeased,
        "property_type_id": updateArr,
        "max_price": priceRange,
        "amenities": aminities
    }

    try {

        let response = await callPublicApi(`/website/properties/filter`, 'post', payload)
        if (response.success === true) {
            //
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Get all notifications
export const GetNotifications = async (page) => {
    try {
        let response = await callPrivateApi(`/user/get-notifications/paginate?page=${page}`, 'get')
        if (response.success === true) {
            //
            return {
                res: response.data
            }
        }
        else {
            // toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}




// Get all notifications
export const GetNotificationsCount = async (data) => {
    try {
        let response = await callPrivateApi('/user/notifications-count', 'get')
        if (response.success === true) {
            return response.data
        }
        else {
            // toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}




// Filter rent property by Pagination
export const GetPaginationRentProp = async (filterInfo , nexturl  ) => {
    try {
        let { lists,
            priceRange,
            updateArr,
            beds,
            baths,
            updateLeased, lat, lng, state, city, zipcode, street, radius } = filterInfo

        let payload = {
            "lat": `${lat}`,
            "lng": `${lng}`,
            "radius": radius,
            "state": state,
            "city": city,
            "street": street,
            "beds": beds !== null ? beds : 1,
            "baths": baths !== null ? baths : 1,
            "zipcode": zipcode,
            "lease_period_id": updateLeased,
            "property_type_id": updateArr,
            "max_price": priceRange,
            "amenities": lists
        }
        let response = await callPrivateApi(`/renters/properties/filter?page=${nexturl}`, 'post' , payload )
        if (response.success === true) {
            //
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Get  peroid by id
export const GetTimeSlot = async (data) => {
    try {
        let response = await callPrivateApi('/renters/appointments/get-time-slots', 'post', data)
        if (response.success === true) {
            //
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Sedual appointments
export const SechedualAppointment = async (data) => {
    // let { property_id,
    //     appointment_type,
    //     date_of_appointment,
    //     time_of_appointment, } = data

    // let payload = {
    //     "property_id": property_id,
    //     "appointment_type": appointment_type,
    //     "date_of_appointment": date_of_appointment,
    //     "time_of_appointment": time_of_appointment,
    // }

    try {

        let response = await callPrivateApi('/renters/appointments/schedule', 'post', data)
        if (response.success === true) {
            toast.success(response?.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Sedual appointments save for later
export const SaveForLater = async (data) => {
    try {
        let response = await callPrivateApi('/renters/lists/save', 'post', data)
        if (response.success === true) {

            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}


//  post Appointment request lease
export const RequestLeasePostAppointment = async (data) => {
    try {
        let response = await callPrivateApi('/renters/post-appointment/request-lease', 'post', data)
        if (response.success === true) {

            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Sedual appointments save for later
export const SubmitRentalAppointment = async (data) => {
    try {
        let response = await callPrivateApi('/renters/rental-application/send', 'post', data)
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Sedual appointments save for later
export const ConfirmReporting = async (data) => {
    try {

        let response = await callPrivateApi('/renters/properties/flag', 'post', data)
        if (response.success === true) {

            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// Sedual appointments save for later
export const RenterBlockUser = async (data) => {
    try {

        let response = await callPrivateApi('/user/blocked-users/add', 'post', data)
        if (response.success === true) {

            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Get  peroid by id 
export const GetPropertyById = async (id) => {

    try {
        let response = await callPrivateApi(`/renters/properties/${id}`, 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }

}

// Renteal Application Apis ///
export const GetBillingDetail = async () => {
    try {
        let response = await callPrivateApi('/renters/rental-application/billing-details', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Renteal Application Apis ///

export const GetSessionCheckout = async (link, isCoSigner = false) => {
    try {
        let payload = {
            success_url: `${link}/success`,
            is_co_signer: isCoSigner
        }
        let response = await callPrivateApi('/user/payments/checkout-session', 'post', payload)
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
export const GetPyamentIntent = async () => {
    try {
        let response = await callPrivateApi('/user/payments/checkout-session', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// ----------------Rental Information ----------------------------//
// check rental app is created or not
export const CheckRentalApp = async (coSignerId = null) => {
    try {
        let path = `/renters/rental-application/my-rental-app${coSignerId ? `?co_signer_id=${coSignerId}` : ''}`;
        let response = await callPrivateApi(path, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response?.data)
            return {
                response: obj
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

export const CheckCoSignerApp = async () => {
    try {
        let response = await callPrivateApi('/renters/rental-application/my-co-signer', 'get')
        if (response.success === true) {
            let obj = response?.data || []
            return {
                response: obj
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

export const DeleteCoSignerApp = async (id) => {
    try {
        let response = await callPrivateApi(`/renters/rental-application/co-signer/${id}`, 'delete')
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)
    }
}

export const CreateNewCoSignerApp = async () => {
    let payload = {
        "payment_intent": "pi_3NRDcDEqK3iozBJU1ReWJxxr"
    }

    try {
        let response = await callPrivateApi(`/renters/rental-application/co-signer`, 'post', payload)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Create Renatal App
export const CreateRentalApp = async () => {

    let payload = {
        "payment_intent": "pi_3NRDcDEqK3iozBJU1ReWJxxr"
    }

    try {
        let response = await callPrivateApi(`/renters/rental-application/create`, 'post', payload)
        if (response.success === true) {
            //
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Create Rental Password
export const CreateRentalPassword = async (data) => {
    try {
        let response = await callPrivateApi(`/renters/rental-application/add-password`, 'post', data)
        if (response.success === true) {
            //
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Create Rental app-info
export const CreateAppInfo = async (data) => {
    const { userid, first_name, last_name, date_of_birth, social_security_number, marital_status, phone, license_number, dependents, co_signer_id } = data
    let parts = date_of_birth.split("-");
    let reversedDateStr = parts[1] + "/" + parts[2] + "/" + parts[0];
    let payload = {
        "user_id": userid,
        "first_name": first_name,
        "last_name": last_name,
        "date_of_birth": reversedDateStr,
        "social_security_number": social_security_number,
        "marital_status": marital_status,
        "phone": phone,
        "license_number": license_number,
        "co_signer_id": co_signer_id ?? null,
    }
    // Check if dependents array exists and is not empty
    if (dependents && dependents.length > 0) {
        // Check if all fields in any dependency are not empty
        const allFieldsFilled = dependents.some(dependent => {
            return Object.values(dependent).some(value => value !== "");
        });

        // If any field in any dependency is filled, include dependents in payload
        if (allFieldsFilled) {
            payload.dependents = dependents;
        }
    }

    try {
        let response = await callPrivateApi(`/renters/rental-application/app-info`, 'post', payload)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Create Rental rental-info
export const CreateRentalInfo = async (data) => {

    try {
        let response = await callPrivateApi(`/renters/rental-application/rental-info`, 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Create Pet Information
export const CreatePetInformation = async (data) => {

    try {
        let response = await callPrivateApi(`/renters/rental-application/pet-info/store`, 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Create Rental vehicle-info
export const CreateVehicleInfo = async (data) => {
    try {
        let response = await callPrivateApi(`/renters/rental-application/vehicle-info`, 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Create Rental employer info
export const CreateEmployerInfo = async (data) => {

    try {
        let response = await callPrivateApi(`/renters/rental-application/employer-info`, 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Create Rental income info
export const CreateIncomeInfo = async (data) => {
    try {
        let response = await callPrivateApi(`/renters/rental-application/income-info`, 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Update Rental income info
export const UpdateIncomeInfo = async (data, id) => {
    try {
        let response = await callPrivateApi(`/renters/rental-application/income-info/${id}`, 'put', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Create Rental debt info
export const CreateDebtInfo = async (data) => {
    try {
        let response = await callPrivateApi(`/renters/rental-application/debt-info`, 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Create Rental Emergancy Contect
export const CreateEmergancyContect = async (data) => {


    try {
        let response = await callPrivateApi(`/renters/rental-application/emergency-contact`, 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Autherize Sign
export const AddAutherizeSign = async (data) => {
    try {
        let response = await callPrivateApi(`/renters/rental-application/authorize`, 'post', data)
        if (response.success === true) {
            let obj = Object.assign({}, ...response?.data)
            return {
                res: obj
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err.message)

    }
}
// Screening Check
export const CheckScreensing = async () => {
    try {
        let response = await callPrivateApi(`/renters/rental-application/crs/get-report`, 'get')
        if (response.success === true) {
            return response.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) { }
}

// Screening Check
export const LoardScreeningReport = async (name, coSignerId) => {
    try {
        let url = `/renters/rental-application/crs/get-report/${name}?co_signer_id=${coSignerId}`
        let response = await callPrivateApi(url, 'get')
        if (response.success === true) {
            return response.data

        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) { }
}
// Create Screening Check
export const CreateScreensing = async (data) => {
    try {
        let response = await callPrivateApi(`/renters/rental-application/crs/generate-report`, 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.message
            }
        }
    }
    catch (err) { }
}




// ------- Get My lists  --------

// Get saved
export const GetSavedList = async (location) => {
    const { latitude = 0, longitude = 0 } = location || {};
    let url = '/renters/lists/get/saved';
    if (latitude !== 0 && longitude !== 0) {
        url += `?lat=${latitude}&lng=${longitude}`;
    }

    try {
        let response = await callPrivateApi(url, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                resSaved: obj?.my_list
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Remove saved
export const RemoveSavedList = async (data) => {
    try {

        let response = await callPrivateApi('/renters/lists/save', 'post', data)
        if (response.success === true) {

            // let obj = Object.assign({}, ...response.data)
            return {
                resSaved: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Get Books
export const GetBookedList = async (location) => {
    const { latitude = 0, longitude = 0 } = location || {};
    let url = '/renters/lists/get/booked';
    if (latitude !== 0 && longitude !== 0) {
        url += `?lat=${latitude}&lng=${longitude}`;
    }

    try {
        let response = await callPrivateApi(url, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                resBook: obj?.my_list
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Remove saved
export const CancelBookList = async (data) => {
    try {

        let response = await callPrivateApi('/renters/appointments/cancel/renter', 'post', data)
        if (response.success === true) {
            return response
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }

}
// Get penddings
export const GetPenddingList = async (location) => {
    const { latitude = 0, longitude = 0 } = location || {};
    let url = '/renters/lists/get/pending';
    if (latitude !== 0 && longitude !== 0) {
        url += `?lat=${latitude}&lng=${longitude}`;
    }

    try {
        let response = await callPrivateApi(url, 'get')
        if (response.success === true) {
            const obj = Object.assign({}, ...response.data)
            return {
                res: obj?.my_list
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// pendding signlease
export const PenddingSendRent = async (data) => {
    try {
        let response = await callPrivateApi('/renters/rental-application/send', 'post', data)
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}



// Remove saved
export const SignLease = async (data) => {
    try {

        let response = await callPrivateApi('/renters/property-lease/sign-lease', 'post', data)
        if (response.success === true) {

            // let obj = Object.assign({}, ...response.data)
            return {
                resSaved: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Get archieved
export const GetArchivedlist = async (location) => {
    const { latitude = 0, longitude = 0 } = location || {};
    let url = '/renters/lists/get/archived';
    if (latitude !== 0 && longitude !== 0) {
        url += `?lat=${latitude}&lng=${longitude}`;
    }

    try {
        let response = await callPrivateApi(url, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                resArchive: obj?.my_list
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Restore archieved
export const RestoreArchived = async (data) => {

    try {
        let response = await callPrivateApi('/renters/lists/save', 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Remove archieved
export const RemoveArchived = async (data) => {

    try {
        let response = await callPrivateApi('/renters/lists/remove', 'post', data)
        if (response.success === true) {
            return { res: response.data }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Get confirmed
export const GetConfirmedList = async (location) => {
    const { latitude = 0, longitude = 0 } = location || {};
    let url = '/renters/lists/get/confirmed';
    if (latitude !== 0 && longitude !== 0) {
        url += `?lat=${latitude}&lng=${longitude}`;
    }

    try {
        let response = await callPrivateApi(url, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                resConfirm: obj?.my_list
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// contanct owner confirmed
export const ConfirmContact = async (data) => {
    try {
        let response = await callPrivateApi(`/renters/post-appointment/request-owner-contact`, 'post', data)
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Confirmed End lease
export const ConfirmedEndease = async (data) => {

    try {
        let response = await callPrivateApi('/renters/post-appointment/end-lease', 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Confirm Decline
export const ConfirmListCancel = async (data) => {
    try {
        let response = await callPrivateApi('/renters/post-appointment/not-interested', 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}



// pendding signlease
export const ConfirmRequestLease = async (data) => {
    try {
        let response = await callPrivateApi('/renters/post-appointment/request-lease', 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// -------endList Api ----- //

//------- Start Flash Api ------ //

// Get flash prices
export const GetPrices = async () => {

    try {
        let response = await callPrivateApi('/renters/subscriptions/flash-lane/prices', 'post')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj?.prices
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Get flash prices
export const GetBillingDetailRenter = async (payload) => {

    try {
        let response = await callPrivateApi('/renters/subscriptions/billing-details', 'post', payload)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Get flash prices
export const GetSubscription = async () => {

    try {
        let response = await callPrivateApi('/user/check-flash-lane-subscription', 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Get flash prices
export const GetSubscriptionbyId = async (id) => {

    try {
        let response = await callPrivateApi(`/user/check-flash-lane-subscription?property_id=${id}`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Get flash prices
export const GetPaymentIntent = async (data) => {
    try {

        let response = await callPrivateApi('/renters/subscriptions/checkout-session', 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Register Subscription

export const RegisterPaymentIntent = async (data) => {
    try {
        let response = await callPrivateApi('/renters/subscriptions/register-subscription', 'post', data)
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) { }
}

// Filter rent property
export const FilterFlashLane = async (data) => {
    let { lists,
        priceRange,
        updateArr,
        beds,
        baths,
        updateLeased, lat, lng, state, city, street } = data
    let payload = {
        "lat": `${lat}`,
        "lng": `${lng}`,
        "state": state,
        "city": city,
        "street": street,
        "beds": beds !== null ? beds : 1,
        "baths": baths !== null ? baths : 1,
        "lease_period_id": updateLeased,
        "property_type_id": updateArr,
        "max_price": priceRange,
        "amenities": lists
    }

    try {

        let response = await callPrivateApi('/renters/properties/flash-lane', 'post', payload)
        if (response.success === true) {
            //
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}


//------- End Flash Api ------ //


//------- Start R-EasyLane Api ------ //

// Get flash prices
export const GetReasySubscription = async () => {

    try {
        let response = await callPrivateApi('/user/check-reazy-lane-subscription', 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

export const RegisterReasyIntent = async (data) => {
    try {
        let response = await callPrivateApi('/renters/subscriptions/register-subscription', 'post', data)
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) { }
}

// register settings

export const GetCityState = async () => {
    try {
        let response = await callPrivateApi('/user/get-cities-updated', 'get')
        if (response) {
            return response
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) { }
}

export const RegReazySettings = async (data) => {
    try {
        let response = await callPrivateApi('/renters/subscriptions/r-eazy-lane/settings', 'post', data)
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) { }
}

export const GetReazySettings = async (data) => {
    try {
        let response = await callPrivateApi('/renters/subscriptions/r-eazy-lane/settings', 'get')
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) { }
}
// Get  peroid by id
export const GetBookingSlot = async () => {
    try {
        let response = await callPrivateApi('/renters/subscriptions/r-eazy-lane/get-blocked-times', 'get')
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Book
export const BookTimeSlot = async (data) => {
    try {
        let response = await callPrivateApi('/renters/subscriptions/r-eazy-lane/block-times', 'post', data)
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Get R-EasyLane prices
export const GetPricesREsy = async () => {

    try {
        let response = await callPrivateApi('/renters/subscriptions/r-eazy-lane/prices', 'post')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj?.prices
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Get flash prices
export const GetResyPaymentIntent = async (data) => {
    try {

        let response = await callPrivateApi('/renters/subscriptions/subscribe', 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Filter rent property
export const FilterReasyLane = async (data) => {
    let { lists,
        priceRange,
        updateArr,
        beds,
        baths,
        updateLeased, lat, lng, state, city, street } = data
    let payload = {
        "lat": `${lat}`,
        "lng": `${lng}`,
        "state": state,
        "city": city,
        "street": street,
        "beds": beds !== null ? beds : 1,
        "baths": baths !== null ? baths : 1,
        "lease_period_id": updateLeased,
        "property_type_id": updateArr,
        "max_price": priceRange,
        "amenities": lists
    }

    try {

        let response = await callPrivateApi('/renters/properties/r-eazy-lane', 'post', payload)
        if (response.success === true) {
            //
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Get time slots
export const GetTimeSlots = async (data) => {
    try {

        let response = await callPrivateApi('/renters/subscriptions/r-eazy-lane/get-blocked-times', 'post', data)
        if (response.success === true) {
            let obj = Object.assign({}, ...response?.data)
            return obj?.blocked_times
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

export const GetRenterTimeSlotes = async (payload) => {
    try {
        let response = await callPrivateApi('/renters/subscriptions/r-eazy-lane/generate-timeslots', 'post', payload)
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}



// Update time slots
export const UpdateTimeSlots = async (data) => {
    try {

        let response = await callPrivateApi('/renters/subscriptions/r-eazy-lane/block-times', 'post', data)
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

//------- End R-EasyLane Api ------ //



// ------------- Rommate apis

// Get rommates
export const GetRommates = async () => {

    try {
        let response = await callPrivateApi('/renters/roommates/roommate', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Get Campus Interest
export const GetCampusInterest = async () => {

    try {
        let response = await callPrivateApi('/renters/roommates/metadata/get-interests', 'get')
        if (response.success === true) {
            return {
                resInterest: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Get Campus Activity
export const GetCampusActivities = async () => {

    try {
        let response = await callPrivateApi('/renters/roommates/metadata/get-extra-curricular-activities', 'get')
        if (response.success === true) {
            return {
                resActivity: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Get Campus Question
export const GetCampusQuestions = async () => {

    try {
        let response = await callPrivateApi('/user/roommates/questions/on_campus', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Get Campus Question
export const GetRommatePriorities = async () => {

    try {
        let response = await callPrivateApi('/renters/roommates/metadata/priorities/get/on_campus', 'get')
        if (response.success === true) {
            return {
                resOn: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Get off Campus Question
export const GetOffRommatePriorities = async () => {

    try {
        let response = await callPrivateApi('/renters/roommates/metadata/priorities/get/off_campus', 'get')
        if (response.success === true) {
            return {
                resOff: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Create Rommate off campus
export const CreateRommateQuestion = async (data) => {
    try {
        let response = await callPrivateApi(`/renters/roommates/metadata/priorities/store`, 'post', data)
        if (response.success === true) {
            return response
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Create Rommate on campus
export const FilterRommateOnCampus = async (data) => {
    try {
        let response = await callPrivateApi(`/renters/roommates/filter`, 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Create Rommate on campus
export const FilterRommateReazyOnCampus = async (data) => {
    try {
        let response = await callPrivateApi(`/renters/roommates/r-eazy-lane`, 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}

export const FilterRommateFlashOnCampus = async (data) => {
    try {
        let response = await callPrivateApi(`/renters/roommates/flash-lane`, 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Create Rommate off campus
export const FilterRommateOffCampus = async (data) => {
    try {
        let response = await callPrivateApi(`/renters/roommates/filter`, 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}



// Not Interested Property
export const NotInterestPropertyRenter = async (data) => {

    try {
        let response = await callPrivateApi(`/renters/post-appointment/not-interested`, 'post', data)
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Get  rommate Detail
export const GetRoommateDetail = async (id) => {
    try {
        let response = await callPrivateApi(`/user/roommates/details/${id}`, 'get')
        if (response.success === true) {
            //
            return response.data
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// add fleg rommate
export const FlagRoommate = async (data) => {
    try {
        let response = await callPrivateApi(`/renters/roommates/flag`, 'post', data)
        if (response.success === true) {
            //
            return response.data
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Get  rommate Images
export const GetRommateImages = async () => {
    try {
        let response = await callPrivateApi(`/renters/roommates/images`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return obj.images
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }


}


// Add  rommate Images
export const CreateRommateImages = async (data) => {
    try {
        let response = await callPrivateApi(`/renters/roommates/images/save`, 'post', data)
        if (response.success === true) {
            return response.data
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }


}


// Website api

export const GetWebFlashPrices = async (lng) => {

    try {
        let response = await callPublicApi(`/flash-lane/prices?lang=${lng}`, 'post')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj?.prices
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}


export const GetWebReazyPrices = async (lng) => {

    try {
        let response = await callPublicApi(`/r-eazy-lane/prices?lang=${lng}`, 'post')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj?.prices
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}