import React, { useEffect, useState } from 'react'
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { CreateRentalInfo } from '../../../lib/Api/RentalAppApi';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { BsTrash } from 'react-icons/bs';
import { Loader } from '../../../utils/loaderButton';
import { useTranslation } from 'react-i18next';
import GooglePlacesAutocomplete from "react-google-autocomplete";
import { compareArrays } from '../../../hooks/ValidateRentalApp';
import { addRentals } from '../../../Redux/UserAuthSlice/RentalSlice';
import { API } from '../../../config/GoolePlace';
import moment from 'moment';

const Information = {
    address: "",
    city: "",
    state: "",
    zip_code: "",
    landlord_name: "",
    apt: "",
    landlord_phone: "",
    address_duration_in_months: '',
    rent_amount_upto_date: false,
    rent_amount: "",
    reason_for_moving: ""
}





const RentInfo = ({ handleNext, handleBack }) => {
    // const [formData, setFormData] = useState({})
    const [loading, setLoading] = useState(false)
    const [addresss, setAddress] = useState("")
    const [citys, setCity] = useState("")
    const [states, setState] = useState("")
    const [zipCodes, setZipCode] = useState("")
    const [suggestions, setSuggestions] = useState([]);

    const dispatch = useDispatch()
    const { rentalData, app_id } = useSelector((state) => state?.rental)

    let rentalApp = rentalData?.rental_info

    // console.log("rentalData", rentalData)

    const { t } = useTranslation()
    const schema = yup.object().shape({
        values: yup.array().of(
            yup.object().shape({
                landlord_name: yup.string().required(t('land_name')),
                address_duration_in_months: yup.string().required(t('add_duration')),
                rent_amount: yup.string().required(t('rent_am_r')),
                landlord_phone: yup.string().required(t('land_phone')),
                apt: yup.string().nullable(true),
                rent_amount_upto_date: yup.boolean(),
                city: yup.string().required(t('city_invalid')),
                state: yup.string().required(t('state_invalid')),
                reason_for_moving: yup.string().required(t('reason_for')),
                zip_code: yup.string().required(t('zip_invalid')),
                earliest_move_in: yup.string().required(t('req_earlist_data'))
            })),
        energy_bill_cost: yup.number().typeError(t('energy_no')).nullable(true),
        // .required(t('energy_invalid')),
        wifi_cost: yup.number().typeError(t('wifi_no')).nullable(true),
        // .required(t('wifi_invalid')),
        cable_cost: yup.number().typeError(t('cable_no')).nullable(true),
        // .required(t('cable_invalid')),
        gas_bill_cost: yup.number().typeError(t('gass_bil')).nullable(true),
        // .required(t('gass_invalid')),

    })


    const { register, reset, control, setValue, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema), defaultValues: { values: [Information] } });
    const { fields, append, remove, } = useFieldArray({
        control,
        name: 'values'
    });


    // Function to set values from the 0 index to other indices
    // const copyValuesFromIndex0 = (index, fields) => {
    //     const { address, state, city, zip_code } = fields[0];
    //     setValue(`values[${index}].address`, address || addresss);
    //     setValue(`values[${index}].state`, state || states);
    //     setValue(`values[${index}].city`, city || citys);
    //     setValue(`values[${index}].zip_code`, zip_code || zipCodes);
    // };

    // Function to handle appending new index
    const handleAppend = (data) => {
        const newIndex = fields.length;
        append(data);
        // copyValuesFromIndex0(newIndex, fields); // Copy values from index 0 to the newly added index
        //  reset(); // Reset the form
    };




    const formatCurrency = (value) => {
        // Remove all non-digit characters
        if (!rentalApp) {
            const numericValue = value?.replace(/[^0-9]/g, '');
            // Format the value with commas
            const formattedValue = new Intl.NumberFormat('en-US').format(numericValue);
            // Add the dollar sign
            return `$${formattedValue}`;
        }
        else {
            const formattedValue = new Intl.NumberFormat('en-US').format(value);
            // Add the dollar sign
            return `$${formattedValue}`;
        }
    };


    const OnSubmit = async (data) => {
        data.values.forEach((element) => {
            element.rent_amount_upto_date = element.rent_amount_upto_date === true || element.rent_amount_upto_date === 1 ? 1 : 0;
            element.energy_bill_cost = data.energy_bill_cost;
            element.wifi_cost = data.wifi_cost;
            element.cable_cost = data.cable_cost;
            element.gas_bill_cost = data.gas_bill_cost;
            // element.address = address
            // element?.rent_amount = Number(data?.rent_amount)
        });

        let check = !compareArrays(rentalApp, data.values)
        if (check) {
            try {
                let payload = {
                    "app_id": rentalData?.id || app_id,
                    "addresses": data.values
                }
                setLoading(true)
                let { res } = await CreateRentalInfo(payload)
                if (res) {
                    dispatch(addRentals(res[0]?.rental_application))
                    setLoading(false)
                    handleNext(2)

                }
                else {
                    setLoading(false)
                }
            }
            catch (err) {
                setLoading(false)

            }
        }
        else {
            handleNext(2)
        }
    }


    const autocompleteOptions = {
        types: ['address'],
        componentRestrictions: { country: 'us' }
    };

    const handleSuggestions = (predictions) => {
        // Filter suggestions based on whether they have a city and zip code
        const filteredSuggestions = predictions.filter((prediction) => {
            if (prediction.address_components) {
                const hasCity = prediction.address_components.some(
                    (component) => component.types.includes('locality')
                );

                const hasZipCode = prediction.address_components.some(
                    (component) => component.types.includes('postal_code')
                );

                return hasCity && hasZipCode;
            }

            return false;
        });

        setSuggestions(filteredSuggestions);
    };

    useEffect(() => {
        if (rentalApp?.length > 0) {
            // let ConvObj = Object.assign({}, ...rentalApp)
            let payload = {
                values: rentalApp.map(app => ({
                    landlord_name: app?.landlord_name,
                    address_duration_in_months: app?.address_duration_in_months,
                    rent_amount: app?.rent_amount,
                    rent_amount_upto_date: app?.rent_amount_upto_date === 1 ? true : false,
                    landlord_phone: app?.landlord_phone,
                    apt: app?.apt,
                    address: app?.address,
                    city: app?.city,
                    state: app?.state,
                    reason_for_moving: app?.reason_for_moving,
                    zip_code: app?.zip_code,
                    earliest_move_in: moment(app?.earliest_move_in).format('YYYY-MM-DD')
                })),
                wifi_cost: rentalApp[0]?.wifi_cost,
                cable_cost: rentalApp[0]?.cable_cost,
                gas_bill_cost: rentalApp[0]?.gas_bill_cost,
                energy_bill_cost: rentalApp[0]?.energy_bill_cost,

            }
            // setFormData(payload)
            // setAddress(rentalApp[0].address)
            reset(payload)
        }
    }, [reset, rentalApp])

    // //console.log("formData", watch('zip_code'))
    return (
        <>
            <form onSubmit={handleSubmit(OnSubmit)} >

                <h2 className='text-[20px] font-bold text-center p-8'>{t('r_info')}</h2>
                <div className='xl:h-[400px]  lg:h-[400px]  md:h-[400px] h-full xl:overflow-y-auto lg:overflow-y-auto md:overflow-y-auto'>
                    {
                        fields.map((field, index) => {
                            // //console.log("filed", field)
                            return (
                                <div className='border rounded-md px-4 py-2 my-2  ' key={index}>
                                    <div className='flex justify-end'>
                                        {
                                            index !== 0 &&
                                            <button type='button' onClick={() => remove(index)} className=' p-2 bg-red-500 rounded-md '><BsTrash className='text-white' /></button>
                                        }
                                    </div>
                                    <div className='flex flex-col mb-4'>
                                        <label className='mb-2 text-[16px] font-semibold'>{t('earlist_date')}<span className='text-red-500'>*</span></label>
                                        <input type="date" placeholder={t('earlist_date')}
                                            name={`values[${index}].earliest_move_in`}
                                            {...register(`values.${index}.earliest_move_in`)}
                                            defaultValue={field.landlord_name}
                                            className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none  w-full' />

                                        {errors.values && errors.values[index] && errors.values[index].earliest_move_in && (
                                            <span className='text-red-500 text-sm '>{errors.values[index].earliest_move_in.message}</span>
                                        )}
                                    </div>
                                    <div className='flex flex-col mb-4'>
                                        <label className='mb-2 text-[16px] font-semibold'>{index > 0 ? t('previous_address') : t('current_address')}<span className='text-red-500'>*</span></label>
                                        <GooglePlacesAutocomplete
                                            apiKey={API}
                                            options={autocompleteOptions}
                                            onSuggestions={handleSuggestions}
                                            suggestions={suggestions}
                                            defaultValue={field?.address}
                                            // onPlaceSelected={(place) => index === 0 ? handlePlaceSelectedIndex0(place) : null}
                                            // value={address}
                                            onPlaceSelected={async (place) => {
                                                // Iterate through the address components
                                                place?.address_components.forEach(component => {
                                                    const types = component.types;
                                                    // Check for the types relevant to city, country, and zipcode
                                                    if (types.includes("locality")) {
                                                        setValue(`values.[${index}].city`, component.long_name);
                                                        setCity(component.long_name)
                                                    } else if (types.includes("administrative_area_level_1")) {
                                                        setValue(`values.[${index}].state`, component.short_name);
                                                        setState(component.short_name)
                                                    } else if (types.includes("postal_code")) {
                                                        setValue(`values.[${index}].zip_code`, component.long_name);
                                                        setZipCode(component.long_name)
                                                    }
                                                })
                                                const address = place.formatted_address || "";
                                                setAddress(address)
                                                setValue(`values.[${index}].address`, address);
                                            }}
                                            className='border p-2 w-full focus:outline-none rounded-md my-2'
                                            placeholder={t('location_search')}
                                        />
                                    </div>
                                    <div className='grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 md:gap-10 xl:gap-10 lg:gap-10'>
                                        <div className='flex flex-col mb-4'>
                                            <label className='mb-2 text-[16px] font-semibold'>{t('state')}<span className='text-red-500'>*</span> </label>
                                            <input type="text" placeholder={t('state')}
                                                name={`values[${index}].state`}
                                                {...register(`values[${index}].state`)}
                                                defaultValue={field.state}
                                                className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
                                            {errors.values && errors.values[index] && errors.values[index].state && (
                                                <span className='text-red-500 text-sm '>{errors.values[index].state.message}</span>
                                            )}
                                        </div>
                                        <div className='flex flex-col mb-4'>
                                            <label className='mb-2 text-[16px] font-semibold'>{t('city')}<span className='text-red-500'>*</span></label>
                                            <input type="text" placeholder={t('city')}
                                                name={`values[${index}].city`}
                                                {...register(`values[${index}].city`)}
                                                defaultValue={field.city}
                                                className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
                                            {errors.values && errors.values[index] && errors.values[index].city && (
                                                <span className='text-red-500 text-sm '>{errors.values[index].city.message}</span>
                                            )}

                                        </div>

                                        <div className='flex flex-col mb-4'>
                                            <label className='mb-2 text-[16px] font-semibold'>{t('zipcode')}<span className='text-red-500'>*</span></label>
                                            <input type="number" placeholder={t('zipcode')}
                                                name={`values[${index}].zip_code`}
                                                {...register(`values[${index}].zip_code`)}
                                                defaultValue={field.city}
                                                className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
                                            {errors.values && errors.values[index] && errors.values[index].zip_code && (
                                                <span className='text-red-500 text-sm '>{errors.values[index].zip_code.message}</span>
                                            )}

                                        </div>
                                        {/* <div className='flex flex-col mb-4'>
                      <label className='mb-2 text-[16px] font-semibold'>{t('zipcode')} </label>
                      <Controller
                        name={`values[${index}].zip_code`}
                        control={control}
                        defaultValue={field.zip_code}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            placeholder={t('zipcode')}
                            maxLength="5"
                            className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none'
                            onChange={(e) => {
                              const value = e.target.value.replace(/\D/g, '');
                              field.onChange(value);
                            }}
                          />
                        )}
                      />

                      {errors.values && errors.values[index] && errors.values[index].zip_code && (
                        <span className='text-red-500 text-sm '>{errors.values[index].zip_code.message}</span>
                      )}
                    </div> */}
                                    </div>
                                    <div className='grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 md:gap-10 xl:gap-10 lg:gap-10'>
                                        <div className='flex flex-col mb-4'>
                                            <label className='mb-2 text-[16px] font-semibold'>{t('aptitude_lbl_rental')}
                                                {/* <span className='text-red-500'>*</span> */}
                                            </label>
                                            <input type="text" placeholder={t('aptitude_lbl_rental')}
                                                name={`values[${index}].apt`}
                                                {...register(`values.${index}.apt`)}
                                                defaultValue={field.apt}
                                                className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none w-full' />
                                            {errors.values && errors.values[index] && errors.values[index].apt && (
                                                <span className='text-red-500 text-sm '>{errors.values[index].apt.message}</span>
                                            )}

                                        </div>
                                        <div className='flex flex-col mb-4'>
                                            <label className='mb-2 text-[16px] font-semibold'>{t('land_name_lbl')}<span className='text-red-500'>*</span></label>
                                            <input type="text" placeholder={t('land_name_lbl')}
                                                name={`values[${index}].landlord_name`}
                                                {...register(`values.${index}.landlord_name`)}
                                                defaultValue={field.landlord_name}
                                                className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none  w-full' />
                                            {errors.values && errors.values[index] && errors.values[index].landlord_name && (
                                                <span className='text-red-500 text-sm '>{errors.values[index].landlord_name.message}</span>
                                            )}

                                        </div>

                                        <div className='flex flex-col mb-4'>
                                            <label className='mb-2 text-[16px] font-semibold'>{t('land_phone_lbl')}<span className='text-red-500'>*</span></label>
                                            <Controller
                                                name={`values[${index}].landlord_phone`}
                                                control={control}
                                                defaultValue={field?.landlord_phone}
                                                render={({ field }) => (
                                                    <PhoneInput
                                                        {...field}
                                                        country={'us'}
                                                    />
                                                )}
                                            />
                                            {errors.values && errors.values[index] && errors.values[index].landlord_phone && (
                                                <span className='text-red-500 text-sm '>{errors.values[index].landlord_phone.message}</span>
                                            )}
                                        </div>

                                    </div>

                                    <div className='grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 md:gap-20 xl:gap-20 lg:gap-20'>
                                        <div className='flex flex-col mb-4'>
                                            <label className='mb-2 text-[16px] font-semibold'>{t('rent_amount')}<span className='text-red-500'>*</span> </label>
                                            <Controller
                                                name={`values[${index}].rent_amount`}
                                                control={control}
                                                defaultValue={field?.rent_amount}
                                                render={({ field }) => (
                                                    <input
                                                        {...field}
                                                        type="text"
                                                        className=' p-2 rounded-md bg-white border focus:outline-none'
                                                        value={formatCurrency(field.value)}
                                                        placeholder='$50'
                                                        onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}
                                                    />
                                                )}
                                            />
                                            {errors.values && errors.values[index] && errors.values[index].rent_amount && (
                                                <span className='text-red-500 text-sm '>{errors.values[index].rent_amount.message}</span>
                                            )}
                                        </div>
                                        <div className='flex flex-col mb-4'>
                                            <label className='mb-2 text-[16px] font-semibold'>{t('is_rent_lbl')}<span className='text-red-500'>*</span></label>
                                            <div className="flex justify-start">
                                                <label className="relative inline-flex items-center cursor-pointer">
                                                    <Controller
                                                        name={`values[${index}].rent_amount_upto_date`}
                                                        control={control}
                                                        defaultValue={field.rent_amount_upto_date} // Set the initial value of the checkbox
                                                        render={({ field }) => (
                                                            <>
                                                                <input
                                                                    type="checkbox"
                                                                    className="sr-only peer"
                                                                    role="switch" id="flexSwitchCheckDefault"
                                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                                    checked={field.value}
                                                                />
                                                                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#F79F15] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#F79F15]"></div>
                                                                {/* <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Toggle me</span> */}
                                                                <label className="form-check-label inline-block text-gray-800 ms-3" for="flexSwitchCheckDefault">{field.rent_amount_upto_date === '1' || field.value ? t('yes') : t('no')}</label>
                                                            </>
                                                        )}
                                                    />

                                                </label>
                                            </div>

                                        </div>
                                        <div className='flex flex-col mb-4'>
                                            <label className='mb-2 text-[16px] font-semibold'>{index > 0 ? t('pre_dura_mon_lbl') : t('dura_mon_lbl')}<span className='text-red-500'>*</span></label>
                                            <input type="text" placeholder={index > 0 ? t('pre_dura_mon_lbl') : t('dura_mon_lbl')}
                                                name={`values[${index}].address_duration_in_months`}
                                                {...register(`values.${index}.address_duration_in_months`)}
                                                defaultValue={field.landlord_name}
                                                className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none  w-full' />

                                            {errors.values && errors.values[index] && errors.values[index].address_duration_in_months && (
                                                <span className='text-red-500 text-sm '>{errors.values[index].address_duration_in_months.message}</span>
                                            )}
                                        </div>
                                    </div>
                                    {/* <div className='grid xl:grid-cols-2 lg:grid-cols-2 gap-20'> */}


                                    <div className='flex flex-col mb-4'>
                                        <label className='mb-2 text-[16px] font-semibold'>{t('reason_lbl')}<span className='text-red-500'>*</span></label>
                                        <input type="text" placeholder={t('reason_lbl')}
                                            name={`values[${index}].reason_for_moving`}
                                            {...register(`values.${index}.reason_for_moving`)}
                                            defaultValue={field.reason_for_moving}
                                            className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
                                        {errors.values && errors.values[index] && errors.values[index].reason_for_moving && (
                                            <span className='text-red-500 text-sm '>{errors.values[index].reason_for_moving.message}</span>
                                        )}
                                    </div>
                                    {/* </div> */}
                                </div>
                            )
                        })
                    }
                </div>

                {/* other filed  */}

                <div className='grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 md:gap-6 xl:gap-6 lg:gap-6 mt-5'>
                    <div className='flex flex-col mb-4'>
                        <label className='mb-2 text-[16px] font-semibold'>{t('wifi_lbl')}
                            {/* <span className='text-red-500'>*</span> */}
                        </label>
                        <Controller
                            name="wifi_cost"
                            control={control}
                            defaultValue=''
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    className=' p-2 rounded-md bg-white border focus:outline-none'
                                    value={formatCurrency(field.value)}
                                    placeholder='$50'
                                    onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}
                                />
                            )}
                        />

                        {errors.wifi_cost && (
                            <p className="text-red-500 text-sm ">{errors.wifi_cost.message}</p>
                        )}
                    </div>

                    <div className='flex flex-col mb-4'>
                        <label className='mb-2 text-[16px] font-semibold'>{t('cabl_lbl')}
                            {/* <span className='text-red-500'>*</span> */}
                        </label>
                        <Controller
                            name="cable_cost"
                            control={control}
                            defaultValue=''
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    className=' p-2 rounded-md bg-white border focus:outline-none'
                                    value={formatCurrency(field.value)}
                                    placeholder='$50'
                                    onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}
                                />
                            )}
                        />

                        {errors.cable_cost && (
                            <p className="text-red-500 text-sm ">{errors.cable_cost.message}</p>
                        )}
                    </div>

                    <div className='flex flex-col mb-4'>
                        <label className='mb-2 text-[16px] font-semibold'>{t('gas_bil_lbl')}
                            {/* <span className='text-red-500'>*</span> */}
                        </label>
                        <Controller
                            name="gas_bill_cost"
                            control={control}
                            defaultValue=''
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    className=' p-2 rounded-md bg-white border focus:outline-none'
                                    value={formatCurrency(field.value)}
                                    placeholder='$50'
                                    onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}
                                />
                            )}
                        />
                        {/* <input type="number"  {...register('gas_bill_cost')} placeholder='e.g 5' className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' /> */}
                        {errors.gas_bill_cost && (
                            <p className="text-red-500 text-sm ">{errors.gas_bill_cost.message}</p>
                        )}
                    </div>
                    <div className='flex flex-col mb-4'>
                        <label className='mb-2 text-[16px] font-semibold'>{t('en_bil_lbl')}
                            {/* <span className='text-red-500'>*</span>  */}
                        </label>
                        <Controller
                            name="energy_bill_cost"
                            control={control}
                            defaultValue=''
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    className=' p-2 rounded-md bg-white border focus:outline-none'
                                    value={formatCurrency(field.value)}
                                    placeholder='$50'
                                    onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}
                                />
                            )}
                        />
                        {/* <input type="number"  {...register('energy_bill_cost')} placeholder='e.g 5' className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' /> */}
                        {errors.energy_bill_cost && (
                            <p className="text-red-500 text-sm ">{errors.energy_bill_cost.message}</p>
                        )}
                    </div>


                </div>


                <div className='flex justify-end my-4'>
                    <button onClick={() => handleAppend(Information)} type='button' className=' px-3 py-2 bg-green-500 rounded-md text-white font-semibold '>{t('add_m_lbl')}</button>
                </div>



                <div className='flex justify-between items-center'>
                    <div className='flex justify-end py-3'>
                        <button onClick={() => handleBack(2)} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Back')}</button>
                    </div>

                    <div className='flex justify-end py-3'>
                        <button className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>

                            {
                                loading ? <Loader type="saveLoder" />
                                    :
                                    t('Next')
                            }
                        </button>
                    </div>
                </div>
            </form>

        </>
    )
}

export default RentInfo