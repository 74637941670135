import React, { useEffect, useState } from 'react'
// import { Countdown } from '../../hooks/TimeCountDown'
import { useTranslation } from 'react-i18next'
import { Truncate, Truncate2 } from '../../utils/TrucateString'
import blog from '../../assests/images/blogImg.png'
import person from '../../assests/svg/Icon ionic-ios-person.svg'
import locat from '../../assests/svg/Icon material-location-on.svg'
import desc from '../../assests/svg/Icon material-description-yellow.svg'
import { Loader } from '../../utils/loaderButton'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AddRentalAppUser } from '../../Redux/UserAuthSlice/RentalSlice'
import BookAppointment from '../../components/RenatalPanelComp/Dashboard/BookAppointment'
import DynamicPopup from '../../utils/Popup/DynamicPopup'
import { IoClose } from 'react-icons/io5'
import { MdMessage } from 'react-icons/md'
import gp4 from '../../assests/svg/Group 4188.svg'
import flash from '../../assests/images/f5.png'
import Resey from '../../assests/images/f6.png'
import PdfPreview from '../../components/RenatalPanelComp/RentalApp/PdfPreview'
import avatar from '../../assests/images/f10.png'
import RentalsDetail from '../Property/PropertyDetail'
import DeletePopup from '../../utils/Popup/DeletePopup'
import { ConfirmRequestLease, RemoveSavedList } from '../../lib/Api/RentalAppApi'
import PropertyOffCampus from '../../components/RenatalPanelComp/FilterRommate/PropertyDetaiOffCamp'
import PropertyOnCampus from '../../components/RenatalPanelComp/FilterRommate/PropertyDetailOnCamp'
// import { MylistDetail, MylistUserInfo } from '../../components/GenericComp/MylistDetail'

// import PostAppointment from '../../utils/Popup/PostAppointment'

const Flashpass = ({ listType, properties, loading, FetchFilterProperty, active }) => {
  const [isBooked, setIsBooked] = useState(false)
  const [book, setBook] = useState(false)
  const [property, setProperty] = useState({})
  const [type, setType] = useState('')
  const [del, setDel] = useState(false)
  const [remover, setRemover] = useState(false)
  const [propertyDetail, setPropertyDetail] = useState({})
  const [leasePdf, setLeasePdf] = useState("")
  const [checkLoading, setCheckLoading] = useState(false)
  const [selectedType, setSelectedType] = useState("")

  const location = useSelector((state) => state?.userAuth?.location)
  let check = location && Object.keys(location)?.length > 0;

  const dispatch = useDispatch()
  const navigate = useNavigate()


  const handleReschdual = (list, type) => {
    if (type === "rommate") {
      let payload = {
        ...list,
        property: null
      };
      setProperty(payload)
    } else {
      setProperty(list)
    }
    setType("seducla")
    setBook(true)
  }

  const handleBooking = (list, type) => {
    if (type === "rommate") {
      let payload = {
        ...list,
        property: null
      };
      setProperty(payload)
    } else {
      setProperty(list)
    }
    setType("book")
    setBook(true)
  }
  const handleOpen = (item, type) => {
    setSelectedType(type)
    setProperty(item)
    setDel(true)
  }

  const handlePopup = async (item, type) => {
    setProperty(item)
    setType("pdf")
    setBook(true)
    setLeasePdf(item?.lease_sent?.pdf)

  }

  const handleRequestLease = async (item) => {
    setType("reqlease")
    setProperty(item)
    let payload = {
      "property_id": item?.property_id || item.id,
    }
    setCheckLoading(true)
    let { res } = await ConfirmRequestLease(payload)
    if (res) {
      await FetchFilterProperty()
      setCheckLoading(false)
    }
  }



  const handleMessage = (data) => {
    dispatch(AddRentalAppUser(data))
    navigate(`/message/${data?.id}`)
  }


  const handleRemove = async () => {
    let payload = {
      "list": "archived",
      "action": "removed_from_saved",
      // "roommate_id": property?.id
    }
    if (selectedType === "rommate") {
      payload.roommate_id = property?.roommate?.id
    }
    else {
      payload.property_id = property?.property_id

    }
    setRemover(true)
    let { resSaved } = await RemoveSavedList(payload)
    if (resSaved) {
      FetchFilterProperty()
      setDel(false)
      setRemover(false)

    }
  }

  const handleClose = () => {
    setBook(false)
  }

  const handleDetail = (detail) => {
    setPropertyDetail(detail)
    if (active === 0) {
      setType("property")
    } else {
      setType("detail")
    }
    setBook(true)

  }

  useEffect(() => {
    if (isBooked === true && book === false) {
      FetchFilterProperty()
      setIsBooked(false)
    }
  }, [isBooked, book])

  // console.log("listing", propertyDetail)

  const { t } = useTranslation()
  return (
    <>

      {book && <DynamicPopup modalOpen={book}
        data={type === "detail" ? propertyDetail && propertyDetail?.property_details !== null ? <PropertyOffCampus detail={propertyDetail} close={() => setBook(false)} /> : <PropertyOnCampus detail={propertyDetail} close={() => setBook(false)} /> : type === "property" ? <RentalsDetail detail={propertyDetail} close={() => setBook(false)} /> : type === "pdf" ? <PdfPreview pdf={leasePdf} t={t} /> : <BookAppointment property={property} setIsBooked={setIsBooked} />} close={() => handleClose()} />}

      {del && <DeletePopup permition={del} loading={remover} Toggle={(val) => setDel(val)} callback={handleRemove} title={t('archive_this')} />}
      {
        listType === "rommate" ?
          (
            <div className='grid lg:grid-cols-2 md:grid-cols-2  gap-4 mt-5'>
              {!loading && properties?.map((list, index) => {
                return (
                  <div key={index} className='rounded-md bg-white shadow-md pb-4 mb-8 w-full relative' >
                    <div className='relative'>
                      <img src={list?.images?.length > 0 ? list?.images[0]?.image : list?.user?.user_image} className="w-full h-[200px] object-cover" alt="faverit_img" />

                      <div className='absolute top-0 w-full bg-black h-full bg-opacity-[0.5] rounded-md ' />
                      <div className='absolute top-[7%]  right-[5%]'>
                        {list?.my_list?.booking_details && Object?.keys(list?.my_list?.booking_details).length > 0 && (
                          <div className=' cursor-pointer rounded-full p-[3px] w-8 h-8 flex justify-center items-center text-white bg-[#F63030] ' onClick={() => handleOpen(list, "rommate")}>
                            <div className='text-[20px] ' >
                            </div>{property?.id === list?.id && remover === true && type === "remove" ? <div className='flex items-start' ><Loader type="single" /> </div> : <IoClose />}
                          </div>
                        )}
                        <div onClick={() => handleMessage(list?.user)} className='mt-1 cursor-pointer p-[3px] text-white bg-blue-500 w-8 h-8 flex justify-center items-center rounded-full' >
                          <div className='text-[18px] ' >
                          </div> <MdMessage />
                        </div>
                      </div>
                      <div className='absolute p-[2px] top-[7%] text-white  left-[5%]'>
                        <div className='text-[20px] cursor-pointer bg-gray-200 rounded-full' onClick={() => handleDetail(list)}>
                          <img src={gp4} alt="detail" className='w-6 h-6' />
                        </div>
                        {list?.property && list?.property?.flashlane_expires_at !== null && list?.property?.flashlane_expires_at !== "" &&
                          <div className='pt-2'>
                            <img src={flash} alt="detail" className='w-6 h-6' />
                          </div>
                        }
                      </div>
                      <div>
                        {
                          list?.property_details === null ?
                            <div className=' absolute bottom-1 flex justify-between w-full items-center '>
                              <div className='text-white px-2 w-[120px] '>
                                <h2 className=' text-[14px]'>{t('rommate_uni_dor')} : {list?.university_details?.domm_name}</h2>
                                <p className='text-[12px]'>{t('rommate_uni_major')} : {list?.university_details?.major}</p>
                              </div>
                              <div className='text-white  px-1'>
                                <h2 className=' text-[12px]'>{list?.university_details?.university_name}</h2>
                                <p className='text-[13px]'>{list?.human_readable_time}</p>
                              </div>
                            </div>
                            :
                            <div className=' absolute bottom-1 flex justify-between w-full items-center '>
                              <div className='text-white px-2  '>
                                <h2 className=' text-[18px]'>{t('list_rent')}</h2>
                                <h2 className=' text-[18px]'>${list?.property_details?.rent}/<span className='text-[12px]'>{t('Month')}</span></h2>
                                <p className='text-[12px]'>{t('sequiry')} ${list?.property_details?.security_deposit}</p>
                              </div>
                              <div className='text-white  px-1'>
                                <h2 className=' text-[13px]'>{`${list?.property_details?.street} ${list?.property_details?.city} ${list?.property_details?.state}`}</h2>
                                <p className='text-[13px]'>{list?.human_readable_time}</p>
                              </div>
                            </div>
                        }
                      </div>
                    </div>
                    {check &&
                      <div className='px-3 pt-2'>
                        <p className='text-[12px]'>{list?.property?.distance}</p>
                      </div>
                    }
                    <div className='flex justify-between items-center p-3 h-[50px]'>
                      <div className='flex items-center w-[80%] '>
                        <img src={
                          list?.user?.user_image || avatar
                        }
                          className="w-[40px] h-[35px] border object-cover rounded-full" alt="user" />
                        <h2 className='font-bold mx-2 text-[14px]  max-w-[120px] '>{Truncate(list?.user?.first_name, 10)}</h2>
                      </div>
                      {
                        list?.my_list?.booking_details && Object?.keys(list?.my_list?.booking_details).length > 0 &&
                        <div className='w-[50%]'>
                          <h2 className='text-[11px] font-semibold text-right'>{list?.my_list?.booking_details.date_of_appointment}</h2>
                          <h2 className='text-[11px] font-semibold text-right'>{list?.my_list?.booking_details.time_of_appointment} </h2>
                        </div>
                      }

                    </div>

                    <div className={` w-full`}>

                      <div className='flex justify-between flex-wrap gap-2 mx-2'>
                        {list?.my_list === null || list?.my_list?.booking_details === null ? (
                          <button
                            type="button"
                            onClick={() => handleBooking(list, "rommate")}
                            className='px-6 py-1 rounded-md bg-[#00AF0C] w-full text-white'
                          >
                            {t('book')}
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => handleReschdual(list, "rommate")}
                            className={`px-3 w-full py-1 rounded-md bg-green-500 text-white`}
                          >
                            {t('reschedule')}
                          </button>
                        )}
                      </div>
                    </div>

                  </div>

                )
              })}
            </div>
          )
          :
          (
            <div className='grid lg:grid-cols-2 md:grid-cols-2  gap-4 mt-5'>
              {!loading && properties?.map((list, index) => {
                return (
                  <div key={index} className='flex '>
                    <div className={`rounded-md bg-white border-2  shadow-md pb-4 mb-4 w-full p-2 relative`} >
                      <div className=' w-full '>
                        <div>
                          <div className='relative'>
                            <div className='absolute top-0 w-full bg-black h-full bg-opacity-[0.5] rounded-md ' />
                            <img src={list?.image || blog} alt="blog" className='object-cover w-full h-[300px]' />

                            <div className="flex justify-between w-full  absolute top-5 right-0 px-5">
                              <div>
                                <div className='text-[20px] cursor-pointer bg-gray-200 rounded-full' onClick={() => handleDetail(list)}>
                                  <img src={gp4} alt="detail" className='w-8 h-8' />
                                </div>
                                {list?.flashlane && list?.flashlane?.seconds > 0 && (
                                  <div className='pt-2'>
                                    <img src={flash} alt="detail" className='w-6 h-6' />
                                  </div>
                                )}
                              </div>
                              <div className=''>
                                {
                                  list?.my_list?.booking_details &&
                                  <div className=' cursor-pointer rounded-full p-[3px] w-8 h-8 flex justify-center items-center text-white bg-[#F63030] ' onClick={() => handleOpen(list?.my_list, "property")}>
                                    <div className='text-[20px] ' >
                                    </div>{property?.id === list?.mylist?.id && remover === true && type === "remove" ? <div className='flex items-start' ><Loader type="single" /> </div> : <IoClose />}
                                  </div>
                                }
                                <div onClick={() => handleMessage(list?.user)} className='mt-1 cursor-pointer p-[3px] text-white bg-blue-500 w-8 h-8 flex justify-center items-center rounded-full'>
                                  <div className='text-[18px] ' >
                                  </div> <MdMessage />
                                </div>
                                {list?.reazy_lane && list?.reazy_lane?.seconds > 0 && (
                                  <div className='pt-2'>
                                    <div className='bg-white flex justify-center items-center max-w-max rounded-md'>
                                      <img src={Resey} alt="detail" className='w-6 h-6' />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="absolute bottom-5 px-1 gap-[2px] flex items-center justify-between w-full">
                              <div className='bg-orange-50 h-[30px] flex gap-1 items-center text-[16px] rounded-md px-2'>
                                <h2 className=' text-[#F79F15] text-[14px]'>{list?.applications || 0} {t('applications')} </h2>
                                <span className='border-[#F79F15] border h-[20px] w-[2px]'></span>
                                <h2 className=' text-[#F79F15] text-[14px]'>{list?.appointment || 0} {t('appointments')} </h2>
                              </div>
                              <div className='bg-gray-100 py-1 text-[14px] rounded-full px-2'>
                                {list?.human_readable_time}
                              </div>
                            </div>
                          </div>
                          <div className=' w-full'>
                            <div className='flex justify-between  px-1 pt-3 '>
                              <div>
                                <ul>
                                  <li>
                                    <div className='flex items-center'>
                                      <img src={person} alt="detail" className='object-cover' />
                                      <h2 className='px-2 text-black text-[16px]'>{Truncate2(list?.user?.first_name, 15)}</h2>
                                    </div>
                                  </li>
                                  <li>
                                    <div className='flex items-center'>
                                      <img src={locat} alt="detail" className='object-cover' />
                                      <h2 className='px-2 text-black text-[14px]'> {`${list?.city} ${list?.state}`}</h2>
                                    </div>
                                  </li>
                                  <li>
                                    <div className='flex items-center'>
                                      <img src={desc} alt="detail" className='object-cover' />
                                      <h2 className='px-2 text-black text-[14px]'>{Truncate(list?.description, 10)}</h2>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div>
                                <h2 className='text-[#F79F15] text-[20px]'>${list?.rent}<span className='text-[18px] text-black'>/ Month</span></h2>
                                <p className='text-black text-[16px]'>{t('Sec_dp')} ${list?.security_deposit}</p>
                              </div>

                            </div>
                            <div className='flex justify-end items-end'>
                              {
                                list?.my_list && list?.my_list?.booking_details &&
                                <div className=''>
                                  <h2 className='text-[14px] font-medium text-right'>{t('app_date')}: {list?.my_list?.booking_details?.date_of_appointment}</h2>
                                  <h2 className='text-[14px] font-medium text-right'>{t('app_time')}: {list?.my_list?.booking_details?.time_of_appointment} </h2>
                                </div>
                              }
                            </div>
                            <div className={`w-full pt-4`}>
                              <div className='px-4 flex justify-between items-center gap-4'>
                                {/* Request Lease Button Logic */}
                                {list?.my_list === null || list?.my_list?.lease_sent === null ? (
                                  <button
                                    onClick={() => handleRequestLease(list?.my_list !== null ? list?.my_list : list)}
                                    className={`px-[3px] py-1 w-full rounded-md bg-[#F79F15] text-white ${property?.id === list?.id && 'bottom-0 px-1 py-1 mr-1'}`}
                                  >
                                    {property?.id === list?.id && type === "reqlease" && checkLoading ?
                                      <div className='flex items-center justify-center'><Loader type="single" /></div>
                                      : t('req_lease')}
                                  </button>
                                ) : (
                                  // If lease_sent is not null, show View Lease
                                  <button
                                    onClick={() => handlePopup(list, "lease")}
                                    className='px-2 py-1 w-full rounded-md bg-[#F79F15] text-white'
                                  >
                                    {t('view_lease')}
                                  </button>
                                )}

                                {/* Booking Button Logic */}
                                {list?.my_list === null || list?.my_list?.booking_details === null ? (
                                  <button
                                    onClick={() => handleBooking(list)}
                                    className='px-6 py-1 rounded-md bg-[#00AF0C] w-full text-white'
                                  >
                                    {t('book')}
                                  </button>
                                ) : (
                                  // If booking_details is not null, show Reschedule
                                  <button
                                    onClick={() => handleReschdual(list)}
                                    className={`px-3 w-full py-1 rounded-md bg-green-500 text-white`}
                                  >
                                    {t('reschedule')}
                                  </button>
                                )}
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                )
              })}
            </div>
          )
      }
    </>
  )
}

export default Flashpass