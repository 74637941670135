import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dashboard from '../../components/ServicerPanelComp/Dashboard/Dashboard'
import { ChangeLanguage } from '../../lib/Api/UserApi'
import DynamicPopup from '../../utils/Popup/DynamicPopup'
import IdVerified from '../../components/GenericComp/IdVerified'
import { Loader } from '../../utils/loaderButton'
import { GetRommates } from '../../lib/Api/RentalAppApi'
import { setUserRommate } from '../../Redux/UserAuthSlice/UserAuthSlice'
import { useNavigate } from 'react-router-dom'
const ServicerDashboard = () => {
  const [loading, setLoading] = useState(false)
  const [showVerify, setShowVerify] = useState(false)
  const isVerify = useSelector((state) => state.userAuth.userInfo?.idVerified)
  const lng = useSelector((state) => state?.userAuth?.language)
  
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleChangeLanguage = async (check) => {
    await ChangeLanguage(lng)
  }
  useEffect(() => {
    if (isVerify === null) {
      setShowVerify(true)
    }
    else {
      setShowVerify(false)
    }
  }, [isVerify])

  useEffect(() => {
    handleChangeLanguage()
  }, [])


  const handleNavigate = () => {
     navigate('/mylist')
  }

  useEffect(() => {
    let fetchData = async () => {
      let { res } = await GetRommates()
      if (res) {
        if (Object.keys(res?.roommates ?? {}).length > 0) {
          const info = {
            college_student: res?.roommates?.college_student,
            on_campus: res?.roommates?.on_campus
          };
          dispatch(setUserRommate(info))
        }
      }
    }
    fetchData()
  }, [])


  return (
    <>
      {showVerify && <DynamicPopup type="verify" modalOpen={showVerify} data={<IdVerified type="verify" setLoading={setLoading}  handleNavigate={() => setShowVerify(false)}  close={handleNavigate} />}  />}
      {loading ? <Loader type="loadData" /> :
        <Dashboard />
      }
    </>
  )
}

export default ServicerDashboard