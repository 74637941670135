import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SignatureCanvas from 'react-signature-canvas'
import { AddAutherizeSign } from '../../../lib/Api/RentalAppApi'
import { Loader } from '../../../utils/loaderButton'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const Autherization = ({ status, handleBack, handleNext }) => {
    const { t } = useTranslation()
    const [toggle, setToggle] = useState(false)
    const [loading, setloading] = useState(false)
    const [sign, setSign] = useState()

    const { rentalData , app_id } = useSelector((state) => state?.rental)

    const removeFairHousingDisclosure = (data) => {
        const { fair_housing_disclosure, screening, ...rest } = data;
        return rest;
    };




    const checkAnyFalse = (data) => {
        return Object.values(data).some(value => !value);
    };




    const handleSign = async () => {
        let signData = sign.toDataURL();
        setloading(true)
        let payload = {
            "app_id": rentalData?.id || app_id,
            // "ssn_number": ssn,
            "sign": signData
        }
        let { res } = await AddAutherizeSign(payload)
        if (res) {
            setloading(false)
            const modifiedData = removeFairHousingDisclosure(res?.status);
            let checkFalse = checkAnyFalse(modifiedData)
            if (checkFalse) {
                toast.error(t('check_rental_App'))
            } else {
                handleNext(9)
            }
        }
        else {
            setloading(false)
        }

    }

    const handlClear = () => {
        sign.clear()
    }
    return (
        <>

            {rentalData?.sign === "" || rentalData?.sign == null ?
                <div>
                    {toggle ?
                        <div>
                            <h2 className='text-[20px] font-bold text-center p-8'>{t('draw_sign')}</h2>
                            <div>

                                <div className='border m-4'>
                                    <SignatureCanvas penColor='#F79F15'
                                        canvasProps={{ width: 700, height: 200, className: 'sigCanvas' }}
                                        ref={data => setSign(data)}
                                    />
                                </div>

                                <div className='flex justify-center gap-5 items-center pt-10'>
                                    <button onClick={() => handlClear()} className='font-semibold  mx-2 text-[#F79F15] border-[#F79F15] border bg-white px-6 py-1.5  rounded-md'>{t('clear')}</button>
                                    <button onClick={() => handleSign()} className='font-semibold bg-green-500 px-6 py-1.5 text-white rounded-md'>{loading ? <Loader type="saveLoder" /> : t('save')}</button>
                                    <button onClick={() => setToggle(false)} className='font-semibold  mx-2 text-[#F79F15] border-[#F79F15] border bg-white px-6 py-1.5  rounded-md'>{t('cancel')}</button>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='px-10'>
                            <h2 className='text-[20px] font-bold text-center p-8'>{t('autherize')}</h2>
                            <p className='pb-2'>{t('auther_para')}</p>
                            <p>{t('autherize_desc')}</p>
                            <div className='flex justify-center items-center gap-5 '>
                                <div className=' '>
                                    <button onClick={() => handleBack(9)} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Back')}</button>
                                </div>
                                <div className=''>
                                    <button onClick={() => setToggle(true)} className='font-semibold bg-green-500 px-6 py-1.5 text-white rounded-md'>{t('i_agree')}</button>
                                </div>
                            </div>
                        </div>
                    }

                </div>
                :
                <div>
                    <div className='px-10'>
                        <h2 className='text-[20px] font-bold text-center p-8'>{t('autherize')}</h2>
                        <div className='border '>
                            <div className='flex justify-center items-center pt-10'>
                                <img src={rentalData?.sign} alt="signs" className='object-cover ' />
                            </div>
                        </div>
                        <p className='py-2'>{t('auther_para')}</p>
                        <p>{t('autherize_desc')}</p>
                        <div className='flex justify-center items-center gap-5 pt-5 '>
                            <div className=' '>
                                <button onClick={() => handleBack(9)} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Back')}</button>
                            </div>
                            <div className=''>
                                <button onClick={() => handleNext(9)} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Next')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Autherization