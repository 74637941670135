import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useSelector } from 'react-redux';
import { Loader } from '../../../utils/loaderButton';
import { VerifyPasswords } from '../../../lib/Api/UserApi';
import { IoEyeOff } from 'react-icons/io5';
import { IoMdEye } from 'react-icons/io';
const VerifyRentalApp = ({ handleClose }) => {
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    const { email } = useSelector((state) => state?.userAuth?.userInfo)
    const { language } = useSelector((state) => state.userAuth)
    const [showpass, setShowPass] = useState(false)


    const schema = yup.object({
        password: yup.string()
            .required(t('invalid_password')),
    })

    const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

    const OnSubmit = async (data) => {
        let payload = {
            email: email,
            password: data.password,
            lang: language ? language : "",
        }
        setLoading(true)
        let { res, error } = await VerifyPasswords(payload)
        if (res) {
            setLoading(false)
            sessionStorage.setItem('hasVerify', 'true');
            handleClose()
        }
        else if (error) {
            setLoading(false)
        }
        else {
            setLoading(false)
        }
    }

    return (
        <>
            <div className='px-5'>
                <form onSubmit={handleSubmit(OnSubmit)}>
                    <div className=''>
                        <label className='mb-2 text-[16px] font-semibold'>{t('enter_pass')} </label>
                        <div className='border rounded-md flex items-center px-2 mb-5 mt-2'>
                            <input type={showpass ? 'text' : 'password'} {...register('password')} placeholder={t('password')} className='   rounded focus:outline-none h-[40px]' />
                            {showpass ? <IoMdEye onClick={() => setShowPass(false)} size={20} className='cursor-pointer' /> : <IoEyeOff onClick={() => setShowPass(true)} size={20} className='cursor-pointer' />}
                        </div>
                        <button className='font-semibold bg-[#F79F15] w-full py-1.5 text-white rounded-md'>
                            {
                                loading ? <Loader type="saved" />
                                    :
                                    t('c_pass')
                            }
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default VerifyRentalApp