import React, { useState, useRef, useEffect } from 'react'
import { Loader } from '../../../utils/loaderButton'
import { FaRegImages } from 'react-icons/fa'
import { MdOutlinePhotoCamera } from 'react-icons/md'
import { ConvertBase64 } from '../../../utils/ConvBase64'
import { useDispatch, useSelector } from 'react-redux'
import { updateUser } from '../../../Redux/UserAuthSlice/UserAuthSlice'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { IoMdCloseCircle } from 'react-icons/io'
// import CheckPriorities from '../../ServicerPanelComp/RoomateComp/CheckPriorities'
// import DynamicPopup from '../../../utils/Popup/DynamicPopup'
import avatar from '../../../assests/images/f10.png'
import { EditUserProfile } from '../../../lib/Api/UserApi'
import { CreateRommateImages, GetRommateImages } from '../../../lib/Api/RentalAppApi'
import { AddRommateIndex, setIsStart, setUpdatePurchaseRoommate, setUpdateRoommate } from '../../../Redux/UserAuthSlice/RommateSlice'
import { useNavigate } from 'react-router-dom'

const PersonalInfo = () => {
    const [loading, setLoading] = useState(false)
    const [Createloading, setCreateLoading] = useState(false)
    const [ImgLoading, setImgLoading] = useState(false)
    const [ImgUpload, setImgUpload] = useState(false)
    const [show, setShow] = useState(false)
    const [profileImgUrl, setProfileImgUrl] = useState("")
    const [images, setImages] = useState([])
    const [fileImages, setFileImages] = useState([])
    const [ImageObj, setImgObj] = useState({})

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const inputFile = useRef(null)

    const user = useSelector((state) => state?.userAuth?.userInfo)
    // const { renterQuestion } = useSelector((state) => state.rommate)
    const { rommate, r_t_rommate } = useSelector((state) => state?.rommate)


    // //console.log("show", show)

    const getImages = async () => {
        try {
            setLoading(true)
            let res = await GetRommateImages()
            if (res) {
                setLoading(false)
                setImages(res)
            }
            else {
                setLoading(false)
            }
        }
        catch (err) { }
    }


    const handleImage = async (e) => {
        const file = e.target.files[0]
        let formData = new FormData
        formData.append('user_image', file)
        const convert = await ConvertBase64(file)
        setImgUpload(true)
        let { res } = await EditUserProfile(formData)
        if (res) {
            let UserData = Object.assign({}, ...res)
            dispatch(updateUser(UserData))
            setImgUpload(false)
            setProfileImgUrl(convert)
        }
    }


    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        let fileLeng = images?.length
        if (fileLeng > 5) {
            toast.error(t('upload_img_info'))
        }
        else if (files?.length > 5) {
            toast.error(t('upload_img_info'))
        }
        else {
            const acceptedFileTypes = ['image/jpeg', 'image/png']; // Add more accepted file types as needed
            const imageFiles = files.filter(file => acceptedFileTypes.includes(file.type));
            setFileImages(imageFiles)
            Promise.all(
                imageFiles.map(file => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = error => reject(error);
                        reader.readAsDataURL(file);
                    });
                })
            )
                .then(base64Images => {
                    if (images?.length) {
                        setImages((prev) => [...prev, base64Images])
                    }
                    else {
                        setImages(base64Images)
                    }
                }
                )
        }
    }

    const RemoveImage = async (index) => {
        setImgLoading(true)
        setImgObj(index)
        let findList = images?.find((f, i) => i === index)
        let ImagesList = images?.filter((_, i) => i !== index)
        if (findList) {
            // let res = await DeleteRommateImage(findList?.id)
            setImgLoading(false)
            setImages(ImagesList)
        }
        else {
            setTimeout(() => {
                setImgLoading(false)
                setImages(ImagesList)
            }, 2000);
        }

    }

    const handleNext = async (e) => {
        e.preventDefault()
        try {
            dispatch(setIsStart(false))
            let filterImages = images.flat().filter((f) => {
                return !f.hasOwnProperty("id")

            })
            if (filterImages?.length > 0) {
                setCreateLoading(true)
                let paylaod = { "user_id": user?.userid, "user_type": "renter" }
                if (filterImages?.length > 0) {
                    paylaod.images_base64 = filterImages
                }
                else {
                    paylaod.images_base64 = images
                }
                let res = await CreateRommateImages(paylaod)
                if (res) {
                    setCreateLoading(false)
                    if (r_t_rommate === "reazy") {
                        dispatch(setUpdateRoommate(true))
                        navigate('/reazy')
                    } else if (r_t_rommate === "flash") {
                        dispatch(setUpdateRoommate(true))
                        dispatch(setUpdatePurchaseRoommate(false))
                        navigate('/flash')
                    }
                    else {
                        if (rommate?.college_student === 1 && rommate?.on_campus === 1) {
                            dispatch(AddRommateIndex(1))
                        }
                        else {
                            dispatch(AddRommateIndex(2))
                        }
                    }

                }
            }
            else {
                if (r_t_rommate === "reazy") {
                    dispatch(setUpdateRoommate(true))
                    navigate('/reazy')
                } else if (r_t_rommate === "flash") {
                    dispatch(setUpdateRoommate(true))
                    dispatch(setUpdatePurchaseRoommate(false))
                    navigate('/flash')

                }
                else {
                    if (rommate?.college_student === 1 && rommate?.on_campus === 1) {
                        dispatch(AddRommateIndex(1))
                    }
                    else {
                        dispatch(AddRommateIndex(2))
                    }

                }
            }
        }
        catch (err) { }
    }

    const onButtonClick = () => {
        inputFile.current.click();

    }



    useEffect(() => {
        getImages()
        if (user) {
            setProfileImgUrl(user?.userimg !== "https://leased.s3.amazonaws.com/" ? user?.userimg : avatar)
        }
    }, [])


    const { t } = useTranslation()
    return (

        <>
            {/* {show && <DynamicPopup data={<CheckPriorities />} modalOpen={show} close={() => setShow(false)} />} */}
            {loading ? <Loader type="loadData" /> :
                <>
                    <h1 className='text-center text-[25px] font-semibold pt-2'>{t('rommate_p_detail')}</h1>
                    <form >
                        <div className='mt-5 border shadow-md p-5 rounded-sm'>
                            <div className='flex justify-center items-center relative  max-w-[180px] mx-auto'>
                                <div className='w-[150px] h-[150px] border rounded-full'>
                                    <img src={profileImgUrl} alt="profile" className="w-[150px] h-[150px] rounded-full" />
                                </div>
                                <div className='absolute top-5 left-5 '>
                                    {
                                        ImgUpload &&
                                        <div className=' absolute top-9 left-14'>
                                            <Loader type="socialLoder" />
                                        </div>
                                    }
                                </div>
                                <div className='absolute  top-[40%] right-[0%] cursor-pointer'>
                                    <div onClick={onButtonClick} className='border bg-white bg-opacity-[0.7] p-1 rounded-full'>
                                        <MdOutlinePhotoCamera className='text-[15px] text-[#F79F15]' />
                                    </div>
                                    <input type="file" ref={inputFile} onChange={handleImage} className='hidden' />
                                </div>
                            </div>
                        </div>

                        <div className='pt-6'>
                            <label className='mb-2  text-[16px] font-semibold'>{t('rommate_p_upload_p')} </label>
                            <div className='border rounded-md h-auto pl-3 mt-5 flex xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap  items-center '>
                                <label htmlFor='upload' className={`${images?.length === 5 ? 'cursor-not-allowed' : 'cursor-pointer'} bg-gray-100 cursor-po <label htmlFor='upload'inter border rounded-md px-6 h-[35px] flex justify-center items-center xl:mt-0 lg:mt-0 md:mt-0 mt-2`}>
                                    <div className='flex items-center text-gray-400  ' >
                                        <FaRegImages />
                                        <h2 className='px-2'>{t('uplod_lbl')}</h2>
                                    </div>
                                </label>
                                <input type="file" id="upload" className='hidden' disabled={images?.length === 5 ? true : false} name="image_upload" accept='/images' multiple onChange={(e) => handleImageChange(e, "images")} />
                                <div className=' xl:ml-3 lg:ml-3 md:ml-3 xl:mt-0 lg:mt-0 md:mt-0 mt-2  grid xl:grid-cols-8 lg:grid-cols-8 md:grid-cols-8 grid-cols-4 gap-2  '>
                                    {
                                        images?.map((u, i) => (
                                            <div className='relative xl:first:mx-2 lg:first:mx-2 md:first:mx-2 first:mx-0 border w-[70px] h-[70px] rounded-md' key={i}>
                                                <img src={u?.image ? u?.image : u} alt="user_image" className='object-cover w-[70px] h-[70px] rounded-md ' />
                                                {
                                                    ImgLoading && ImageObj === i ?
                                                        <div className='absolute top-1'>
                                                            <Loader type="socialLoder" />
                                                        </div>
                                                        :
                                                        <div className='absolute right-1 top-1' onClick={() => RemoveImage(i)}>
                                                            <IoMdCloseCircle className='text-red-500 cursor-pointer' />
                                                        </div>
                                                }
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                        </div>

                        <div className='flex justify-end items-end mt-5 gap-2'>

                            <button onClick={(e) => handleNext(e)} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
                                {Createloading ? <Loader type="saveLoder" /> : t('next_lbl')}
                            </button>


                        </div>
                    </form>
                </>
            }
        </>
    )
}

export default PersonalInfo