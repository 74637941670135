import React, { useState, useEffect } from 'react'
import blogImg from '../../assests/images/blogImg.png'
import bath from '../../assests/images/bath.png'
import bed from '../../assests/images/bed.png'
// import person from '../../assests/svg/Icon ionic-ios-person.svg'
import locat from '../../assests/svg/Icon material-location-on.svg'
import LikeDislikePopup from '../../utils/Popup/LikeDisLike';
// import { BsFlag } from 'react-icons/bs'
import { FaRegFlag } from "react-icons/fa";
// import { GetPropertyById } from '../../lib/Api/RentalAppApi'
import { Loader } from '../../utils/loaderButton'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper';
import { useTranslation } from 'react-i18next'
import DynamicPopup from '../../utils/Popup/DynamicPopup'
import PdfPreview from '../../components/RenatalPanelComp/RentalApp/PdfPreview'
import Confirermation from './Confirermation'
import { ConfirmReporting, ConfirmRequestLease, GetPropertyById, RenterBlockUser } from '../../lib/Api/RentalAppApi'

const RentalsDetail = ({ detail, swipeLeft, close }) => {
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    // const [reqLease, setReqLease] = useState(false)
    const [properties, setProperties] = useState({})
    const [pdf, setPdf] = useState({})
    const [type, setType] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [popupType, setPopuptype] = useState("")
    const [confirmType, setConfirmType] = useState("")
    const [swiperHeight, setSwiperHeight] = useState('auto');

    const handleImageLoad = (e, i) => {
        const imageHeight = e.target.clientHeight;
        setSwiperHeight(imageHeight);
    };
    const { t } = useTranslation()

    let fetchData = async () => {
        let check = GetPropertyById(detail?.id)
        let { res } = await check
        let obj = Object.assign({}, ...res)
        if (res) {
            setProperties(obj.property || obj?.listing)
            return res
        }

    }


    //    console.log("properties", properties)

    const ViewPdf = async (lease) => {
        if (properties?.lease_built && Object.keys(properties?.lease_built).length > 0) {
            setPdf(lease?.lease_built?.pdf)
            setType(true)
        } else {
            setType(true)
            setPopuptype("confirm")
            setConfirmType("reqlease")
        }
    }

    const handleOpen = () => {
        setPopuptype("confirm")
        setConfirmType("block")
        setType(true)
    }

    let handleReqLease = async () => {
        let payload = {
            "property_id": properties?.id,
        }
        setConfirmLoading(true)
        let { res } = await ConfirmRequestLease(payload)
        if (res) {
            setConfirmLoading(false)
            setType(false)
            await fetchData()
        }
    }

    const ShowMessage = () => {
        setPopuptype('confirm')
        setType(true)
    }
    const handleConfirm = async () => {
        setConfirmLoading(true)
        let payload = {
            "property_id": properties?.id
        }
        let { res } = await ConfirmReporting(payload)
        if (res) {
            setConfirmLoading(false)
            swipeLeft(properties?.id)
            setType(false)
            close()
        }
        else {
            setConfirmLoading(false)

        }
    }

    const handleBlock = async () => {
        setConfirmLoading(true)
        let payload = {
            "blocked_user_id": properties?.user?.id
        }
        let { res } = await RenterBlockUser(payload)
        if (res) {
            setConfirmLoading(false)
            swipeLeft(properties?.id)
            setType(false)
            close()
        }
        else {
            setConfirmLoading(false)

        }
    }


    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                let res = await fetchData()
                if (res) {
                    setLoading(false)
                }

            } catch (error) {
            }
        })();
    }, [detail])


    const url = `https://www.google.com/maps?q=${detail?.complete_address}`

    return (
        <>
            {show && <LikeDislikePopup permition={show} Toggle={setShow} type={type} />}
            {type && <DynamicPopup close={() => setType(false)} modalOpen={type} data={popupType === "confirm" ? <Confirermation type={confirmType} handleConfirm={confirmType === "reqlease" ? handleReqLease : confirmType === "block" ? handleBlock : handleConfirm} close={() => setType(false)} confirmLoading={confirmLoading} /> : <PdfPreview pdf={pdf} t={t} />} />}
            {
                loading ?
                    <div className="flex justify-center items-center text-[120px]">
                        <Loader type="loadData" />
                    </div>
                    :
                    <div className="container mx-auto pt-4">
                        <div className="flex justify-center flex-col items-center">

                            {/* center section  */}

                            <div className="xl:w-[700px]  lg:w-[700px] md:w-full w-[500px] max-w-full col-span-3  xl:mx-auto lg:mx-auto md:mx-auto  xl:px-10 lg:px-10  ">
                                <div className='relative  shadow'>
                                    <Swiper
                                        pagination={{
                                            type: 'progressbar',
                                        }}
                                        navigation={true}
                                        modules={[Pagination, Navigation]}
                                        className="h-[400px] w-full"
                                    // style={{ height: swiperHeight }}
                                    >
                                        {
                                            properties?.images?.map((item, i) => (
                                                <SwiperSlide key={i} className='bg-[#efefef]'>
                                                    <img
                                                        src={item?.image || blogImg}
                                                        alt="blogImg"
                                                        className='h-full object-contain'
                                                        onLoad={(e) => handleImageLoad(e, i)}
                                                    />
                                                </SwiperSlide>
                                            ))

                                        }

                                    </Swiper>
                                    <div className=' absolute bottom-10 z-40 left-4 '>
                                        <div className='bg-gray-100  rounded-full px-2'>
                                            {properties?.human_readable_time}
                                        </div>
                                    </div>

                                    <div className="absolute top-5 z-40  w-full px-4 ">
                                        <div className="flex justify-between items-center bg-white shadow-md rounded-full max-w-max p-[10px] cursor-pointer" onClick={() => ShowMessage()}>
                                            <FaRegFlag className='text-[20px] text-red-700' />
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2'>
                                    <div className='flex xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap justify-between items-center   pb-2'>
                                        <div>
                                            <ul>
                                                <li>
                                                    <div className='flex items-center'>
                                                        <img src={detail?.user?.user_image} alt="detail" className='object-cover w-[35px] h-[35px] rounded-full' />
                                                        <h2 className='px-2 text-black font-bold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px] '>{properties?.user?.first_name}</h2>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='flex items-center'>
                                                        <img src={locat} alt="detail" className='object-cover' />
                                                        <h2 className='px-2 text-black text-[14px]'>{properties?.complete_address}</h2>
                                                    </div>
                                                </li>
                                                {properties?.complete_address &&
                                                    <li>
                                                        <div className='flex items-center'>
                                                            <img src={locat} alt="detail" className='object-cover' />
                                                            <a href={url} target="_blank" className='px-2 text-black text-[14px]' rel="noopener noreferrer">
                                                                {t('view_map')}
                                                            </a>
                                                        </div>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                        <div>
                                            <div className='flex items-center'>
                                                <h2 className=' text-[25px] py-1 '>${properties?.rent} <span className='text-[15px]'>/ {t('Month')}</span></h2>
                                            </div>
                                            <p className=''>
                                                {
                                                    `${t('Sec_dp')} : ${properties?.security_deposit === "0" ? 'NR' : `$${properties?.security_deposit}`}`
                                                }
                                            </p>
                                            {properties?.lister_type === "1" &&
                                                <p className=''>
                                                    {properties?.broker_fee === 0
                                                        ? `${t('broker_fee')} : N/A`
                                                        : `${t('broker_fee')} : ${properties?.broker_fee_type !== "percent" ? "$" : ""}${properties?.broker_fee}${properties?.broker_fee_type === "percent" ? "%" : ""}`
                                                    }
                                                </p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-5'>
                                    <div className="flex items-center justify-center">
                                        <div className='flex  items-center bg-gray-200 rounded-md xl:p-3 lg:p-3 md:p-3 p-1'>
                                            <img src={bed} alt="bed" className='object-cover' />
                                            <h2 className='text-[16px] px-2 font-medium'>{properties?.number_of_beds} {t('bedRom')}</h2>
                                        </div>
                                        <div className='flex  items-center bg-gray-200 rounded-md xl:p-3 lg:p-3 md:p-3 p-1 mx-5'>
                                            <img src={bath} alt="bath" className='object-cover' />
                                            <h2 className='text-[16px] px-2 font-medium'>{properties?.number_of_baths} {t('bathRom')}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-8">
                                    <div className='flex items-center'>
                                        <h2 className='font-semibold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px] '>{t('list_by')}</h2>
                                    </div>
                                    <div className="pb-2">
                                        <h2 className='font-medium flex items-center xl:text-[16px] lg:text-[16px] md:text-[16px] text-[16px]'>
                                            <span className="font-semibold pr-1">{t('email')}:</span>{detail?.lister_email || detail?.realtor_email || "N/A"}
                                        </h2>
                                    </div>
                                    <div className="pb-2">
                                        <h2 className='font-medium flex items-center xl:text-[16px] lg:text-[16px] md:text-[16px] text-[16px]'>
                                            <span className="font-semibold pr-1">{t('contact_phone')}:</span>{detail?.lister_phone || "N/A"}
                                        </h2>
                                    </div>
                                    <div className="pb-2">
                                        <h2 className='font-medium flex items-center xl:text-[16px] lg:text-[16px] md:text-[16px] text-[16px]'>
                                            <span className="font-semibold pr-1">{t('sp_broker')}:</span>{detail?.sponsoring_broker || "N/A"}
                                        </h2>
                                    </div>
                                    <div classNam=" ">
                                        {properties?.mls_listing_id &&
                                            <h2 className='font-medium flex items-center xl:text-[16px] lg:text-[16px] md:text-[16px] text-[16px] pb-2'>
                                                <span className="font-semibold pr-1">{t('mlsid')}:</span>{properties?.mls_listing_id}
                                            </h2>
                                        }
                                        {properties?.mls_source &&
                                            <h2 className='font-medium flex items-center xl:text-[16px] lg:text-[16px] md:text-[16px] text-[16px]'>
                                                <span className="font-semibold pr-1">{t('mlsSys')}:</span>{properties?.mls_source}
                                            </h2>
                                        }

                                    </div>
                                    {/* <div>
                                        <h2 className='font-semibold xl:text-[18px] lg:text-[18px] md:text-[18px] text-[16px] '>{t('professional_lab')}</h2>
                                        <p className='py-2 '> {detail?.listed_with_realtor ? detail?.realtor_license : detail?.professional_license || "N/A"}</p>
                                    </div> */}
                                </div>

                                <div className="pt-4">
                                    <div className='flex items-center'>
                                        <h2 className='font-semibold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px] '>{t('desc')}</h2>
                                    </div>
                                    <p className='pt-1'> {properties?.description}</p>
                                </div>
                                {/* {properties?.lease_built && Object.keys(properties?.lease_built).length > 0 ? */}
                                {/* <div className="pt-8">
                                    <div className='flex items-start flex-col'>
                                        <div className='flex items-center   cursor-pointer ' onClick={() => ViewPdf(properties)}>
                                            <div className='flex items-center bg-gray-200 rounded-md p-3'>
                                               {reqLease ? <Loader type="social"/> : t('view_lease') }
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* } */}
                                <div className="pt-4">
                                    {
                                        properties?.amenities?.length > 0 &&

                                        <>

                                            <div className='flex items-center'>
                                                <h2 className='font-semibold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px] '>{t('amanity')}</h2>
                                            </div>
                                            <div className='grid grid-cols-3 gap-5 pt-2 bg-white rounded-md shadow-md p-3 mt-2'>
                                                {
                                                    properties?.amenities?.map((c, i) => (
                                                        <div className='flex flex-col' key={i}>
                                                            <h2 className='text-gray-400'>{c?.name}</h2>

                                                        </div>
                                                    ))
                                                }


                                            </div>
                                        </>
                                    }

                                    <div className='flex items-center pt-4'>
                                        <h2 className='font-semibold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px] '>{t('leased_p')}</h2>
                                    </div>
                                    <div className=''>
                                        <div>
                                            <h2 className='text-[18px] text-gray-400'>{properties?.leased?.title}</h2>
                                            <p>{properties?.leased?.description}</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="pt-8 w-full">
                                    <div className='flex items-center w-full  cursor-pointer ' onClick={() => ViewPdf(properties)}>
                                        <div className='flex items-center justify-center text-center bg-[#F79F15] text-white w-full rounded-md p-3'>
                                            {t('view_lease')}
                                        </div>
                                    </div>
                                </div>

                                <div className="pt-4 w-full">
                                    <div className='flex items-center w-full  cursor-pointer ' onClick={() => handleOpen(properties)}>
                                        <div className='flex items-center justify-center text-center bg-[#F79F15] text-white w-full rounded-md p-3'>
                                            {t('block_button')}
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
            }

        </>
    )
}

export default RentalsDetail